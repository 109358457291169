import { Component, Input, forwardRef } from '@angular/core';
import * as _ from 'lodash';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';
import { SponsorSettings } from '../../../shared/sponsor-settings.class';
import { SponsorValidationService } from '../../../validation/sponsor-validation.service';
import { CurrentMemberService } from '../../../shared/current-member.service';
import { Transition } from "@uirouter/angular";

@Component({
    selector: 'vpe-email',
    templateUrl: './email.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EmailComponent),
            multi: true,
        }
    ],
    styleUrls: ['./email.component.scss']
})
export class EmailComponent implements ControlValueAccessor, Validator {
    email: string;
    _validateEmailDomain = true; // true on main sign-up pages, false on waitlist
    validEmailDomain = true;
    touched = false;
    private emailRegex = /^[^@]+@[^@]+$/;
    private sponsorId: number;

    @Input() set validateEmailDomain(validateDomain: Boolean) {
        if (validateDomain === false) {
            this._validateEmailDomain = false;
        }
    }

    @Input() popoverText: string;
    @Input() set sponsorSettings(settings: SponsorSettings) {
        if (settings) {
            this.sponsorId = settings.sponsorId;
        }
    }

    @Input() emailOptional: boolean;

    constructor(private sponsorValidationService: SponsorValidationService,
        private currentMemberService: CurrentMemberService,
        private transition: Transition,
    ) { }

    public writeValue(obj: any) {
        if (obj) {
            this.email = obj.toString();
        }

        if (this.email) {
            this.touched = true;
            this.validateAndPropagateEmailAddress();
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.validateAndPropagateEmailAddress();
    }

    public onBlur() {
        this.touched = true;
        this.validateAndPropagateEmailAddress();
        this.propagateTouch(true);
    }

    public isValidEmail() {
        return (
            (!_.isNil(this.email) && this.email.length >= 5 && this.emailRegex.test(this.email))
            || (_.isEmpty(this.email) && this.emailOptional)
        );
    }

    private validateAndPropagateEmailAddress() {
        this.currentMemberService.memberEnrollmentData.enrollmentGroupId = this.transition.params().enrollmentGroupId;
        if (_.isNil(this.email) || this.email.trim() == "") {
            this.email = null;
        }
        this.currentMemberService.memberEnrollmentData.emailAddress = this.email;

        if (this._validateEmailDomain && this.isValidEmail()) {
            return this.sponsorValidationService
                .validateEmailDomain(this.sponsorId, this.currentMemberService.memberEnrollmentData)
                .subscribe(() => {
                    this.validEmailDomain = true;
                    this.propagateChange(this.email);
                },
                () => {
                    this.validEmailDomain = false;
                    this.propagateChange(this.email);
                });
        } else {
            this.propagateChange(this.email);
        }
    }

    public validate(c: UntypedFormControl): { [key: string]: any } {
        if (!this.isValidEmail() || !this.validEmailDomain) {
            return { 'invalid': 'email' };
        }

        return null;
    }
}
