import { AnalyticsData } from './analytics-data.class';
import { FlexFormField } from './flex-form-field.class';
import { ProgressBarStepData } from './progress-bar-step-data.class';
import { FlexFormAttributes } from "./flex-from-attributes";

/**
 * Front-end representation of the API's `FlexFormSpec` object.
 *
 * - Holds the API-given data that the {@link FlexFormComponent} needs to present the user with a form or
 * other enrollment page.
 * - Collects the field values to be sent back to the API, e.g. via {@link FlexFormService.submitFlexForm}.
 */
export class FlexForm {
    fields: FlexFormField[];
    progressBarSteps: ProgressBarStepData[];
    pageType: string;
    progressData: string;
    submitUrl: string;
    title: string;
    description: string;
    languageCode: string;
    analyticsData: AnalyticsData;
    attributes: FlexFormAttributes;

    /** Copy constructor. */
    static fromFlexForm(oldFlexForm: FlexForm): FlexForm {
        let form = new FlexForm();
        form.fields = oldFlexForm.fields.map(field => new FlexFormField(field));
        form.progressBarSteps = oldFlexForm.progressBarSteps;
        form.pageType = oldFlexForm.pageType;
        form.progressData = oldFlexForm.progressData;
        form.submitUrl = oldFlexForm.submitUrl;
        form.title = oldFlexForm.title;
        form.description = oldFlexForm.description;
        form.languageCode = oldFlexForm.languageCode;
        form.analyticsData = oldFlexForm.analyticsData;
        form.attributes = oldFlexForm.attributes;
        return form;
    }
}
