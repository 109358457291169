import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { SessionStorageService } from '../session-storage.service';
import { FlexFormHistoryCacheKey } from './flex-form-history-cache-key.class';
import { FlexFormHistoryEntry } from './flex-form-history-entry.class';
import { FlexForm } from './flex-form.class';
import { Constants } from "../constants.class";

@Injectable({
    providedIn: 'root'
})
export class FlexFormHistoryService {

    storageKey = 'flexFormHistory';
    history: Array<FlexFormHistoryEntry> = [];

    constructor(
        private stateService: StateService,
        private sessionStorageService: SessionStorageService
    ) {
        this.loadHistory();
    }

    getFlexForm(
        step: number,
        cacheKey: FlexFormHistoryCacheKey
    ): { resendRequest: boolean, productSelectUrl: string, formGroupValues: { [key: string]: any }, flexForm: FlexForm } {
        let historyEntry = this.history[step - 1];

        if (this.entryMatchesKey(historyEntry, cacheKey)) {
            return { resendRequest: false, productSelectUrl: null,  formGroupValues: null, flexForm: historyEntry?.flexForm };
        } else if (this.entryMatchesKeyOnEnrollmentGroupExternalId(historyEntry, cacheKey)) {
            let previousHistoryEntry = this.history[step - 2];
            return {
                resendRequest: true,
                productSelectUrl: this.getProductSelectUrl(historyEntry, previousHistoryEntry),
                formGroupValues: previousHistoryEntry?.formGroupValues,
                flexForm: previousHistoryEntry?.flexForm
            };
        } else {
            this.history = [];
            return null;
        }
    }

    navigateToFlexForm(
        enrollmentGroupExternalId: string,
        step: number
    ) {
        this.stateService.go('flex', {
            enrollmentGroupExternalId,
            step: step.toString(),
            emailVerification: ''
        });
    }

    pushFlexForm(
        flexForm: FlexForm,
        submittedFormGroupValues: { [key: string]: any },
        nextStep: number,
        cacheKey: FlexFormHistoryCacheKey
    ) {
        while (this.history.length >= nextStep) {
            this.history.pop();
        }

        this.history.push(new FlexFormHistoryEntry(flexForm, null, cacheKey));

        if (this.history.length > 1 && nextStep > 1) {
            this.history[nextStep - 2].formGroupValues = submittedFormGroupValues;
        }

        this.sessionStorageService.set(this.storageKey, this.history);
    }

    insertFlexForm(
        flexForm: FlexForm,
        currentStep: number,
        cacheKey: FlexFormHistoryCacheKey
    ) {
        if (currentStep >= 1) {
            this.history[currentStep - 1] = new FlexFormHistoryEntry(flexForm, this.history[currentStep - 1]?.formGroupValues, cacheKey)

            this.sessionStorageService.set(this.storageKey, this.history);
        }
    }

    entryMatchesKey(historyEntry: FlexFormHistoryEntry, cacheKey: FlexFormHistoryCacheKey): boolean {
        return (historyEntry?.key?.enrollmentGroupExternalId == cacheKey?.enrollmentGroupExternalId) &&
            (historyEntry?.key?.languageCode == cacheKey?.languageCode);
    }

    entryMatchesKeyOnEnrollmentGroupExternalId(historyEntry: FlexFormHistoryEntry, cacheKey: FlexFormHistoryCacheKey): boolean {
        return (historyEntry?.key?.enrollmentGroupExternalId == cacheKey?.enrollmentGroupExternalId)
    }

    private loadHistory() {
        let storedHistory = this.sessionStorageService.get(this.storageKey) as Array<FlexFormHistoryEntry>;
        if (storedHistory) {
            this.history = storedHistory;
        }
    }

    private getProductSelectUrl(historyEntry, previousHistoryEntry): string {
        const useProductSelectUrl = previousHistoryEntry?.flexForm.pageType === Constants.PAGE_TYPE.PRODUCT_SELECT
            && historyEntry?.flexForm.pageType === Constants.PAGE_TYPE.AGREEMENTS;

        if (useProductSelectUrl) {
            const indexOfProductCode = historyEntry.flexForm.submitUrl.split('/').indexOf('product') + 1;
            const currentProductCode = historyEntry.flexForm.submitUrl.split('/')[indexOfProductCode];

            return previousHistoryEntry.flexForm.fields.find((productField) => productField.displayName === currentProductCode).values[0].submitUrl;
        } else {
            return null;
        }
    }
}
