import {Component, Input, forwardRef, Output, EventEmitter, OnInit} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { EnrollmentGroup } from '../../../shared/enrollment-group.class';
import { SponsorEnrollmentGroupsService } from "../../../shared/sponsor-enrollment-groups.service";
import { SponsorSettings } from "../../../shared/sponsor-settings.class";

@Component({
    selector: 'vpe-employee-id',
    templateUrl: './employee-id.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmployeeIdComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EmployeeIdComponent),
            multi: true,
        }
    ]
})
export class EmployeeIdComponent implements ControlValueAccessor, Validator, OnInit {
    employeeId: string;
    customLabel: string;
    sensitiveEmployeeId: boolean;
    hiddenEmployeeId: boolean;
    touched = false;
    enableButton = false;
    employeeIdLength = 4;
    stateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    maxLength = null;
    sponsorId = null;
    enrollmentGroupId = null;
    customLabelName = null;
    enrollmentValidationRuleNames = ['EmployeeIdAndLastNameAndDateOfBirth'];

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
        this.customLabel = group.getFieldName('EmployeeId');
        this.customLabelName = group.getFieldName('EmployeeId');
        this.enrollmentGroupId = group.id;
    }

    @Input() set sponsorSettings(settings: SponsorSettings) {
      this.sensitiveEmployeeId = settings.sensitiveEmployeeId;
      this.hiddenEmployeeId = settings.sensitiveEmployeeId;
      this.sponsorId = settings.sponsorId;
      if (this.sensitiveEmployeeId) {
        this.maxLength = this.employeeIdLength;
      }
    }

    @Input() set tieBreakerOverride(enrollmentValidationRuleName: string) {
        if (this.enrollmentValidationRuleNames.includes(enrollmentValidationRuleName)) {
            this.maxLength = null;
        }
    }

    @Output() emitButtonEnable = new EventEmitter<boolean>();

    constructor(private translateService: TranslateService,
                private sponsorEnrollmentGroupsService: SponsorEnrollmentGroupsService
    ) {
    }

    ngOnInit() {
      this.initTranslation();
    }

    private initTranslation() {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          this.sponsorEnrollmentGroupsService.loadEnrollmentGroup(
            this.sponsorId,
            this.enrollmentGroupId,
            event.lang
          ).subscribe((group) => {
            if (!this.sensitiveEmployeeId) {
              this.customLabel = group.getFieldName('EmployeeId');

            } else {
              this.customLabel = this.customLabel.replace(this.customLabelName, group.getFieldName('EmployeeId'));
              this.customLabelName = group.getFieldName('EmployeeId');
            }
          });
      })
    }

    public writeValue(obj: any) {
        if (obj) {
            this.employeeId = obj.toString();
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
      this.propagateChange(this.employeeId);
      this.enableButton = !this.sensitiveEmployeeId || this.employeeId.length === this.employeeIdLength || this.maxLength == null;
      this.emitButtonEnable.emit(this.enableButton);
    }

    public onBlur() {
        this.touched = true;
        this.propagateTouch(true);
    }

    public toggleIcon() {
        this.hiddenEmployeeId = !this.hiddenEmployeeId;
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.employeeId) {
            return { 'required' : 'employeeId' };
        }

        return null;
    }
}
