import { Agreement } from "./agreement.class";

export class AgreementVM {
    public id: number;
    public product: string;

    constructor(agreement: Agreement) {
        this.id = agreement.id;
        this.product = agreement.product;
    }
}
