<div class="org-hierarchy-component"
     *ngIf="_member">
    <div class="form-group form-group-name"
         *ngIf="companies && companies.length > 1">
        <label for="company" translate="">
            {{ displayCustomCompanyLabel ? ('enrollment.forms.area' | translate) : ('enrollment.forms.company' | translate) }}
        </label>
        <div>
            <select class="form-input"
                    id="company"
                    [(ngModel)]="_member.company"
                    [ngClass]="{ 'validation-error': !_member.company }"
                    (change)="onCompanyChange()"
                    (blur)="onCompanyBlur()"
                    required
                    aria-describedby="oh-company-error">
                <option [ngValue]="null" disabled translate="">
                    {{ displayCustomCompanyLabel ? ('enrollment.forms.pickYourArea' | translate) : ('enrollment.forms.pickYourCompany' | translate) }}
                </option>
                <option class="notranslate"
                        *ngFor="let company of companies"
                        [ngValue]="company.name">
                    {{ (company.name) }}
                </option>
            </select>
        </div>
        <div *ngIf="!_member.company && companyTouched"
             id="oh-company-error">
            <small class="sr-only" translate="">
                enrollment.screenReader.error
            </small>
            <small class="error-text" translate="">
                enrollment.forms.errors.makeASelection
            </small>
        </div>
    </div>
    <div class="form-group form-group-name"
         *ngIf="businessUnits && businessUnits.length > 1">
        <label for="businessUnit" translate="">
            enrollment.forms.businessUnit
        </label>
        <div>
            <select class="form-input"
                    id="businessUnit"
                    [(ngModel)]="_member.businessUnit"
                    [ngClass]="{ 'validation-error': !_member.businessUnit }"
                    (change)="onChange()"
                    (blur)="onBusinessUnitBlur()"
                    required
                    aria-describedby="oh-bu-error">
                <option [ngValue]="null" disabled translate="">
                    enrollment.forms.selectYourBusinessUnit
                </option>
                <option class="notranslate"
                        *ngFor="let businessUnit of businessUnits"
                        [ngValue]="businessUnit.name">
                    {{ (businessUnit.name) }}
                </option>
            </select>
        </div>
        <div *ngIf="!_member.businessUnit && businessUnitTouched"
             id="oh-bu-error">
            <small class="sr-only" translate="">
                enrollment.screenReader.error
            </small>
            <small class="error-text" translate="">
                enrollment.forms.errors.makeASelection
            </small>
        </div>
    </div>
    <div class="form-group form-group-name"
         *ngIf="locations && locations.length > 1">
        <label for="location" translate="">
            enrollment.forms.location
        </label>
        <div>
            <select class="form-input"
                    id="location"
                    [(ngModel)]="_member.officeLocation"
                    [ngClass]="{ 'validation-error': !_member.officeLocation }"
                    (change)="onChange()"
                    (blur)="onLocationBlur()"
                    required aria-describedby="oh-location-error">
                <option [ngValue]="null" disabled translate="">
                    enrollment.forms.pickYourLocation
                </option>
                <option class="notranslate"
                        *ngFor="let location of locations"
                        [ngValue]="location.name">
                    {{ (location.name) }}
                </option>
            </select>
        </div>
        <div *ngIf="!_member.officeLocation && locationTouched"
             id="oh-location-error">
            <small class="sr-only" translate="">
                enrollment.screenReader.error
            </small>
            <small class="error-text" translate="">
                enrollment.forms.errors.makeASelection
            </small>
        </div>
    </div>
    <div class="form-group"
         *ngIf="!hasValidOrgHierarchy()">
        <div class="error-text org-error" translate="">
            enrollment.forms.errors.orgHeirarchyHasNotBeenConfigured
        </div>
    </div>
</div>
