<form-wrapper>
    <h1 class="page-title" translate="">enrollment.forms.signUp</h1>
    <div class="validation-header">
        <div *ngIf="isScreenCopySet">{{ enrollmentGroupSettingsText }}</div>
        <div *ngIf="!isScreenCopySet" [ngSwitch]="ruleType">
            <div *jrSwitchCases="['NoEligibilityFile', 'NoEligibilityFileWithOnlyBirthYear']"
                 [innerHtml]="'enrollment.forms.invitesYouToJoin' | translate:{ sponsorName: sponsorSettings.sponsorName }">
            </div>
            <div *ngSwitchDefault translate>enrollment.forms.letsMakeSureYoureEligible</div>
        </div>
    </div>
    <div *ngIf="ruleType" [ngSwitch]="ruleType">
        <no-eligibility-file-validation-form *ngSwitchCase="'NoEligibilityFile'"
                                             [memberData]="memberData"
                                             [sponsorSettings]="sponsorSettings"
                                             [validationRule]="_enrollmentGroup.enrollmentValidationRule">
        </no-eligibility-file-validation-form>
        <no-eligibility-file-birth-year-only-validation-form *ngSwitchCase="'NoEligibilityFileWithOnlyBirthYear'"
                                                             [memberData]="memberData"
                                                             [sponsorSettings]="sponsorSettings"
                                                             [validationRule]="_enrollmentGroup.enrollmentValidationRule">
        </no-eligibility-file-birth-year-only-validation-form>
        <lastname-dateofbirth-gender-validation-form *ngSwitchCase="'LastNameAndDateOfBirthAndGender'"
                                                     [memberData]="memberData"
                                                     [sponsorSettings]="sponsorSettings"
                                                     [enrollmentGroup]="_enrollmentGroup">
        </lastname-dateofbirth-gender-validation-form>
        <lastname-dateofbirth-state-validation-form *ngSwitchCase="'LastNameAndDateOfBirthAndState'"
                                                    [memberData]="memberData"
                                                    [sponsorSettings]="sponsorSettings"
                                                    [enrollmentGroup]="_enrollmentGroup">
        </lastname-dateofbirth-state-validation-form>
        <employeeid-lastname-dateofbirth-validation-form *ngSwitchCase="'EmployeeIdAndLastNameAndDateOfBirth'"
                                                         [memberData]="memberData"
                                                         [sponsorSettings]="sponsorSettings"
                                                         [enrollmentGroup]="_enrollmentGroup">
        </employeeid-lastname-dateofbirth-validation-form>
        <enrollment-code-validation-form *jrSwitchCases="['EnrollmentCode', 'EnrollmentCodeAndLastName']"
                                         [memberData]="memberData"
                                         [sponsorSettings]="sponsorSettings"
                                         [enrollmentGroup]="_enrollmentGroup">
        </enrollment-code-validation-form>
        <enrollmentcode-lastname-dateofbirth-validation-form *ngSwitchCase="'EnrollmentCodeAndLastNameAndDateOfBirth'"
                                                             [memberData]="memberData"
                                                             [sponsorSettings]="sponsorSettings"
                                                             [enrollmentGroup]="_enrollmentGroup">
        </enrollmentcode-lastname-dateofbirth-validation-form>
        <firstname-lastname-dateofbirth-validation-form *ngSwitchCase="'FirstNameAndLastNameAndDateOfBirth'"
                                                        [memberData]="memberData"
                                                        [sponsorSettings]="sponsorSettings"
                                                        [enrollmentGroup]="_enrollmentGroup">
        </firstname-lastname-dateofbirth-validation-form>
        <employeeid-validation-form *ngSwitchCase="'EmployeeId'"
                                    [memberData]="memberData"
                                    [sponsorSettings]="sponsorSettings"
                                    [enrollmentGroup]="_enrollmentGroup">
        </employeeid-validation-form>
        <basic-validation-form *ngSwitchDefault
                               [memberData]="memberData"
                               [sponsorSettings]="sponsorSettings"
                               [enrollmentGroup]="_enrollmentGroup">
        </basic-validation-form>
    </div>
</form-wrapper>
