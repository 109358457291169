<div class="form-group">
    <label for="customEnrollmentData">
        {{ _customEnrollmentDataField }}
    </label>
    <div>
        <input class="form-input"
               id="customEnrollmentData"
               maxlength="100"
               (change)="onChange()"
               (keyup)="onChange()"
               [(ngModel)]="customEnrollmentData"
               [ngClass]="{'validation-error': getError()}">
    </div>
    <div>
        <small class="error-text"
               *ngIf="getError() && getError().tooLong"
               id="custom-enrollment-data-error-length">
            Custom enrollment data is too long (max length: 100)
        </small>
        <small class="error-text"
               *ngIf="getError() && getError().required"
               id="custom-enrollment-data-error-required">
            Custom enrollment data is required
        </small>
    </div>
</div>
