import { Component } from '@angular/core';
import { ErrorBannerService } from './error-banner.service';

@Component({
  selector: 'error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent {
  showErrorBanner: boolean = false;
  errorType: string = '';

  constructor(
    private errorBannerService: ErrorBannerService
  ) {
    this.errorBannerService.bannerVisible$.subscribe(
    (visible) => {
      this.showErrorBanner = visible;
      if (visible) {
        this.errorBannerService.getErrorMessage().subscribe(
        (error) => {
          this.errorType = error;
        });
      }
    });
  }

  closeErrorBanner() {
    this.showErrorBanner = false;
    this.errorBannerService.setBannerVisible(false, '');
  }

}
