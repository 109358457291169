<div class="form-group">
    <div>
        <div class="email-image">
            <img src="assets/images/email-green.svg" alt="Email">
        </div>
        <div translate="" class="check-your-inbox"
            [innerHtml]="'enrollment.flex.verificationSent.checkInbox' | translate: { emailAddress: emailAddress }">
        </div>
        <div translate="" class="dont-see-it-yet"
            [innerHTML]="'enrollment.flex.verificationSent.dontSeeItYet' | translate: {}">
        </div>
        <div translate="" class="still-need-help"
            [innerHtml]="'enrollment.flex.verificationSent.stillNeedHelp' | translate: { memberServicesEmail: memberServicesEmail }">
        </div>

    </div>
</div>
