import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class EnrollmentHttpInterceptor implements HttpInterceptor {

    KEY_ITEM = 'X-Authenticator-Device'

    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
    	if (req.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
    		return next.handle(req);
        }

        const headers = req.headers.set('Content-Type', 'application/json; charset=utf-8')
                                   .set(this.KEY_ITEM, this.getEnrollmentFingerprint());

        const request = req.clone({
            headers
        });

        return next.handle(request);
    }

    private getEnrollmentFingerprint() {
        const fingerPrint = localStorage.getItem(this.KEY_ITEM);
        if (fingerPrint && fingerPrint !== '') { return fingerPrint; }

        return Date.now().toString();
    }
}
