import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageNameService {
    private pageNameSubject: BehaviorSubject<string>

    constructor() {
        this.pageNameSubject = new BehaviorSubject('')
    }

    setPageName(name: string) {
        this.pageNameSubject.next(name)
    }

    getPageName() {
        return this.pageNameSubject.asObservable();
    }
}
