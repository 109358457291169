import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsyncSubject, Observable } from 'rxjs';

import { Agreement } from './agreement.class';
import { ProductCode } from "./product.enum";
import { Utils } from '../utils/Utils.class';

enum AgreementCallType {
    LIST,
    SUBMIT
}

@Injectable()
export class AgreementsService {
    private subject: AsyncSubject<Array<Agreement>> = null;
    private acceptedAgreements: AsyncSubject<void> = null;
    private currentProduct: string;
    private currentSponsorId: number;
    private currentLanguage: string;
    private currentCountryId: number;
    private lastCalled: AgreementCallType;

    constructor(
        private http: HttpClient
    ) { }

    getAgreementsList(product, sponsorId, language, countryId): Observable<Array<Agreement>> {
        let AGREEMENTS_URL: string = Utils.isNullOrUndefined(countryId) ? `/enrollment-api/sponsors/${sponsorId}/agreements?language=${language}&product=${product}`
            : `/enrollment-api/sponsors/${sponsorId}/agreements?language=${language}&countryId=${countryId}&product=${product}`;

        if (this.currentLanguage === language && this.currentSponsorId === sponsorId && this.currentCountryId === countryId && this.currentProduct === product &&
            this.lastCalled === AgreementCallType.LIST && this.subject === null) {
            return this.subject;
        }

        this.subject = new AsyncSubject();
        this.currentProduct = product;
        this.currentLanguage = language;
        this.currentSponsorId = sponsorId;
        this.currentCountryId = countryId;

        this.http.get<Agreement[]>(AGREEMENTS_URL).subscribe((agreements) => {
            this.subject.next(agreements);
            this.subject.complete();
            this.subject = null;
        }, (err) => {
            this.subject.error(err);
            this.subject.complete();
            this.subject = null;
        });

        return this.subject;
    }

    acceptAgreements(product: ProductCode, sponsorId: number, eligibilityId: string, agreementIds: Array<number>): Observable<void> {
        const AGREEMENTS_URL: string = `/enrollment-api/sponsors/${sponsorId}/agreements?product=${product}&id=${eligibilityId}&agreement=${agreementIds.join('&agreement=')}`;

        if (this.currentProduct === product && this.lastCalled === AgreementCallType.SUBMIT && this.acceptedAgreements !== null) {
            return this.acceptedAgreements;
        }

        this.currentProduct = product;
        this.acceptedAgreements = new AsyncSubject<void>();

        this.http.post<void>(AGREEMENTS_URL, {}).subscribe(() => {
            this.acceptedAgreements.next();
            this.acceptedAgreements.complete();
            this.acceptedAgreements = null;
        }, (err) => {
            this.acceptedAgreements.error(err);
            this.acceptedAgreements.complete();
            this.acceptedAgreements = null;
        });

        return this.acceptedAgreements;
    }
}
