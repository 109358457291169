import { Component, Input } from '@angular/core';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';

@Component({
    selector: 'reactive-waitlist-confirmation',
    templateUrl: './reactive-waitlist-confirmation.component.html',
    styleUrls: ['./reactive-waitlist-confirmation.component.scss']
})
export class ReactiveWaitlistConfirmationComponent {

    @Input()
    field: FlexFormField;
}
