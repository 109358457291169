import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultItemInputs } from '@engineering/genesis-frontend';
import { Constants } from '../../../../shared/constants.class';
import { BaseFormField } from '../../../../shared/flex-forms/base-form-field.class';
import { FlexFormDropdownData } from '../../../../shared/flex-forms/flex-form-dropdown-data.class';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';
import { SecurityQuestion } from '../../../../shared/security-question.class';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
    selector: 'reactive-security-questions',
    templateUrl: './reactive-security-questions.component.html',
    styleUrls: ['./reactive-security-questions.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveSecurityQuestionsComponent)
        }
    ]
})
export class ReactiveSecurityQuestionsComponent extends BaseFormField implements OnInit, ControlValueAccessor {
    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;

    CONSTANTS = [
        {
            QUESTION_LABEL: 'enrollment.flex.securityQuestion.question1',
            ANSWER_LABEL: 'enrollment.flex.securityQuestion.answer1',
            MIN_LENGTH_ERROR: this.VALIDATORS.MIN_SECURITY_QUESTION_1_ANSWER_LENGTH
        },
        {
            QUESTION_LABEL: 'enrollment.flex.securityQuestion.question2',
            ANSWER_LABEL: 'enrollment.flex.securityQuestion.answer2',
            MIN_LENGTH_ERROR: this.VALIDATORS.MIN_SECURITY_QUESTION_2_ANSWER_LENGTH
        },
        {
            QUESTION_LABEL: 'enrollment.flex.securityQuestion.question3',
            ANSWER_LABEL: 'enrollment.flex.securityQuestion.answer3',
            MIN_LENGTH_ERROR: this.VALIDATORS.MIN_SECURITY_QUESTION_3_ANSWER_LENGTH
        },
    ];

    securityQuestions: FlexFormDropdownData[][] = [[], [], []];
    grooveOptions: DropdownOptions[][] = [[], [], []];

    securityQuestionsWithAnswers: SecurityQuestion[] = [
        new SecurityQuestion(1),
        new SecurityQuestion(2),
        new SecurityQuestion(3)
    ];

    constructor() {
        super();
    }

    get VALIDATORS() {
        return Constants.VALIDATORS;
    }

    ngOnInit(): void {
        this.securityQuestions.forEach((_, i) => {
            this.securityQuestions[i] = this.field?.values as FlexFormDropdownData[];
            this.grooveOptions[i] = this.createGrooveOptions(this.securityQuestions[i]);
        });
    }

    writeValue(newSecurityQuestions: any): void { }

    onAnswerChange(questionIndex: number, answer: string): void {
        this.securityQuestionsWithAnswers[questionIndex].answer = answer;
        this.propagateChange(this.securityQuestionsWithAnswers);
    }

    onQuestionChange(questionIndex: number, questionName: string) {
        let selectedQuestionId = this.securityQuestions[questionIndex]?.find((q) => {
            return q['name'] === questionName;
        })?.id;

        this.securityQuestionsWithAnswers[questionIndex].securityQuestionId = parseInt(selectedQuestionId);
        this.securityQuestionsWithAnswers[questionIndex].answer = null;

        const questionIndices = [0, 1, 2];
        questionIndices.splice(questionIndex, 1);

        this.securityQuestions[questionIndices[0]] = this.removeSelectedQuestions([
            this.securityQuestionsWithAnswers[questionIndex].securityQuestionId,
            this.securityQuestionsWithAnswers[questionIndices[1]].securityQuestionId
        ]);
        this.grooveOptions[questionIndices[0]] = this.createGrooveOptions(this.securityQuestions[questionIndices[0]]);

        this.securityQuestions[questionIndices[1]] = this.removeSelectedQuestions([
            this.securityQuestionsWithAnswers[questionIndex].securityQuestionId,
            this.securityQuestionsWithAnswers[questionIndices[0]].securityQuestionId
        ]);
        this.grooveOptions[questionIndices[1]] = this.createGrooveOptions(this.securityQuestions[questionIndices[1]]);

        this.propagateChange(this.securityQuestionsWithAnswers);
    }

    onTouch(): void {
        this.propagateTouch();
    }

    createGrooveOptions(questions: FlexFormDropdownData[]): DropdownOptions[] {
        return questions?.map((q) => {
            return { text: q.name };
        });
    }

    private removeSelectedQuestions(valuesToRemove: number[]) {
        return (this.field?.values as FlexFormDropdownData[])?.filter((q) => {
            return !valuesToRemove?.map(v => v?.toString())?.includes(q?.id);
        });
    }
}
