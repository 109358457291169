import { Component, forwardRef } from '@angular/core';
import { CountryService } from '../../../shared/country.service';
import { Country } from '../../../shared/country.class';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as _ from 'lodash';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

@Component({
    selector: 'vpe-country',
    templateUrl: './country.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountryComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountryComponent),
            multi: true,
        }
    ]
})
export class CountryComponent implements ControlValueAccessor, Validator {
    countries: Array<Country>;
    currentLanguage: string;
    currentCountry: Country = null;

    constructor(private countryService: CountryService, private translateService: TranslateService) {
        translateService.onLangChange.subscribe(() => {
            this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
            this.countryService.getCountries(this.currentLanguage).subscribe((countries) => {
                this.countries = countries;
                if (this.currentCountry) {
                    this.currentCountry = _.find(this.countries, {id: this.currentCountry.id});
                    this.countryService.setSelectedCountry(this.currentCountry);
                }
            });
        });
    }

    public writeValue(obj: string) {
        this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
        this.countryService.getCountries(this.currentLanguage).subscribe((countries) => {
            if (!this.countries) {
                this.countries = countries;
            }
            let country: Country = null;

            if (obj) {
                country = _.find(this.countries, {'name': obj});
            }
            if (country !== this.currentCountry) {
                this.currentCountry = country;
                this.onChange();
            }
        });
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        const countryName = _.get(this.currentCountry, 'englishName') || _.get(this.currentCountry, 'name');
        this.propagateChange(countryName);
        this.countryService.setSelectedCountry(this.currentCountry);
    }

    public onBlur() {
        this.propagateTouch(true);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.currentCountry) {
            return { 'required' : 'country' };
        }
        return null;
    }
}
