<div>
    <groove-dropdown-field class="groove-wrapper"
                           [id]="field?.fieldName + '-input'"
                           [fieldName]="field?.fieldName"
                           [label]="field?.displayName"
                           [isDisabled]="field?.editable === false"
                           (valueChanged)="onChange($event)"
                           (touched)="onTouch()"
                           [placeholder]="field?.placeholder"
                           [data]="grooveOptions"
                           [value]="selectedStateProvince"
                           [isRequired]="isRequired">
    </groove-dropdown-field>
</div>
