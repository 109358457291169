<div class="form-group" *ngIf="isCountryUS">
    <label for="stateOfResidence" translate="">
        enrollment.forms.stateOfResidence
    </label>
    <div>
        <select class="form-input timezone"
                id="stateOfResidence"
                (change)="onChange()"
                (blur)="onBlur()"
                required
                [(ngModel)]="currentState">
            <option [ngValue]="null" disabled translate="">
                enrollment.forms.stateOfResidence
            </option>
            <option *ngFor="let state of states"
                    [ngValue]="state">
                {{ state.name }}
            </option>
        </select>
    </div>
</div>
