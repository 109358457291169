import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormControl,
  Validator
} from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PasswordResetChallenge } from '../../../shared/passwordResetChallenge.enum';

const minAge = 16;
const maxAge = 100;

@Component({
  selector: 'vpe-birthyear',
  templateUrl: './birthyear.component.html',
  styleUrls: ['./birthyear.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthyearComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BirthyearComponent),
      multi: true,
    }
  ]
})
export class BirthyearComponent implements ControlValueAccessor, Validator {
  birthYear: number = null;
  availableYears: Array<number>;
  touched = false;
  passwordResetTypeDOB = false;

  @Input() popoverText: string;

  @Input() set passwordResetChallenge(passwordResetChallenge: string) {
      if (passwordResetChallenge) {
          this.passwordResetTypeDOB = passwordResetChallenge === PasswordResetChallenge.DOB;
      }
  }

  constructor() {
    let currentYear = moment().year();
    this.availableYears = _.rangeRight(currentYear - maxAge, currentYear - minAge + 1);
  }

  public writeValue(obj: any) {
    if (obj) {
      let birthDate: moment.Moment = moment(obj);
      if (birthDate.isValid()) {
        this.birthYear = birthDate.year();
      }
    }
  }

  public validate(c: UntypedFormControl): {[key: string]: any} {
    return this.getError();
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  private propagateChange = (_: any) => { };
  private propagateTouch = (_: any) => { };

  public onChange() {
    let maxYearLength = 4;
    let yearString = this.birthYear ? this.birthYear.toString() : '';
    if (yearString.length > maxYearLength) {
      yearString = yearString.slice(0, maxYearLength);
      this.birthYear = Number.parseInt(yearString);
    }

    this.propagateChange(this.getBirthdateAsMoment());
  }

  public onBlur() {
    this.touched = true;
    this.propagateTouch(true);
  }

  public getError(): {[key: string]: any} {
    if(!this.birthYear) {
      return { 'required': 'birthyear' };
    }

    let currentDate = moment();
    let parsedDate = this.getBirthdateAsMoment();

    if (!parsedDate.isValid()) {
      return { 'invalid': 'birthyear' };
    }

    if (currentDate.clone().subtract(minAge, 'years').isBefore(parsedDate) && currentDate.isAfter(parsedDate)) {
      return { 'lowAge': 'birthyear' };
    }

    if (currentDate.clone().subtract(maxAge, 'years').isAfter(parsedDate) || currentDate.isSameOrBefore(parsedDate)) {
      return { 'highAge': 'birthyear' };
    }

    return null;
  }

  public getBirthdateAsMoment() {
    return moment.utc([this.birthYear, 0, 1]);
  }
}
