import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { StateOfResidence } from './state-of-residence.class';

@Injectable()
export class StateOfResidenceService {
  private stateOfResidenceList: Observable<Array<StateOfResidence>>;

  constructor(private http: HttpClient) { }

  getStates(currentLanguage: string) {
    let STATES_URL: string = 'genesis-api/states';

    this.stateOfResidenceList = this.http.get<StateOfResidence[]>(STATES_URL, {
      params: {
        langCode: currentLanguage
      }
    });
                
    return this.stateOfResidenceList;
  }
}