import { Injectable } from '@angular/core';
import { EligibleProduct } from './eligible-product';

@Injectable()
export class EligibleProductService {
    acceptedProductCodes(products: EligibleProduct[], lowerCase: boolean = true): string[] {
        return products.filter((product) => product.accepted)
            .map((product) => lowerCase ? product.productCode.toLowerCase() : product.productCode);
    }
}
