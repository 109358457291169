import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { StateService, Transition } from "@uirouter/angular";

import { SponsorValidationService } from '../validation/sponsor-validation.service';
import { CurrentMemberService } from '../shared/current-member.service';
import { MemberEnrollmentData } from '../shared/member-enrollment-data.class';
import { SponsorSettings } from '../shared/sponsor-settings.class';
import { EnrollmentGroup } from '../shared/enrollment-group.class';
import { ValidationTransitionService } from "../shared/validation-transition.service";
import { environment } from "../../environments/environment";

@Component({
    templateUrl: './identify.component.html',
    styleUrls: ['./identify.component.scss']
})
export class IdentifyComponent implements OnInit {
    saveError: number = null;
    member: MemberEnrollmentData;
    identifyForm: UntypedFormGroup;
    supportLink: string = 'https://' + environment.zendesk.url + '/hc/en-us/requests/new?ticket_form_id=56434';
    enableButton = false;

    @Input() sponsorSettings: SponsorSettings;
    @Input() enrollmentGroup: EnrollmentGroup;

    constructor(
        private sponsorValidationService: SponsorValidationService,
        private currentMemberService: CurrentMemberService,
        private validationTransitionService: ValidationTransitionService,
        private state: StateService,
        public transition: Transition,
        private fb: UntypedFormBuilder
    ){
        this.member = this.currentMemberService.memberEnrollmentData;
    }

    ngOnInit() {
        this.identifyForm = this.fb.group({
            employeeId: ['']
        });
    }

    onSubmit() {
        this.member.sponsorId = this.sponsorSettings.sponsorId;
        this.member.legacySponsorId = this.sponsorSettings.legacySponsorId;

        this.sponsorValidationService
            .validateMemberResolved(this.member)
            .subscribe((result) => {
                this.currentMemberService.memberEnrollmentData = result;
                this.saveError = null;

                const transition = this.validationTransitionService.getValidationTransition(this.transition.params(), this.currentMemberService.memberEnrollmentData.eligibleProducts);

                this.state.go(transition.target, transition.params);
            },
        (error) => {
            this.saveError = error;
        });
    }

  detectButtonEnableChange(change) {
      this.enableButton = change;
  }

}
