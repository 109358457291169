import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FlexFormService } from 'src/app/shared/flex-forms/flex-form.service';
import { FlexForm } from 'src/app/shared/flex-forms/flex-form.class';
import { Constants } from 'src/app/shared/constants.class';
import { TranslateService } from '@ngx-translate/core';
import { FlexFormHistoryService } from 'src/app/shared/flex-forms/flex-form-history.service';
import { ProgressBarStepData } from 'src/app/shared/flex-forms/progress-bar-step-data.class';
import { UrlService } from '@uirouter/angular';
import { RegistrationDetails } from 'src/app/shared/registration-details.class';
import { SponsorSettingsService } from 'src/app/shared/sponsor-settings.service';
import { FlexFormError } from 'src/app/shared/flex-forms/flex-form-error.class';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { IconService } from '@engineering/genesis-frontend';
import { KeycloakLoginService } from 'src/app/shared/authentication/keycloak-login.service';

/**
 * Owns the current {@link FlexForm} data, representing the broad state of the main page content.
 */
@Component({
    selector: 'flex-form',
    templateUrl: './flex-form.component.html',
    styleUrls: ['./flex-form.component.scss'],
})
export class FlexFormComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup = new UntypedFormGroup({});
    flexForm: FlexForm = null;
    pageTypesWithoutContinueButton = [
        Constants.PAGE_TYPE.VERIFICATION_EMAIL_SENT,
        Constants.PAGE_TYPE.ENROLLMENT_SUCCESSFUL,
        Constants.PAGE_TYPE.SPONSOR_CAP_WAITLIST_CONFIRMATION,
        Constants.PAGE_TYPE.COMPANY_CAP_WAITLIST_CONFIRMATION,
        Constants.PAGE_TYPE.REGISTRATION_CLOSED,
        Constants.PAGE_TYPE.REGISTRATION_CLOSED_AGAIN,
        Constants.PAGE_TYPE.REGISTRATION_SCHEDULED,
        Constants.PAGE_TYPE.SPONSOR_SEARCH
    ];
    pagesTypesWithLanguageDropdown = [
        Constants.PAGE_TYPE.VALIDATION,
        Constants.PAGE_TYPE.VALIDATION_TIE_BREAKER,
        Constants.PAGE_TYPE.SPONSOR_CAP_WAITLIST,
        Constants.PAGE_TYPE.REGISTRATION_CLOSED,
        Constants.PAGE_TYPE.PRODUCT_SELECT,
        Constants.PAGE_TYPE.AGREEMENTS,
        Constants.PAGE_TYPE.REGISTRATION_SCHEDULED,
        Constants.PAGE_TYPE.SPONSOR_SEARCH,
        Constants.PAGE_TYPE.ENROLLMENT_GROUP_SELECTION
    ];
    showSignInLink: boolean = true;
    errorMessage: string = null;
    /** URL to which to send already-enrolled members that realize they don't belong in the enrollment flow. */
    signInLink: string = environment.signIn.vpLink;
    registrationDetails: RegistrationDetails = null;
    continueBtnTranslation: string = null;
    showSubmitButton: boolean = true;
    isSubmitPending: boolean;
    isSponsorSearchPage = false;

    languageChangeSubscription: Subscription = new Subscription();
    newFlexFormSubscription: Subscription = new Subscription();

    @Output()
    stepDataEvent = new EventEmitter<ProgressBarStepData[]>(true);

    @Output()
    languageDropdownVisibleEvent = new EventEmitter<boolean>(true);

    @Output()
    leftPanelImageEvent = new EventEmitter<string>(true);

    @Output()
    sidebarIntroEvent = new EventEmitter<string>(true);

    @Output()
    logoUrlEvent = new EventEmitter<string>(true);

    @Input()
    enrollmentGroupExternalId: string;

    @Input()
    emailToken: string;

    @Input()
    eligibilityToken: string;

    @Input()
    ssoRedirectPath: string;

    @Input()
    redirectedFrom: string;

    @Input()
    sponsorExternalId: string;

    get url(): string {
        if (!this.enrollmentGroupExternalId) {
            return this.sponsorExternalId ? `/enrollment-api/sponsor/${this.sponsorExternalId}/enrollment-group-select` : '/enrollment-api/sponsor-search';
        }

        let baseUrl = `/enrollment-api/enrollment-group/${this.enrollmentGroupExternalId}/enroll`;

        if (this.emailToken) {
            return baseUrl + `/verify-email/${this.emailToken}`;
        } else {
            return baseUrl + '/initial';
        }
    }

    get language(): string {
        return this.translateService.currentLang || this.translateService.defaultLang;
    }

    constructor(
        private flexFormService: FlexFormService,
        public translateService: TranslateService,
        private flexFormHistoryService: FlexFormHistoryService,
        private urlService: UrlService,
        private sponsorSettingsService: SponsorSettingsService,
        private iconService: IconService,
        private keycloakLoginService: KeycloakLoginService,
    ) {
        this.sponsorSettingsService.getRegistrationDetails().subscribe(
            (registrationDetails: RegistrationDetails) => {
            this.showSignInLink = (registrationDetails?.sponsorSettings?.customEligibilityDataSource?.name !== Constants.CUSTOM_ELIGIBILITY_DATA_SOURCE.AETNA_VSI);
            this.registrationDetails = registrationDetails;
        });

        this.iconService.register({ 'exclamation-circle': 'fa fa-exclamation-circle' })
    }

    ngOnInit() {
        this.isSubmitPending = false;
        this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            if (this.flexForm) {
                this.getFlexForm();
            }
        });

        this.getFlexForm();

        this.newFlexFormSubscription = this.flexFormService.flexFormSubject.subscribe((newFlexForm: FlexForm) => {
            if (newFlexForm) {
                this.navigateToNewFlexForm(newFlexForm);
            }
        }, (response: any) => {
            this.handleFlexFormError(response);
        });
    }

    ngOnDestroy() {
        this.languageChangeSubscription.unsubscribe();
        this.newFlexFormSubscription.unsubscribe();
    }

    getFlexFormFromAPI(languageCode: string) {
        this.flexFormService.getFlexForm(this.url, languageCode, this.eligibilityToken, this.ssoRedirectPath, this.redirectedFrom)
        .toPromise()
        .then((newFlexForm: FlexForm) => {
            const addToHistory = newFlexForm.pageType !== Constants.PAGE_TYPE.SPONSOR_SEARCH && newFlexForm.pageType !== Constants.PAGE_TYPE.ENROLLMENT_GROUP_SELECTION;
            this.displayFlexForm(newFlexForm, addToHistory);
        }, (response: any) => {
            this.handleFlexFormError(response);
        });
    }

    handleFlexFormError(response: any, flexForm: FlexForm = null) {
        this.isSubmitPending = false;
        let flexFormError = response?.error as FlexFormError;
        if (flexFormError?.errorMessage == 'Sponsor org hierarchies could not be found.') {
            this.showSubmitButton = false;
        }
        let responseErrorMessage = flexFormError?.translatedUserErrorMessage;
        if (responseErrorMessage) {
            this.errorMessage = responseErrorMessage;
        } else {
            this.translateService.get('enrollment.forms.oopsSomethingWentWrong').subscribe((defaultErrorMessage: string) => {
                this.errorMessage = defaultErrorMessage;
            });
        }

        this.sendErrorAnalyticsData(flexForm, flexFormError);
    }

    sendErrorAnalyticsData(flexForm: FlexForm, flexFormError: FlexFormError) {
        if (flexForm?.analyticsData) {
            let errorAnalyticsData = flexForm.analyticsData
            errorAnalyticsData.event_name = "Enrollment Error"
            errorAnalyticsData["page_type"] = flexForm.pageType
            errorAnalyticsData["error_code"] = flexFormError?.errorCode
            this.flexFormService.sendAnalyticsData(errorAnalyticsData);
        }
    }

    getFlexForm() {
        if (this.emailToken) {
            this.getFlexFormFromAPI(null);
            return;
        }

        let step = this.getStep();
        let existingForm = this.flexFormHistoryService.getFlexForm(step, {
            enrollmentGroupExternalId: this.enrollmentGroupExternalId,
            languageCode: this.language
        });

        if (existingForm?.resendRequest && existingForm?.formGroupValues && existingForm?.flexForm) {
            this.isSubmitPending = true;
            this.flexFormService.submitFlexForm(
                existingForm.formGroupValues,
                existingForm.flexForm,
                this.language,
                existingForm.productSelectUrl
            ).toPromise().then((newFlexForm: FlexForm) => {
                this.isSubmitPending = false;
                this.errorMessage = null;
                this.displayFlexForm(newFlexForm, true);
            }, (response: any) => {
                this.handleFlexFormError(response, existingForm.flexForm);
            });
        } else if (existingForm?.flexForm) {
            this.displayFlexForm(existingForm.flexForm, false);
        } else if (step != 1) {
            this.rewriteUrl();
        } else {
            this.getFlexFormFromAPI(this.language);
        }
    }

    onSubmit() {
        if (this.flexForm.pageType === Constants.PAGE_TYPE.SPONSOR_SEARCH) {
            return;
        }

        if (this.flexForm.pageType === Constants.PAGE_TYPE.ENROLLMENT_GROUP_SELECTION) {
            const enrollmentGroupGuid = this.form.controls['enrollmentGroup'].value;
            this.urlService.url(`/enrollmentGroups/${enrollmentGroupGuid}/step/1`, false);
            return;
        }

        this.isSubmitPending = true;

        this.flexFormService.submitFlexForm(
            this.form.value,
            this.flexForm,
            this.language
        ).toPromise().then((newFlexForm: FlexForm) => {
            this.navigateToNewFlexForm(newFlexForm);
        }, (response: any) => {
            this.handleFlexFormError(response, this.flexForm);
        });
    }

    navigateToNewFlexForm(newFlexForm: FlexForm) {
        this.isSubmitPending = false;
        this.errorMessage = null;
        this.addFormToHistory(newFlexForm, this.form.value);

        this.languageChangeSubscription.unsubscribe();
        this.flexFormHistoryService.navigateToFlexForm(this.enrollmentGroupExternalId, this.getStep() + 1);
    }

    displayFlexForm(newFlexForm: FlexForm, addToHistory: boolean) {
        this.flexForm = newFlexForm;
        this.form = this.flexFormService.createFormGroup(this.flexForm);

        this.isSponsorSearchPage = this.flexForm.pageType === Constants.PAGE_TYPE.SPONSOR_SEARCH;

        if (!this.flexForm.submitUrl
            && this.flexForm.pageType !== Constants.PAGE_TYPE.SPONSOR_SEARCH
            && this.flexForm.pageType !== Constants.PAGE_TYPE.ENROLLMENT_GROUP_SELECTION) {
            this.showSubmitButton = false;
        }

        this.signInLink = this.keycloakLoginService.getKeycloakSignInUrl(this.flexForm.attributes?.identityProviderType ?? null);

        this.emitLanguageDropdownVisible(this.pagesTypesWithLanguageDropdown.includes(this.flexForm.pageType));
        this.emitProgressSteps(newFlexForm.progressBarSteps);
        this.emitLeftPanelImageEvent(this.flexForm.attributes?.leftPanelImageUrl);
        this.emitSidebarIntroEvent(this.flexForm.attributes?.sidebarIntro);
        this.emitLogoUrlEvent(this.flexForm.attributes?.logoUrl);

        if (addToHistory) {
            this.insertFormToHistory(newFlexForm);
        }

        if (this.flexForm.pageType !== Constants.PAGE_TYPE.VALIDATION
            && this.flexForm.pageType !== Constants.PAGE_TYPE.SPONSOR_SEARCH
            && this.flexForm.pageType !== Constants.PAGE_TYPE.ENROLLMENT_GROUP_SELECTION) {
            this.showSignInLink = false;
        }

        this.continueBtnTranslation = this.getBtnTranslation(this.flexForm);
        if (newFlexForm.languageCode && newFlexForm.languageCode != this.language) {
            this.translateService.use(newFlexForm.languageCode);
        }

        this.flexFormService.sendAnalyticsData(newFlexForm.analyticsData);
    }

    getBtnTranslation(flexForm: FlexForm): string {
        switch(flexForm.pageType) {
            case Constants.PAGE_TYPE.VALIDATION: {
                return 'enrollment.forms.submit';
            }
            case Constants.PAGE_TYPE.VALIDATION_TIE_BREAKER:
            case Constants.PAGE_TYPE.AGREEMENTS: {
                return 'enrollment.forms.next';
            }
            case Constants.PAGE_TYPE.ACCOUNT_INFORMATION: {
                return 'enrollment.forms.createMyAccount';
            }
            case Constants.PAGE_TYPE.SPONSOR_CAP_WAITLIST:
            case Constants.PAGE_TYPE.COMPANY_CAP_WAITLIST: {
                return 'enrollment.forms.signUp';
            }
            default: {
                return 'enrollment.forms.continue';
            }
        }
    }

    addFormToHistory(newFlexForm: FlexForm, submittedFormGroupValues: { [key: string]: any }) {
        this.flexFormHistoryService.pushFlexForm(newFlexForm, submittedFormGroupValues, this.getStep() + 1, {
            enrollmentGroupExternalId: this.enrollmentGroupExternalId,
            languageCode: newFlexForm.languageCode
        });
    }

    insertFormToHistory(newFlexForm: FlexForm) {
        this.flexFormHistoryService.insertFlexForm(newFlexForm, this.getStep(), {
            enrollmentGroupExternalId: this.enrollmentGroupExternalId,
            languageCode: newFlexForm.languageCode
        });
    }

    getFlexStyle(columns: number): any {
        const ONE_HUNDRED_PERCENT = 100;
        const PIXEL_SIZE_OF_GAP = 5;
        const subtractForGap = ((columns - 1) * PIXEL_SIZE_OF_GAP) / columns;

        return {'flex': `calc(${ONE_HUNDRED_PERCENT / columns || 1}% - ${subtractForGap}px)`}
    }

    emitProgressSteps(steps: ProgressBarStepData[]) {
        this.stepDataEvent.emit(steps);
    }

    emitLeftPanelImageEvent(imageUrl: string) {
        this.leftPanelImageEvent.emit(imageUrl);
    }

    emitSidebarIntroEvent(sidebarIntro: string) {
        this.sidebarIntroEvent.emit(sidebarIntro);
    }

    emitLanguageDropdownVisible(isLanguageDropdownVisible: boolean) {
        this.languageDropdownVisibleEvent.emit(isLanguageDropdownVisible);
    }

    emitLogoUrlEvent(logoUrl: string) {
        this.logoUrlEvent.emit(logoUrl);
    }

    rewriteUrl() {
        let newUrl = `/enrollmentGroups/${this.enrollmentGroupExternalId}/step/1`;
        if (this.emailToken) {
            newUrl += `?emailToken=${this.emailToken}`;
        }

        this.urlService.url(newUrl, true);
    }

    getStep(): number {
        let pathComponents = this.urlService.path()?.split('/');

        if (pathComponents?.length) {
            return parseInt(pathComponents[pathComponents?.length - 1]) || 1;
        } else {
            return 1;
        }
    }
}
