import { Component, Input } from '@angular/core';

import { StateService, Transition } from "@uirouter/angular";
import { AnalyticsTrackingCommandsService } from '../analytics-tracking/analyticsTrackingCommands.service';
import { PasswordCreationPageModel } from '../analytics-tracking/domain/password-creation-page.model';

import { CurrentMemberService } from '../shared/current-member.service';
import { EligibleProductService } from '../shared/eligible-product.service';
import { EnrollmentService } from './enrollment.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { SponsorSettings } from '../shared/sponsor-settings.class';
import { EnrollmentGroup } from '../shared/enrollment-group.class';
import { ErrorBannerService } from '../header/error-banner/error-banner.service';
import { NonFileValidationRules } from '../validation/validation-rule-type.enum';
import { EnrollmentGroupsSettingsService } from '../shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-groups-settings.service';

import * as _ from 'lodash';
import { EnrollmentGroupUtil } from '../shared/enrollment-group-util';

@Component({
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignUpComponent {
    ruleType: string = null;
    goBackState: string = null;
    _sponsorSettings: SponsorSettings;
    _enrollmentGroup: EnrollmentGroup;
    enrollmentGroupSettingsText: String = null;
    isScreenCopySet: Boolean = false;
    isEmailConfirmationRequired = false;
    isEmailOptional = false; // mutually exclusive with isEmailConfirmationRequired
    isAbbreviatedEnrollment = false;
    customEnrollmentDataField: string;

    @Input() set sponsorSettings(settings: SponsorSettings) {
        this._sponsorSettings = settings;
        if (this._enrollmentGroup) {
            this.checkToken();
        }
    }

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
        this._enrollmentGroup = group;
        if (this._enrollmentGroup.requireEmailConfirmation) {
            this.isEmailConfirmationRequired = EnrollmentGroupUtil.isEmailConfirmationRequired(this._enrollmentGroup.requireEmailConfirmation, this.currentMemberService.memberEnrollmentData.emailAddress);
        }
        if (this._enrollmentGroup.emailOptional) {
            this.isEmailOptional = this._enrollmentGroup.emailOptional;
        }
        if (this._enrollmentGroup.abbreviatedEnrollment) {
            this.isAbbreviatedEnrollment = this._enrollmentGroup.abbreviatedEnrollment;
        }
        if (this._sponsorSettings) {
            this.checkToken();
        }

        if (this._enrollmentGroup.customEnrollmentDataField) {
            this.customEnrollmentDataField = this._enrollmentGroup.customEnrollmentDataField;
        }
    }

    @Input() set enrollmentGroupSettings(settings: Object) {
        this.setEnrollmentGroupSettingsText(settings);
    }

    constructor(
        public currentMemberService: CurrentMemberService,
        public transition: Transition,
        private state: StateService,
        private enrollmentService: EnrollmentService,
        private translateService: TranslateService,
        private errorBannerService: ErrorBannerService,
        private analyticsTrackingCommandsService: AnalyticsTrackingCommandsService,
        enrollmentGroupsSettingsService: EnrollmentGroupsSettingsService,
        private eligibleProductService: EligibleProductService
    ) {
        this.goBackState = 'validate';

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            enrollmentGroupsSettingsService.getEnrollmentGroupSettings(
                transition.params().sponsorId,
                transition.params().enrollmentGroupId,
                event.lang
            ).subscribe((settings) => {
                this.setEnrollmentGroupSettingsText(settings);
            });
        });
    }

    private setEnrollmentGroupSettingsText(settings) {
        if (!_.isNil(settings['EnrollmentForm2'])) {
            this.enrollmentGroupSettingsText = settings['EnrollmentForm2'];
            this.isScreenCopySet = true;
        }
    }

    private checkToken() {
        let token = this.transition.params().token;

        if (this._enrollmentGroup.enrollmentValidationRule) {
            this.ruleType = this._enrollmentGroup.enrollmentValidationRule;
        } else {
            this.ruleType = 'default';
        }

        if ((NonFileValidationRules.some(rule => rule === this.ruleType) || this.isEmailConfirmationRequired) && token) {
            this.enrollmentService.verifyMemberByCode(token, this._sponsorSettings.sponsorId).subscribe(
                (member) => {
                    this.currentMemberService.memberEnrollmentData = member;

                    this.trackPasswordCreationPageViewed();

                    if (this.isEmailConfirmationRequired) {
                        this.currentMemberService.memberEnrollmentData.validationToken = token;
                    }
                    if (member.language) {
                        this.translateService.use(member.language);
                    }
                },
                (error) => {
                    if (error.status === 417) {
                        this.errorBannerService.setBannerVisible(true, 'expired-enrollment-link');
                        this.enrollmentService.getMemberByCode(token).subscribe(
                            (member) => {
                                this.currentMemberService.memberEnrollmentData = member;
                                if (this.isEmailConfirmationRequired) {
                                    this.currentMemberService.memberEnrollmentData.validationToken = token;
                                }
                            });
                    } else {
                        this.errorBannerService.setBannerVisible(true, 'invalid-enrollment-link');
                    }
                    this.state.go('validate', this.transition.params());
                }
            );
        } else if (!this.currentMemberService.memberEnrollmentData.sponsorId) {
            this.state.go('validate', this.transition.params());
        } else {
            this.trackPasswordCreationPageViewed();
        }

    }

    private trackPasswordCreationPageViewed() {
        const passwordCreationPageModel: PasswordCreationPageModel = {
            validation_rule: _.lowerCase(this.ruleType),
            sponsor_id: this._sponsorSettings.sponsorId,
            sponsor_name: this._sponsorSettings.sponsorName.toLowerCase(),
            country: _.lowerCase(this.currentMemberService.memberEnrollmentData.country),
            external_eligibility_id: this.currentMemberService.memberEnrollmentData.externalId,
            products: this.eligibleProductService.acceptedProductCodes(this.currentMemberService.memberEnrollmentData.eligibleProducts)
        }

        this.analyticsTrackingCommandsService.trackSignUpPasswordCreationPageViewed(
            passwordCreationPageModel,
            this._sponsorSettings.excludeMixpanelTracking
        );
    }
}
