import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { SponsorSettings } from './sponsor-settings.class';
import { RegistrationDetails } from './registration-details.class';
import { CachedSubject } from './cached-subject';

@Injectable()
export class SponsorSettingsService {
    private sponsorSettings: CachedSubject<SponsorSettings>;
    private registrationDetails: CachedSubject<RegistrationDetails>;

    constructor(private http: HttpClient) {
        this.sponsorSettings = new CachedSubject();
        this.registrationDetails = new CachedSubject();
    }

    getSponsorSettings(): BehaviorSubject<SponsorSettings> {
        return this.sponsorSettings.getSubject();
    }

    getSponsorSettingsByLegacyId(legacySponsorId: number): Observable<SponsorSettings> {
        let url: string = `/enrollment-api/legacy/sponsors/${legacySponsorId}/settings`;
        return this.sponsorSettings.get(legacySponsorId, () => this.http.get<SponsorSettings>(url));
    }

    getSponsorSettingsById(sponsorId: number): Observable<SponsorSettings> {
        let url: string = `/enrollment-api/sponsors/${sponsorId}/settings`;
        return this.sponsorSettings.get(sponsorId, () => this.http.get<SponsorSettings>(url));
    }

    getSponsorSettingsByExternalId(sponsorExternalId: string): Observable<SponsorSettings> {
        let url: string = `/enrollment-api/sponsor/${sponsorExternalId}/settings`;
        return this.sponsorSettings.get(sponsorExternalId, () => this.http.get<SponsorSettings>(url));
    }

    getRegistrationDetails(): BehaviorSubject<RegistrationDetails> {
        return this.registrationDetails.getSubject();
    }

    getRegistrationDetailsByEnrollmentGroupExternalId(externalId: string): Observable<RegistrationDetails> {
        let url = `/enrollment-api/enrollment-groups/${externalId}/registration-details`;

        return this.registrationDetails.get(externalId, () => this.http.get<RegistrationDetails>(url));
    }

    getRegistrationDetailsBySponsorId(sponsorId: number): Observable<RegistrationDetails> {
        const url: string = `/enrollment-api/sponsors/${sponsorId}/registration-details`;

        return this.registrationDetails.get(sponsorId, () => this.http.get<RegistrationDetails>(url));
    }

    getRegistrationDetailsBySponsorGuid(sponsorGuid: number): Observable<RegistrationDetails> {
        const url: string = `/enrollment-api/sponsor/${sponsorGuid}/registration-details`;

        return this.registrationDetails.get(sponsorGuid, () => this.http.get<RegistrationDetails>(url));
    }
}
