import { Component } from '@angular/core';

@Component({
  selector: 'closed-registration',
  templateUrl: './closed-registration.component.html',
  styleUrls: ['./closed-registration.component.scss']
})
export class ClosedRegistrationComponent {
  constructor() { }
}
