import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/core';
import { ProgressBarStepData } from 'src/app/shared/flex-forms/progress-bar-step-data.class';
import { RegistrationDetails } from 'src/app/shared/registration-details.class';
import { SponsorSettingsService } from 'src/app/shared/sponsor-settings.service';
import { Title } from '@angular/platform-browser';
import { Constants } from 'src/app/shared/constants.class';

@Component({
    selector: 'flex-form-page',
    templateUrl: './flex-form-page.component.html',
    styleUrls: ['./flex-form-page.component.scss']
})
export class FlexFormPageComponent implements OnInit {

    icon: string = null;
    enrollmentGroupExternalId: string = null;
    emailToken: string = null;
    eligibilityToken: string = null;
    flexFormProgressSteps: ProgressBarStepData[];
    isLanguageDropdownVisible: boolean = true;
    ssoRedirectPath: string = null;
    redirectedFrom: string = null;
    leftPanelImageUrl: string = null;
    sidebarIntro: string = null;
    sponsorExternalId: string = null;
    sponsorName: string = null;

    constructor(
        public transition: Transition,
        private sponsorSettingsService: SponsorSettingsService,
        private title: Title
    ) {}

    ngOnInit() {
        this.enrollmentGroupExternalId = this.transition.params()['enrollmentGroupExternalId'];
        this.emailToken = this.transition.params()['emailVerification'];
        this.eligibilityToken = this.transition.params()['eligibilityToken'];
        this.ssoRedirectPath = this.transition.params()['postEnrollmentRedirectPath'];
        this.redirectedFrom = this.transition.params()['redirectFrom'];
        this.sponsorExternalId = this.transition.params()['sponsorExternalId'];

        this.sponsorSettingsService.getRegistrationDetails().subscribe((registrationDetails: RegistrationDetails) => {
            this.sponsorName = registrationDetails?.sponsorSettings?.sponsorName;
        });
    }

    setProgressSteps(steps: ProgressBarStepData[]) {
        this.flexFormProgressSteps = steps;
        const currentStep = steps.find((step) => step.isCurrentStep);
        this.title.setTitle(currentStep ? `Enrollment - ${currentStep.title}` : 'Enrollment');
    }

    setIsLanguageDropdownVisible(isLanguageDropdownVisible: boolean) {
        this.isLanguageDropdownVisible = isLanguageDropdownVisible;
    }

    setLeftPanelImageUrl(imageUrl?: string) {
        this.leftPanelImageUrl = imageUrl;
    }

    setSidebarIntro(sidebarIntro?: string) {
        this.sidebarIntro = sidebarIntro;
    }

    setLogoUrl(logoUrl?: string) {
        this.icon = logoUrl || Constants.DEFAULT_LOGO_URL;
    }
}
