<div class="form-group">
    <label for="selectCountry"
           translate="">
        enrollment.forms.country
    </label>
    <div>
        <select class="form-input timezone"
                id="selectCountry"
                (change)="onChange()"
                (blur)="onBlur()"
                required
                [(ngModel)]="currentCountry">
            <option [ngValue]="null" disabled translate="">
                enrollment.forms.country
            </option>
            <option *ngFor="let country of countries"
                    [ngValue]="country">
                {{ country.name }}
            </option>
        </select>
    </div>
</div>
