import { Injectable } from '@angular/core';

import { AnalyticsTrackingService } from './analyticsTracking.service';
import { AnalyticsTrackingAction } from './AnalyticsTrackingAction.enum';
import { EmailSentPageModel } from './domain/email-sent-page.model';
import { PasswordCreationPageModel } from './domain/password-creation-page.model';
import { SignUpCompleteModel } from './domain/sign-up-complete.model';
import { ValidationPageModel } from './domain/validation-page.model';

@Injectable()
export class AnalyticsTrackingCommandsService {
    constructor(private analyticsTrackingService: AnalyticsTrackingService) {
    }

    trackSignUpDemographicPageViewed(trackingData: ValidationPageModel, excludeMixpanelTracking: boolean) {
        this.analyticsTrackingService.sendData(
            AnalyticsTrackingAction.SignUpDemographicPageViewed,
            trackingData,
            excludeMixpanelTracking
        );
    }

    trackSignUpDemographicInfoComplete(trackingData: ValidationPageModel, excludeMixpanelTracking: boolean) {
        this.analyticsTrackingService.sendData(
            AnalyticsTrackingAction.SignUpDemographicInfoComplete,
            trackingData,
            excludeMixpanelTracking
        );
    }

    trackSignUpEmailSentPageViewed(trackingData: EmailSentPageModel, excludeMixpanelTracking: boolean) {
        this.analyticsTrackingService.sendData(
            AnalyticsTrackingAction.SignUpEmailSentPageViewed,
            trackingData,
            excludeMixpanelTracking
        );
    }

    trackSignUpPasswordCreationPageViewed(trackingData: PasswordCreationPageModel, excludeMixpanelTracking: boolean) {
        this.analyticsTrackingService.sendData(
            AnalyticsTrackingAction.SignUpPasswordCreationPageViewed,
            trackingData,
            excludeMixpanelTracking
        );
    }

    trackSignUpComplete(trackingData: SignUpCompleteModel, excludeMixpanelTracking: boolean) {
        this.analyticsTrackingService.sendData(
            AnalyticsTrackingAction.SignUpComplete,
            trackingData,
            excludeMixpanelTracking
        )
    }
}
