import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import * as moment from 'moment';
import { Constants } from '../../../../shared/constants.class';
import { BaseFormField } from '../../../../shared/flex-forms/base-form-field.class';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';
import { InputType } from '@engineering/genesis-frontend/domain/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reactive-birth-year',
    templateUrl: './reactive-birth-year.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveBirthYearComponent)
        }
    ]
})
export class ReactiveBirthYearComponent extends BaseFormField implements OnInit, ControlValueAccessor {
    constants = Constants;

    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;

    birthYear: number = Number.NaN;
    inputType: InputType = 'number';
    customValidatorsWithMessages: { validatorName: string, validator: ValidatorFn }[] = [];

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.field.initialValue) {
            this.birthYear = this.field.initialValue
        }

        this.customValidatorsWithMessages.push({
            validatorName: 'minBirthYear',
            validator: this.minBirthYear()
        }, {
            validatorName: 'maxBirthYear',
            validator: this.maxBirthYear()
        });
    }

    writeValue(newBirthYear: number): void {
        if (newBirthYear) {
            this.birthYear = newBirthYear;
        }
    }

    onChange($event: number): void {
        this.birthYear = $event;
        this.propagateChange(this.birthYear);
    }

    onTouch(): void {
        this.propagateTouch();
    }

    getBirthdateAsMoment() {
        return moment.utc([this.birthYear, 0, 1]);
    }

    private maxBirthYear(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value > new Date().getFullYear() - Constants.MINIMUM_AGE) {
                return {
                    maxBirthYear: 'Invalid',
                    message: this.translateService.instant('enrollment.forms.errors.youMustBeAtLeast')
                };
            }

            return null;
        };
    }

    private minBirthYear(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value < Constants.MINIMUM_BIRTH_YEAR) {
                return {
                    minBirthYear: 'Invalid',
                    message: this.translateService.instant('enrollment.forms.errors.beSureToUseYourActualDateOfBirth')
                };
            }

            return null
        };
    }
}
