import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'maintenance-registration', // formerly app-maintenance-registration
  templateUrl: './maintenance-registration.component.html',
  styleUrls: ['./maintenance-registration.component.scss']
})
export class MaintenanceRegistrationComponent {
  constructor() { }
}
