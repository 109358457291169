import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable()
export class WolverineLoginService {

    constructor(
        private http: HttpClient
    ) {
    }

    public logIn(username, password) {
        let WOLVERINE_URL: string = environment.wolverine.url + '/v2/account/LoginExpress';
        let credentials = {
            userName: username,
            pwd: password
        };

        return this.http.post(WOLVERINE_URL, credentials, {
            withCredentials: true
        });
    }
}
