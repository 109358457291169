<div class="form-group form-group-name">
    <label>
        {{customLabel ? customLabel : ('enrollment.forms.dateOfBirth' | translate)}}
    </label>
    <div class="date-of-birth-wrapper">
        <input class="birthYear form-input d-none d-md-block"
               id="birthYear"
               type="number"
               [(ngModel)]="birthYear"
               [ngClass]="{'validation-error': hasYearErrored() }"
               placeholder="{{'enrollment.forms.year' | translate }}"
               triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
               [popover]="popoverText"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               [attr.aria-label]="'enrollment.screenReader.enterYear' | translate"
               aria-describedby="birthdate-error birthdate-warning">
        <select class="birthYear form-input d-md-none mobile-picker"
                [(ngModel)]="birthYear"
                [ngClass]="{'validation-error': hasYearErrored() }"
                triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
                [popover]="popoverText"
                (change)="onChange()"
                (blur)="onBlur()"
                [attr.aria-label]="'enrollment.screenReader.selectYear' | translate"
                aria-describedby="birthdate-error birthdate-warning">
            <option [ngValue]="null" disabled translate="">
                enrollment.forms.year
            </option>
            <option *ngFor="let year of availableYears"
                    [ngValue]="year">{{ year }}
            </option>
        </select>
        <select class="birthMonth form-input"
                id="birthMonth"
                [(ngModel)]="birthMonth"
                [ngClass]="{'validation-error': hasMonthErrored() }"
                triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
                [popover]="popoverText"
                (change)="onChange()"
                (blur)="onBlur()"
                [attr.aria-label]="'enrollment.screenReader.selectMonth' | translate"
                aria-describedby="birthdate-error birthdate-warning">
            <option [ngValue]="null" disabled translate="">
                enrollment.forms.month
            </option>
            <option value="1" translate="">
                enrollment.dates.months.January
            </option>
            <option value="2" translate="">
                enrollment.dates.months.February
            </option>
            <option value="3" translate="">
                enrollment.dates.months.March
            </option>
            <option value="4" translate="">
                enrollment.dates.months.April
            </option>
            <option value="5" translate="">
                enrollment.dates.months.May
            </option>
            <option value="6" translate="">
                enrollment.dates.months.June
            </option>
            <option value="7" translate="">
                enrollment.dates.months.July
            </option>
            <option value="8" translate="">
                enrollment.dates.months.August
            </option>
            <option value="9" translate="">
                enrollment.dates.months.September
            </option>
            <option value="10" translate="">
                enrollment.dates.months.October
            </option>
            <option value="11" translate="">
                enrollment.dates.months.November
            </option>
            <option value="12" translate="">
                enrollment.dates.months.December
            </option>
        </select>
        <input class="birthDay form-input d-none d-md-block"
               id="birthDay"
               type="number"
               [(ngModel)]="birthDay"
               [ngClass]="{'validation-error': hasDayErrored() }"
               placeholder="{{'enrollment.forms.day' | translate }}"
               triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
               [popover]="popoverText"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               [attr.aria-label]=" 'enrollment.screenReader.enterDate' | translate "
               aria-describedby="birthdate-error birthdate-warning">
        <select class="birthDay form-input d-md-none mobile-picker"
                [(ngModel)]="birthDay"
                [ngClass]="{'validation-error': hasDayErrored() }"
                triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
                [popover]="popoverText"
                (change)="onChange()"
                (blur)="onBlur()"
                [attr.aria-label]="'enrollment.screenReader.selectDate' | translate"
                aria-describedby="birthdate-error birthdate-warning">
            <option [ngValue]="null" disabled translate="">
                enrollment.forms.day
            </option>
            <option *ngFor="let day of availableDays"
                    [ngValue]="day">
                {{ day }}
            </option>
        </select>
    </div>
    <small class="error-text"
           *ngIf="touched && getError()"
           id="birthdate-error">
        <small class="sr-only"
               translate="">
            enrollment.screenReader.error
        </small>
        <span *ngIf="getError().required"
            [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: customLabel ? customLabel : ('enrollment.forms.dateOfBirth' | translate) }">
        </span>
        <span translate=""
              *ngIf="getError().invalid">
            enrollment.forms.errors.beSureToUseANumberThatIsADay
        </span>
        <span translate=""
              *ngIf="getError().lowAge">
            enrollment.forms.errors.youMustBeAtLeast
        </span>
        <span translate=""
              *ngIf="getError().highAge">
            enrollment.forms.errors.beSureToUseYourActualDateOfBirth
        </span>
    </small>
    <vpe-age-consent-warning [birthdate]="getBirthDayAsMoment()"></vpe-age-consent-warning>
</div>
