<form-wrapper>
    <h1 class="page-title" translate="">enrollment.forms.productSelect</h1>
    <div class="product-description" translate="">enrollment.forms.productSelectDescription</div>
    <div class="product-container">
        <div *ngFor="let product of productList">
            <product-box [product]="product" (click)="goToAgreements(product)"></product-box>
        </div>
    </div>
    <button class="btn btn-orange"
            (click)="continue()"
            translate=""
            [disabled]="isDisabled()">
        enrollment.forms.continue
    </button>
    <div class="product-select-message" translate="">enrollment.forms.productSelectAcceptOne</div>
</form-wrapper>
