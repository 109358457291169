<div>
    <groove-input-field class="groove-wrapper"
                        [id]="field?.fieldName + '-input'"
                        [fieldName]="field?.fieldName"
                        [label]="field?.displayName"
                        (valueChanged)="onChange($event)"
                        (touched)="onTouch()"
                        [placeholder]="field?.placeholder"
                        [value]="birthYear"
                        [type]="inputType"
                        [maxLength]="constants.MAX_YEAR_LENGTH"
                        [isRequired]="isRequired"
                        [customValidatorsWithMessages]="customValidatorsWithMessages">
    </groove-input-field>

    <vpe-age-consent-warning
        [id]="field?.fieldName + '-age-consent-warning'"
        [birthdate]="getBirthdateAsMoment()">
    </vpe-age-consent-warning>
</div>
