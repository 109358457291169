<div class="form-group">
    <label for="enrollmentCode">
        {{ customLabel ? customLabel : ('enrollment.forms.enrollmentCode' | translate) }}
    </label>
    <div>
        <input class="form-input"
               id="enrollmentCode"
               [(ngModel)]="enrollmentCode"
               placeholder="{{ customLabel ? ('enrollment.forms.enterYourCustomFieldName' | translate: { customFieldName: customLabel }) : ('enrollment.forms.enterYourEnrollmentCode' | translate) }}"
               [ngClass]="{'validation-error': !enrollmentCode}"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               required>
    </div>
    <div>
        <small class="error-text"
               *ngIf="touched && !enrollmentCode"
               [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: customLabel ? customLabel : ('enrollment.forms.enrollmentCode' | translate) }">
        </small>
    </div>
</div>
