import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { StateService, Transition } from "@uirouter/angular";
import { TranslateService } from '@ngx-translate/core';

import { Agreement } from '../shared/agreement.class';
import { SponsorSettings } from '../shared/sponsor-settings.class';
import { EnrollmentGroup } from '../shared/enrollment-group.class';
import { AgreementsService } from '../shared/agreements.service';
import { CurrentMemberService } from '../shared/current-member.service';
import { MemberEligibilityCreationService } from '../shared/member-eligibility-creation.service';
import { MemberEligibilityUpdateService } from '../shared/member-eligibility-update.service';
import { CountryService } from '../shared/country.service';
import { ProductCode } from "../shared/product.enum";
import { Country } from '../shared/country.class';
import { AgreementRedirect } from "../shared/agreement-redirect.enum";

import * as _ from 'lodash';
import { ValidationTransitionService } from "../shared/validation-transition.service";
import { AgreementVM } from "../shared/agreement-vm.class";
import { EnrollmentGroupUtil } from '../shared/enrollment-group-util';

@Component({
    templateUrl: './agreements.component.html',
    styleUrls: ['./agreements.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AgreementsComponent implements OnInit {
    agreements: Array<Agreement>;
    loadingSave: boolean = false;
    country: Country;

    @Input() sponsorSettings: SponsorSettings;

    @Input() enrollmentGroup: EnrollmentGroup;

    product: ProductCode;

    redirect: AgreementRedirect;

    displayAgreementsToaster: boolean = false;

    constructor(
        private state: StateService,
        private transition: Transition,
        private currentMemberService: CurrentMemberService,
        private agreementsService: AgreementsService,
        private translateService: TranslateService,
        private memberEligibilityCreationService: MemberEligibilityCreationService,
        private memberEligibilityUpdateService: MemberEligibilityUpdateService,
        private countryService: CountryService,
        private validationTransitionService: ValidationTransitionService
    ) {
        this.countryService.getSelectedCountry().subscribe((country) => {
            this.country = country;
        });

        this.translateService.onLangChange.subscribe(() => {
            this.getAgreements();
        });

        this.product = this.transition.params().product;
        // Using transition.params() casts the redirect to a string, so we need to get the RawParams directly.
        this.redirect = this.transition.targetState().params().redirect;
    }

    ngOnInit() {
        if (this.country) {
            this.getAgreements();
        } else {
            this.getCurrentCountry();
        }
    }

    private getCurrentCountry() {
        this.countryService.getCountries(this.translateService.currentLang || this.translateService.getDefaultLang())
            .subscribe((countries) => {
                this.country = countries.find((country) => country.name === this.currentMemberService.memberEnrollmentData.country);
                this.getAgreements();
            });
    }

    private getAgreements() {
        if (this.sponsorSettings && this.product) {
            this.agreementsService.getAgreementsList(
                this.product,
                this.sponsorSettings.sponsorId,
                this.translateService.currentLang || this.translateService.getDefaultLang(),
                _.get(this.country, 'id', null)
            ).subscribe((agreements) => {
                this.agreements = agreements;
                _.forEach(this.agreements, (agreement) => {
                    agreement.accepted = false;
                });
            },
                (err) => {
                    console.error(`Oops: connection error occurred.`, err);
                    this.state.go('verify', this.transition.params());
                });
        }
    }

    continue() {
        this.currentMemberService.memberEnrollmentData.agreements = this.agreements.map((agreement) => new AgreementVM(agreement));
        this.loadingSave = true;
        this.displayAgreementsToaster = false;

        switch(this.enrollmentGroup.enrollmentValidationRule) {
            case 'NoEligibilityFile':
            case 'NoEligibilityFileWithOnlyBirthYear':
                this.memberEligibilityCreationService.createMember(this.currentMemberService.memberEnrollmentData).subscribe((data) => {
                        this.currentMemberService.memberEnrollmentData = data;

                        this.agreementsService.acceptAgreements(this.product,
                            this.currentMemberService.memberEnrollmentData.sponsorId,
                            this.currentMemberService.memberEnrollmentData.externalId,
                            this.agreements.map(a => a.id)).subscribe(() => {
                                _.find(this.currentMemberService.memberEnrollmentData.eligibleProducts, prod => prod.productCode === this.product).accepted = true;

                                const transition = this.validationTransitionService.getSignupTransition(this.transition.params(), this.enrollmentGroup, this.redirect);
                                this.state.go(transition.target, transition.params);
                            },
                            (err) => {
                                this.loadingSave = false;
                                console.error("Agreements could not be accepted!", err);
                        });
                    },
                    (err) => {
                        this.loadingSave = false;
                        console.error("Member could not be created!", err);
                    });
                break;
            default:
                this.agreementsService.acceptAgreements(this.product,
                    this.currentMemberService.memberEnrollmentData.sponsorId,
                    this.currentMemberService.memberEnrollmentData.externalId,
                    this.agreements.map(a => a.id)).subscribe(() => {
                        _.find(this.currentMemberService.memberEnrollmentData.eligibleProducts, prod => prod.productCode === this.product).accepted = true;

                        if (EnrollmentGroupUtil.isEmailConfirmationRequired(this.enrollmentGroup.requireEmailConfirmation,
                                                                            this.currentMemberService.memberEnrollmentData.emailAddress)) {
                            this.memberEligibilityUpdateService.updateMemberEligibility(this.currentMemberService.memberEnrollmentData).subscribe((data) => {
                                    this.currentMemberService.memberEnrollmentData = data;

                                    const transition = this.validationTransitionService.getSignupTransition(this.transition.params(), this.enrollmentGroup, this.redirect);
                                    this.state.go(transition.target, transition.params);
                                },
                                () => {
                                    this.loadingSave = false;
                                });
                        } else {
                            // Normally these would be ===, but some browsers are getting string values, so we're going to use some ugly comparisons here.
                            if (this.redirect == AgreementRedirect.SIGNUP) {
                                this.state.go('signup', this.transition.params());
                            } else if (this.redirect == AgreementRedirect.PRODUCTS) {
                                this.state.go('productSelect', this.transition.params());
                            } else {
                                console.log(`Unknown redirect: ${this.redirect}`);
                            }
                        }
                },
                    (err) => {
                        this.displayAgreementsToaster = true;
                        console.error(`Oops: connection error occurred.`, err);
                        this.loadingSave = false;
                    });
                break;
        }
    }

    isContinueDisabled() {
        if (this.loadingSave) {
            return true;
        }

        if (this.agreements) {
            let acceptedAgreements = _.filter(this.agreements, { accepted: true });
            if (acceptedAgreements.length === this.agreements.length) {
                return false;
            }
        }
        return true;
    }

    toggleContent(agreement: Agreement, event: Event) {
        event.preventDefault();
        agreement.showContent = !agreement.showContent;
    }
}
