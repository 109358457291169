import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormField } from '../../../../shared/flex-forms/base-form-field.class';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';

@Component({
    selector: 'reactive-app-download',
    templateUrl: './reactive-app-download.component.html',
    styleUrls: ['./reactive-app-download.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveAppDownloadComponent)
        }
    ]
})
export class ReactiveAppDownloadComponent extends BaseFormField implements ControlValueAccessor {
    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;

    constructor() {
        super();
    }

    writeValue(): void {}
}
