import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { NoEligibilityFileBaseValidationForm } from '../../shared/no-eligibility-file-base-validation-form.class';

@Component({
    selector: 'no-eligibility-file-birth-year-only-validation-form',
    templateUrl: './no-eligibility-file-birth-year-only-validation-form.component.html',
    styleUrls: ['../../validation.component.scss']
})
export class NoEligibilityFileBirthYearOnlyValidationFormComponent extends NoEligibilityFileBaseValidationForm implements OnInit {

    @Input() set memberData(memberData) {
        if (memberData) {
            this.member = memberData;

            this.validationForm = this.fb.group({
                firstName: [this.member.firstName, Validators.required],
                lastName: [this.member.lastName, Validators.required],
                email: [this.member.emailAddress],
                orgHierarchy: [''],
                genderIdentity: [this.member.genderIdentity],
                birthyear: [this.member.dateOfBirth],
                timezone: [this.member.timezone],
                phoneNumber: [''],
                homePhoneNumber: [''],
                country: [this.member.country],
                stateOfResidence: [this.member.stateOfResidence]
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onSubmit() {
        super.onSubmit();
    }
}
