<div class="form-group">
    <div class="employee-id-label">
        <label for="employeeId"
               *ngIf="!sensitiveEmployeeId || maxLength === null">
            {{ customLabel ? customLabel : ('enrollment.forms.employeeIdNumber' | translate) }}
        </label>
        <label for="employeeId"
               *ngIf="sensitiveEmployeeId && maxLength !== null">
            {{ customLabel ? ('enrollment.forms.customFieldName' | translate: { customFieldName: customLabel }) : ('enrollment.forms.last4DigitsOfEmployeeId' | translate) }}
        </label>
        <span class="eye-icon"
              *ngIf="sensitiveEmployeeId">
            <img (click)="toggleIcon()"
                 src="{{ hiddenEmployeeId ? 'assets/images/icon_hide.svg' : 'assets/images/icon_show.svg' }}"
                 alt="">
        </span>
    </div>
    <div>
        <input class="form-input ng-trim-ignore"
               id="employeeId"
               [(ngModel)]="employeeId"
               type="{{ hiddenEmployeeId ? 'password' : 'text' }}"
               placeholder="{{ customLabel ? ('enrollment.forms.enterYourCustomFieldName' | translate: { customFieldName: customLabel }) : ('enrollment.forms.enterYourIdNumber' | translate) }}"
               [ngClass]="{ 'validation-error': !employeeId, 'input-length-error': !enableButton }"
               (change)="onChange()"
               maxlength="{{ maxLength }}"
               (keyup)="onChange()"
               (blur)="onBlur()"
               required>
    </div>
    <div>
        <small class="error-text"
               *ngIf="touched && !employeeId"
               role="alert"
               [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: customLabel ? customLabel : ('enrollment.forms.employeeIdNumber' | translate) }">
        </small>
    </div>
</div>
