import {Component, Input} from '@angular/core';

@Component({
    template: ''
})
export abstract class BaseFormField {
    protected disabled = false;
    protected propagateChange = (newValue: any) => { };
    protected propagateTouch = () => { };

    @Input() isRequired = false;

    registerOnChange(onChange: any) {
        this.propagateChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.propagateTouch = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
