import { Component, Input, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EnrollmentGroup } from '../../../shared/enrollment-group.class';
import { PasswordResetChallenge } from '../../../shared/passwordResetChallenge.enum';

const minAge = 16;
const maxAge = 100;

@Component({
    selector: 'vpe-birthdate',
    templateUrl: './birthdate.component.html',
    styleUrls: ['./birthdate.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BirthdateComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BirthdateComponent),
            multi: true,
        }
    ]
})
export class BirthdateComponent implements ControlValueAccessor, Validator {
    birthDay: number = null;
    birthMonth: number = null;
    birthYear: number = null;
    customLabel: string;
    availableYears: Array<number>;
    availableDays: Array<number>;
    touched = false;
    passwordResetTypeDOB = false;

    @Input() popoverText: string;

    @Input() set passwordResetChallenge(passwordResetChallenge: string) {
        if (passwordResetChallenge) {
            this.passwordResetTypeDOB = passwordResetChallenge === PasswordResetChallenge.DOB;
        }
    }

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
      this.customLabel = group.getFieldName('DateOfBirth');
    }
    constructor() {
        let currentYear = moment().year();
        this.availableYears = _.rangeRight(currentYear - maxAge, currentYear - minAge + 1);
        this.setAvailableDays();
    }

    public writeValue(obj: any) {
        if (obj) {
            let birthDate: moment.Moment = moment(obj);
            if (birthDate.isValid()) {
                this.birthDay = birthDate.date();
                this.birthMonth = birthDate.month() + 1;
                this.birthYear = birthDate.year();
            }
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    public onChange() {
        let maxYearLength = 4;
        let yearString = this.birthYear ? this.birthYear.toString() : '';
        if (yearString.length > maxYearLength) {
            yearString = yearString.slice(0, maxYearLength);
            this.birthYear = Number.parseInt(yearString);
        }

        let maxDayLength = 2;
        let dayString = this.birthDay ? this.birthDay.toString() : '';
        if (dayString.length > maxDayLength) {
            dayString = dayString.slice(0, maxDayLength);
            this.birthDay = Number.parseInt(dayString);
        }

        this.setAvailableDays();

        this.propagateChange(this.getBirthDayAsMoment());
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onBlur() {
        this.touched = true;
        this.propagateTouch(true);
    }

    public getError(): {[key: string]: any} {
        if(!this.birthYear
        || !this.birthMonth
        || !this.birthDay) {
            return { 'required': 'birthdate' };
        }

        let currentDate = moment();
        let parsedDate = this.getBirthDayAsMoment();

        if (!parsedDate.isValid()) {
            return { 'invalid': 'birthdate' };
        }

        if (currentDate.clone().subtract(minAge, 'years').isBefore(parsedDate) && currentDate.isAfter(parsedDate)) {
            return { 'lowAge': 'birthdate' };
        }

        if (currentDate.clone().subtract(maxAge, 'years').isAfter(parsedDate) || currentDate.isSameOrBefore(parsedDate)) {
            return { 'highAge': 'birthdate' };
        }

        return null;
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        return this.getError();
    }

    private setAvailableDays() {
        let maxDays = 31;
        let selected = moment.utc([this.birthYear, Number(this.birthMonth) - 1]);
        if (selected.isValid()) {
            maxDays = selected.daysInMonth();
        }
        this.availableDays = _.range(1, maxDays + 1);
    }

    public getBirthDayAsMoment() {
        return moment.utc([this.birthYear, +this.birthMonth - 1, this.birthDay]);
    }

    public hasYearErrored() {
        let errors = this.getError();
        return (errors && ((errors['required'] && !this.birthYear) || errors['lowAge'] || errors['highAge']));
    }

    public hasMonthErrored() {
        let errors = this.getError();
        return (errors && (errors['required'] && !this.birthMonth));
    }

    public hasDayErrored() {
        let errors = this.getError();
        return (errors && ((errors['required'] && !this.birthDay) || errors['invalid']));
    }
}
