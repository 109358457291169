import { Component, Input } from "@angular/core";
import { EligibleProduct } from "../../shared/eligible-product";

@Component({
    selector: "product-box",
    templateUrl: './product-box.template.html',
    styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent {
    @Input() product: EligibleProduct;

    getBoxClasses(): string[] {
        const result = [`${this.product.productCode}-box`];

        if (!this.product.accepted) {
            result.push(`${this.product.productCode}-box-clickable`);
        }

        return result;
    }
}
