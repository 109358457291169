import * as createjs from 'createjs-module';

export class BubbleCelebrationParticle {
  public radius: number;
  public x: number;
  public y: number;
  public element: createjs.Shape;

  constructor(
      public diameter: number,
      public opacity: number,
      public scale: number,
      public color: string
  ) {
      let HALF = 0.5;
      this.radius = diameter * HALF;
  }
}
