import { ProductCode } from "./product.enum";

export class EligibleProduct {
    productCode: ProductCode;
    accepted: boolean;

    constructor(code: ProductCode, accepted: boolean) {
        this.productCode = code;
        this.accepted = accepted;
    }
}
