import { Component, Input, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SecurityQuestionsService } from '../../../shared/security-questions.service';
import { Question } from '../../../shared/question.class';
import { SecurityQuestion } from '../../../shared/security-question.class';
import * as _ from 'lodash';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

@Component({
    selector: 'vpe-security-question',
    templateUrl: './security-question.component.html',
    styleUrls: ['./security-question.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SecurityQuestionComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SecurityQuestionComponent),
            multi: true,
        }
    ]
})
export class SecurityQuestionComponent implements ControlValueAccessor, Validator {
    questions: Array<Question>;
    question: Question = null;
    securityQuestion: SecurityQuestion = new SecurityQuestion;
    answer: string;
    answerTouched = false;
    minLength = 2;
    maxLength = 100;
    currentLanguage: string;

    @Input() orderIndex: number;
    @Input() otherQuestions: Array<SecurityQuestion>;

    constructor(private securityQuestionsService: SecurityQuestionsService, private translateService: TranslateService) {
        translateService.onLangChange.subscribe(() => {
            this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
            this.securityQuestionsService.getSecurityQuestions(this.currentLanguage).subscribe((questions) => {
                    this.questions = questions;
                    if (this.question) {
                        this.question = _.find(this.questions, {id: this.question.id});
                    }
            });
        });
    }

    public writeValue(obj: any) {
        this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
        this.securityQuestionsService.getSecurityQuestions(this.currentLanguage).subscribe((questions) => {
            if (!this.questions) {
                this.questions = questions;
            }

            let question: Question;
            let answer: string;

            if (obj) {
                question = _.find(this.questions, obj);
                answer = _.find(this.answer, obj);
            }

            if (question !== this.question) {
                if (question || !this.question) {
                    this.question = question;
                }
                this.onChange();
            }

            if (answer !== this.answer) {
                if (answer || !this.answer) {
                    this.answer = answer;
                }
                this.onChange();
            }
        });
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.securityQuestion.securityQuestionId = this.question ? this.question.id : null;
        this.securityQuestion.answer = this.answer;
        this.securityQuestion.orderIndex = this.orderIndex;
        this.propagateChange(this.securityQuestion);
    }

    public onQuestionChange() {
        this.answer = null;
        this.onChange();
    }

    public onBlur() {
        this.propagateTouch(true);
    }
    public onAnswerBlur() {
        this.answerTouched = true;
        this.propagateTouch(true);
    }

    public isValidAnswer() {
        if (this.answer) {
            return this.answer.length >= this.minLength && this.answer.length <= this.maxLength
        }
        return false;
    }

    public questionInUse(question: Question) {
        return _(this.otherQuestions)
                .map('securityQuestionId')
                .includes(question.id);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {

        if (!this.question) {
            return { 'required' : 'question' };
        }

        if (!this.answer || !this.isValidAnswer())  {
            return { 'required' : 'answer' };
        }

        return null;
    }
}
