<div class="form-group">
    <label for="securityQuestion-{{orderIndex}}" translate="">
        enrollment.forms.SecurityQuestion.Question{{orderIndex}}
    </label>
    <div>
        <select class="form-input securityQuestions"
                id="securityQuestion-{{orderIndex}}"
                (change)="onQuestionChange()"
                (blur)="onBlur()"
                [(ngModel)]="question"
                required>
            <option [ngValue]="undefined" hidden disabled translate="">
                enrollment.forms.PleaseSelectOne
            </option>
            <option *ngFor="let q of questions"
                    [ngValue]="q"
                    [disabled]="questionInUse(q)">
                {{ q.question }}
            </option>
        </select>
    </div>
</div>
<div class="form-group">
    <label for="securityQuestionAnswer-{{orderIndex}}" translate="">
        enrollment.forms.SecurityQuestion.Answer{{orderIndex}}
    </label>
    <div>
        <input class="form-input answer"
               id="securityQuestionAnswer-{{orderIndex}}"
               minLength="2"
               maxlength="100"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onAnswerBlur()"
               [(ngModel)]="answer"
               [disabled]="!question"
               autocomplete="off"
               required
               [attr.aria-describedby]=" 'security-question-' + orderIndex + '-error' ">
    </div>
    <div *ngIf="answerTouched && !isValidAnswer()"
         [id]=" 'security-question-' + orderIndex + '-error' ">
        <small class="sr-only" translate="">
            enrollment.screenReader.error
        </small>
        <small class="error-text" translate="">
            enrollment.forms.SecurityQuestion.AnswersMustBe
        </small>
    </div>
</div>
