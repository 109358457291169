import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { StateService, Transition } from '@uirouter/angular';
import * as _ from 'lodash';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { EnrollmentApiSponsorsEnrollmentGroupsService } from '../../shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-api-sponsors-enrollment-groups.service';
import { SponsorSettings} from '../../shared/sponsor-settings.class';

import { EnrollmentGroup } from '../../shared/enrollment-group.class';
import { ValidationTransitionService } from '../../shared/validation-transition.service';
import { CurrentMemberService } from '../../shared/current-member.service';
import { MemberEnrollmentData } from '../../shared/member-enrollment-data.class';

@Component({
    selector: 'sponsor-enrollment-group-selection',
    templateUrl: './enrollment-group-selection.component.html',
    styleUrls: ['./enrollment-group-selection.component.scss'],
})
export class EnrollmentGroupSelectionComponent implements OnInit {
    public initialized: boolean = false;
    public hasGroups: boolean = false;
    public selectedEnrollmentGroup: EnrollmentGroup = null;
    groupSelectForm: UntypedFormGroup;

    public enrollmentGroups: Array<EnrollmentGroup>;

    @Input() sponsorSettings: SponsorSettings;

    @Input() set initialEnrollmentGroups(groups: Array<EnrollmentGroup>) {
        var visibleEnrollmentGroups = _.reject(groups, function(enrollmentGroup) {
            return enrollmentGroup.hidden;
        });

        this.enrollmentGroups = visibleEnrollmentGroups.sort(this.enrollmentGroupSort);
        this.hasGroups = !_.isEmpty(this.enrollmentGroups);
    }

    constructor(
        private fb: UntypedFormBuilder,
        private state: StateService,
        private transition: Transition,
        private enrollmentApiSponsorsEnrollmentGroupsService: EnrollmentApiSponsorsEnrollmentGroupsService,
        private translateService: TranslateService,
        private validationTransitionService: ValidationTransitionService,
        private currentMemberService: CurrentMemberService
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            if (this.sponsorSettings) {
                this.setCurrentEnrollmentGroups(event.lang);
            }
        });
    }

    setCurrentEnrollmentGroups(language) {
        const ONE_ENROLLMENT_GROUP: number = 1;

        this.enrollmentApiSponsorsEnrollmentGroupsService.getEnrollmentGroups(this.sponsorSettings.sponsorId, language)
            .subscribe((enrollmentGroups) => {
                this.enrollmentGroups = enrollmentGroups.sort(this.enrollmentGroupSort);
                this.hasGroups = !_.isEmpty(enrollmentGroups);
            }
        )
    }

    ngOnInit() {
        if (this.groupSelectForm) {
            return;
        }

        this.groupSelectForm = this.fb.group({
            enrollmentGroup: ['']
        });

        this.initialized = true;

        const language = this.currentMemberService.memberEnrollmentData.language
        this.currentMemberService.memberEnrollmentData = new MemberEnrollmentData();
        this.currentMemberService.memberEnrollmentData.language = language;
    }

    onChange() {
        this.selectedEnrollmentGroup = this.groupSelectForm.value.enrollmentGroup;
    }

    onContinue() {
        this.goToNextStep(this.selectedEnrollmentGroup.id);
    }

    private goToNextStep(groupId) {
        const next = this.validationTransitionService.getValidationTransition(this.transition.params(), this.currentMemberService.memberEnrollmentData.eligibleProducts);
        this.state.go(next.target, _.extend({ enrollmentGroupId: groupId }, next.params));
    }

    enrollmentGroupSort(valA, valB) {
        var numberRegex = /\d+/;
        return parseInt(numberRegex.exec(valA.groupName)[0], 10) - parseInt(numberRegex.exec(valB.groupName)[0], 10);
    }
}
