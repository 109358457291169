<div class="form-group form-group-name">
    <div *ngIf="emailOptional">
        <label for="emailAddress"
               translate="">
            enrollment.forms.emailOptional
        </label>
    </div>
    <div *ngIf="!emailOptional">
        <label for="emailAddress" translate="">
            enrollment.forms.yourEmail
        </label>
    </div>
    <div>
        <input class="form-input"
               id="emailAddress"
               [(ngModel)]="email"
               placeholder="{{'enrollment.forms.enterYourPreferredEmail' | translate }}"
               [popover]="popoverText"
               triggers="{{ popoverText ? 'focus' : 'none' }}"
               [ngClass]="{'validation-error': !isValidEmail() || !validEmailDomain }"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               [attr.required]="emailOptional ? null : 'required'"
               aria-describedby="email-error">
    </div>
    <div *ngIf="touched" id="email-error">
        <small class="sr-only" translate="">
            enrollment.screenReader.error
        </small>
        <small class="error-text"
               *ngIf="!email && !emailOptional"
               [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: 'enrollment.forms.yourEmail' | translate }">
        </small>
        <small class="error-text"
               *ngIf="email && !isValidEmail()"
               translate="">
            enrollment.forms.errors.pleaseUseAValidEmail
        </small>
        <small class="error-text"
               *ngIf="email && isValidEmail() && !validEmailDomain"
               translate="">
            enrollment.forms.errors.thisEmailIsNotValid
        </small>
    </div>
    <div *ngIf="emailOptional" id="email-optional-message">
        <small class="sr-only" translate="">
            enrollment.forms.addYourEmail
        </small>
        <div class="hint-content" translate="">
            enrollment.forms.addYourEmail
        </div>
    </div>
</div>
