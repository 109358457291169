import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as _ from 'lodash';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { trace } from '@uirouter/core';

if (!_.includes(['local', 'docker'], environment.envName)) {
  enableProdMode();
  window['NEWRELIC'].init([environment.newrelic.licenseKey, environment.newrelic.appId]);
}

trace.enable("TRANSITION");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
