import { Component, OnInit, Input } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { SponsorSettings } from '../shared/sponsor-settings.class';
import { WaitlistService } from './waitlist.service';

@Component({
    selector: 'app-waitlist',
    templateUrl: './waitlist.component.html',
    styleUrls: ['./waitlist.component.scss']
})
export class WaitlistComponent implements OnInit {
    waitlistFormComplete: Boolean;
    _sponsorSettings: SponsorSettings;
    capType: string;

    @Input() set sponsorSettings(sponsorSetting: SponsorSettings) {
        this._sponsorSettings = sponsorSetting;

        if (sponsorSetting && sponsorSetting.capType) {
            this.capType = sponsorSetting.capType;
        }
    };

    constructor(
        private transition: Transition,
        private waitlistService: WaitlistService
    ) { }

    ngOnInit() {
        this.waitlistService.waitlistFormComplete.subscribe(formComplete => {
            this.waitlistFormComplete = formComplete;
        });
    }

}
