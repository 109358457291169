import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject, Observable } from 'rxjs';

import { Country } from './country.class';

@Injectable()
export class CountryService {
    private selectedCountry: ReplaySubject<Country> = new ReplaySubject<Country>(1);

    constructor(private http: HttpClient) { }

    getCountries(currentLanguage: string): Observable<Country[]> {
        let COUNTRY_URL: string = 'genesis-api/countries/phone-codes';
    
        let countryList = this.http.get<Country[]>(COUNTRY_URL, {
          params: {
            langCode: currentLanguage
          }
        });
                    
        return countryList;
      }

    setSelectedCountry(country){
      this.selectedCountry.next(country);
    }

    getSelectedCountry(){
      return this.selectedCountry;
    }
}