import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Question } from './question.class';

@Injectable()
export class SecurityQuestionsService {
    private securityQuestionsList: Observable<Array<Question>>;

    constructor(
        private http: HttpClient
    ) {}

    getSecurityQuestions(currentLanguage: string) {
        let SECURITY_QUESTIONS_URL: string = '/enrollment-api/security-questions';

        this.securityQuestionsList = this.http.get<Question[]>(SECURITY_QUESTIONS_URL, {
            params: {
                language: currentLanguage
            }
        });

        return this.securityQuestionsList;
    }
}