import { Component, Input, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

import { SponsorSettings } from '../../../shared/sponsor-settings.class';
import { SponsorStateProvincesService } from '../../../shared/enrollment-api/services/sponsors/sponsor-state-provinces.service';
import { EnrollmentGroup } from "../../../shared/enrollment-group.class";

@Component({
    selector: 'vpe-state-province',
    templateUrl: './state-province.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StateProvinceComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StateProvinceComponent),
            multi: true,
        }
    ]
})
export class StateProvinceComponent implements ControlValueAccessor, Validator {
    stateProvince: string;
    customLabel: string;
    touched: boolean = false;
    sponsorId: number;
    stateProvinceList: Array<string> = [];

    @Input() set sponsorSettings(settings: SponsorSettings) {
        if (settings) {
            this.sponsorId = settings.sponsorId;

            if (this.sponsorStateProvincesService) {
                this.sponsorStateProvincesService.getSponsorStatesAndProvinces(this.sponsorId).subscribe((stateProvinceList) => {
                    this.stateProvinceList = stateProvinceList;
                });
            }
        }
    }

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
        this.customLabel = group.getFieldName('StateProvince');
    }

    constructor(
        private sponsorStateProvincesService: SponsorStateProvincesService
    ) {
        if (this.sponsorId) {
            this.sponsorStateProvincesService.getSponsorStatesAndProvinces(this.sponsorId).subscribe((stateProvinceList) => {
                this.stateProvinceList = stateProvinceList;
            });
        }
    }

    public writeValue(obj: any) {
        if (obj) {
            this.stateProvince = obj.toString();
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.propagateChange(this.stateProvince);
    }

    public onBlur() {
        this.touched = true;
        this.propagateTouch(true);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.stateProvince) {
            return { 'required' : 'stateProvince' };
        }

        return null;
    }
}
