import { Injectable } from '@angular/core';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Constants } from '../constants.class';
import { FieldWithConfirmation } from './field-with-confirmation';
import { PasswordWithConfirmation } from './password-with-confirmation.class';

@Injectable({
    providedIn: 'root'
})
export class FlexFormValidationService {
    constructor() { }

    confirmationValidator(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.CONFIRMATION, this.matchesConfirmation)
    }

    dateFormat(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.DATE_FORMAT, this.isDateFormatValid);
    }

    usernameValidator(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.USERNAME, this.isUsernameValid);
    }

    passwordLowercaseLetterValidator(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.LOWERCASE, this.passwordHasLowercaseLetter);
    }

    maxAge(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.MAX_AGE, this.isMaxAgeValid);
    }

    minAge(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.MIN_AGE, this.isMinAgeValid);
    }

    passwordLength(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.PASSWORD_LENGTH, this.passwordIsCorrectLength);
    }

    passwordNumberValidator(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.NUMBER, this.passwordHasNumber);
    }

    passwordSymbolValidator(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.SYMBOL, this.passwordHasSymbol);
    }

    passwordUppercaseLetterValidator(): ValidatorFn {
        return this.booleanValidator(Constants.VALIDATORS.UPPERCASE, this.passwordHasUppercaseLetter);
    }

    productSelectValidator(fieldNames: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let fieldValues = fieldNames?.map((fieldName) => {
                return control?.get(fieldName)?.value;
            });

            // let areOneOrMoreAccepted = fieldValues?.some(value => value == "true");
            let areOneOrMoreAccepted = fieldValues?.some(value => value == true);
            if (areOneOrMoreAccepted) {
                return null;
            } else {
                return {'noProductAccepted': true};
            }
        }
    }

    securityQuestionValidator(): ValidatorFn {
        const MIN_SECURITY_QUESTION_ANSWER_LENGTH = [
            Constants.VALIDATORS.MIN_SECURITY_QUESTION_1_ANSWER_LENGTH,
            Constants.VALIDATORS.MIN_SECURITY_QUESTION_2_ANSWER_LENGTH,
            Constants.VALIDATORS.MIN_SECURITY_QUESTION_3_ANSWER_LENGTH,
        ];

        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const errors = {};

            control.value.forEach((securityQuestion, index) => {
                if (!securityQuestion?.answer || securityQuestion?.answer?.length < 2) {
                    errors[MIN_SECURITY_QUESTION_ANSWER_LENGTH[index]] = securityQuestion.answer;
                }
            });

            return Object.keys(errors).length === 0 ? null : errors;
        };
    }

    private booleanValidator(validatorName: string, validationFn: (...args: any[]) => Boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const error = {};
            error[validatorName] = true;
            return validationFn(control.value) ? null : error;
        };
    }

    passwordHasLowercaseLetter(value: any): Boolean {
        return Constants.REGEX.LOWERCASE.test((value as PasswordWithConfirmation)?.password);
    }

    passwordHasNumber(value: any): Boolean {
        return Constants.REGEX.NUMBER.test((value as PasswordWithConfirmation)?.password);
    }

    passwordHasSymbol(value: any): Boolean {
        return Constants.REGEX.SYMBOL.test((value as PasswordWithConfirmation)?.password);
    }

    passwordHasUppercaseLetter(value: any): Boolean {
        return Constants.REGEX.UPPERCASE.test((value as PasswordWithConfirmation)?.password);
    }

    passwordIsCorrectLength(value: any): Boolean {
        let password = (value as PasswordWithConfirmation)?.password;

        if (!password) {
            return false;
        }

        return password.length >= Constants.MINIMUM_PASSWORD_LENGTH && password.length <= Constants.MAXIMUM_PASSWORD_LENGTH;
    }

    isDateFormatValid(value: any): Boolean {
        return Constants.REGEX.DATE.test(value as string);
    }

    isUsernameValid(value: any): Boolean {
        return Constants.REGEX.USERNAME.test(value as string);
    }

    isMaxAgeValid(value: any): Boolean {
        let dob = new Date(value as string);
        return dob.getUTCFullYear() >= Constants.MINIMUM_BIRTH_YEAR;
    }

    isMinAgeValid(value: any): Boolean {
        let dob = new Date(value as string);
        let utcDob = new Date(
            dob.getUTCFullYear(),
            dob.getUTCMonth(),
            dob.getUTCDate()
        );

        let now = new Date();
        let utcMaxDob = new Date(
            now.getUTCFullYear() - Constants.MINIMUM_AGE,
            now.getUTCMonth(),
            now.getUTCDate()
        );

        return utcDob <= utcMaxDob;
    }

    matchesConfirmation(value: any): Boolean {
        let fieldWithConfirmation = value as FieldWithConfirmation;
        return fieldWithConfirmation?.matchesConfirmation === true;
    }
}
