import * as _ from 'lodash';
import { EligibleProduct } from './eligible-product';
import { MemberEnrollmentData } from './member-enrollment-data.class';

export class ProductHelper {
    static readonly eligibleProducts = ['VP', 'PA']

    static productExists(eligibleProducts: EligibleProduct[], checkedProductCode: string): boolean {
        return (!!_.find(eligibleProducts, product => product.productCode === checkedProductCode));
    }

    static productAccepted(eligibleProducts: EligibleProduct[], checkedProductCode: string): boolean {
        return (!!_.find(eligibleProducts, product => product.productCode === checkedProductCode && product.accepted));
    }

    static getRelevantProducts(member: MemberEnrollmentData): Array<EligibleProduct> {
        return _.filter(member.eligibleProducts, (eligibleProduct) => _.includes(ProductHelper.eligibleProducts, eligibleProduct.productCode));
    }

    static hasPendingProducts(member: MemberEnrollmentData): boolean {
        return _.some(this.getRelevantProducts(member), eligibleProduct => !eligibleProduct.accepted);
    }
}
