<form class="container-fluid"
      (ngSubmit)="onSubmit()"
      [formGroup]="validationForm"
      novalidate>
    <div class="form-group form-group-name">
        <label translate="">enrollment.forms.name</label>
        <div class="name-wrapper">
            <div class="firstName-col">
                <input class="form-input firstName"
                       [attr.aria-label]=" 'enrollment.forms.firstName' | translate "
                       id="firstName"
                       name="firstName"
                       [(ngModel)]="member.firstName"
                       formControlName="firstName"
                       placeholder="{{'enrollment.forms.firstName' | translate }}"
                       [ngClass]="{'validation-error': !validationForm.controls.firstName.valid}"
                       required
                       aria-describedby="ldg-name-blank-error">
            </div>
            <div class="lastName-col">
                <input class="form-input lastName"
                       [attr.aria-label]=" 'enrollment.forms.lastName' | translate "
                       id="lastName"
                       name="lastName"
                       [(ngModel)]="member.lastName"
                       formControlName="lastName"
                       placeholder="{{ lastNameText ? lastNameText : ('enrollment.forms.lastName' | translate) }}"
                       [ngClass]="{'validation-error': !validationForm.controls.lastName.valid}"
                       required
                       aria-describedby="ldg-name-blank-error">
            </div>
        </div>
        <div *ngIf="(validationForm.controls.lastName.touched && !validationForm.controls.lastName.valid) || (validationForm.controls.firstName.touched && !validationForm.controls.firstName.valid)"
             id="ldg-name-blank-error">
            <small class="sr-only" translate="">enrollment.screenReader.error</small>
            <small class="error-text" 
                [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: 'enrollment.forms.name' | translate }">
            </small>
        </div>
    </div>
    <vpe-gender-identity formControlName="genderIdentity"
                         [(ngModel)]="member.genderIdentity"
                         [sponsorSettings]="sponsorSettings"
                         [enrollmentGroup]="_enrollmentGroup">
    </vpe-gender-identity>
    <vpe-birthdate id="birthdate"
                   name="birthdate"
                   formControlName="birthdate"
                   [(ngModel)]="member.dateOfBirth"
                   [enrollmentGroup]="_enrollmentGroup">
    </vpe-birthdate>
    <vpe-country id="country"
                 formControlName="country"
                 [(ngModel)]="member.country">
    </vpe-country>
    <vpe-state-of-residence id="stateOfResidence"
                            formControlName="stateOfResidence"
                            [(ngModel)]="member.stateOfResidence">
    </vpe-state-of-residence>
    <div *ngIf="saveError !== null"
         id="ldg-save-error"
         aria-live="polite">
        <small class="sr-only" translate="">enrollment.screenReader.error</small>
        <div [ngSwitch]="saveError">
            <small class="error-text"
                   *ngSwitchCase="enrollmentError.AlreadyEnrolled"
                   translate="">
                enrollment.forms.errors.helloAgain
            </small>
            <small class="error-text"
                   *ngSwitchDefault
                   translate="">
                enrollment.forms.errors.yourDetailsDontMatch
            </small>
        </div>
    </div>
    <button class="btn btn-orange"
            type="submit"
            translate=""
            [disabled]="!validationForm.valid || loadingValidation"
            aria-describedby="ldg-save-error">
        enrollment.forms.continue
    </button>
</form>
