<div class="form-group">
    <div class="password-label">
        <label for="password" translate="">enrollment.forms.createPassword</label>
        <button class="eye-icon" id="password-toggle-button" type="button" (click)="toggleIcon()">
            <img id="password-toggle-icon" [src]="showPassword ? icons.show : icons.hide" alt="" />
        </button>
    </div>
    <div class="password-box">
        <div class="password-help-box">
            <div class="password-rule" id="password-help-upperCase">
                <img class="password-icon" [src]="icons.dot" *ngIf="!password" alt="" />
                <img class="password-icon" [src]="icons.pass" *ngIf="isPasswordRuleSatisfied('upperCase')" alt="" />
                <img class="password-icon" [src]="icons.fail" *ngIf="!isPasswordRuleSatisfied('upperCase') && password" alt="" />
                <small translate="">enrollment.forms.passwordHelp.oneUpperCase</small>
            </div>
            <div class="password-rule" id="password-help-lowerCase">
                <img class="password-icon" [src]="icons.dot" *ngIf="!password" alt="" />
                <img class="password-icon" [src]="icons.pass" *ngIf="isPasswordRuleSatisfied('lowerCase')" alt="" />
                <img class="password-icon" [src]="icons.fail" *ngIf="!isPasswordRuleSatisfied('lowerCase') && password" alt="" />
                <small translate="">enrollment.forms.passwordHelp.oneLowerCase</small>
            </div>
            <div class="password-rule" id="password-help-number">
                <img class="password-icon" [src]="icons.dot" *ngIf="!password" alt="" />
                <img class="password-icon" [src]="icons.pass" *ngIf="isPasswordRuleSatisfied('number')" alt="" />
                <img class="password-icon" [src]="icons.fail" *ngIf="!isPasswordRuleSatisfied('number') && password" alt="" />
                <small translate="">enrollment.forms.passwordHelp.oneNumber</small>
            </div>
            <div class="password-rule" id="password-help-special">
                <img class="password-icon" [src]="icons.dot" *ngIf="!password" alt="" />
                <img class="password-icon" [src]="icons.pass" *ngIf="isPasswordRuleSatisfied('special')" alt="" />
                <img class="password-icon" [src]="icons.fail" *ngIf="!isPasswordRuleSatisfied('special') && password" alt="" />
                <small translate="">enrollment.forms.passwordHelp.oneSpecialCharacter</small>
            </div>
            <div class="password-rule" id="password-help-length">
                <img class="password-icon" [src]="icons.dot" *ngIf="!password" alt="" />
                <img class="password-icon" [src]="icons.pass" *ngIf="isPasswordRuleSatisfied('length')" alt="" />
                <img class="password-icon" [src]="icons.fail" *ngIf="!isPasswordRuleSatisfied('length') && password" alt="" />
                <small translate="">enrollment.forms.passwordHelp.lengthSpecifications</small>
            </div>
        </div>
        <input class="form-input ng-trim-ignore"
               id="password"
               name="password"
               [(ngModel)]="password"
               [type]="showPassword ? 'text' : 'password'"
               placeholder="{{ 'enrollment.forms.createANewPassword' | translate }}"
               triggers="focus"
               [ngClass]="{ 'validation-error': !isValidPassword() }"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               required="required"
               aria-describedby="password-popover password-conditions-error" />
        <div class="sr-only" translate="" id="password-popover">enrollment.forms.passwordsMustHave</div>
    </div>
    <div *ngIf="touched && !isValidPassword()" id="password-conditions-error">
        <small class="sr-only" translate="">enrollment.screenReader.error</small>
        <small class="error-text" translate="">enrollment.forms.errors.thisPasswordDoesntMeetAllConditions</small>
    </div>
</div>
<div class="form-group">
    <div class="password-label">
        <label for="passwordConfirm" translate="">enrollment.forms.confirmPassword</label>
        <button class="eye-icon" id="password-confirmation-toggle-button" type="button" (click)="toggleIcon()">
            <img id="password-confirmation-toggle-icon" [src]="showPassword ? icons.show : icons.hide" alt="" />
        </button>
    </div>
    <div class="password-box">
        <input class="form-input ng-trim-ignore"
               id="passwordConfirm"
               name="passwordConfirm"
               [type]="showPassword ? 'text' : 'password'"
               [(ngModel)]="passwordConfirm"
               [placeholder]="'enrollment.forms.retypeYourPassword' | translate"
               [ngClass]="{ 'validation-error': !areEqualPasswords() && password }"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onConfirmBlur()"
               required="required"
               aria-describedby="password-match-error" />
        <img class="pull-right password-icon" [src]="icons.pass" *ngIf="isValidPassword() && areEqualPasswords()" />
    </div>
    <div *ngIf="touchedConfirm && !areEqualPasswords()" id="password-match-error">
        <small class="sr-only" translate="">enrollment.screenReader.error</small>
        <small class="error-text" translate="">enrollment.forms.errors.passwordsNotMatch</small>
    </div>
</div>
