<form class="container-fluid"
      [formGroup]="signUpForm"
      novalidate=""
      (ngSubmit)="save()"
      *ngIf="currentMemberService.memberEnrollmentData">
    <vpe-username *ngIf="hasUsernameOnlyLogin"
                  formControlName="username"
                  [(ngModel)]="currentMemberService.memberEnrollmentData.username">
    </vpe-username>
    <div class="form-group"
         *ngIf="isEmailConfirmationRequired">
        <div>{{ currentMemberService.memberEnrollmentData.emailAddress }}</div>
    </div>
    <vpe-email *ngIf="!isEmailConfirmationRequired"
               formControlName="email"
               [(ngModel)]="currentMemberService.memberEnrollmentData.emailAddress"
               [sponsorSettings]="sponsorSettings"
               [emailOptional]="isEmailOptional">
    </vpe-email>
    <vpe-password id="password"
                  name="password"
                  formControlName="password"
                  [(ngModel)]="currentMemberService.memberEnrollmentData.password">
    </vpe-password>
    <vpe-gender-identity formControlName="genderIdentity"
                         [(ngModel)]="currentMemberService.memberEnrollmentData.genderIdentity"
                         [sponsorSettings]="sponsorSettings">
    </vpe-gender-identity>
    <vpe-phonenumber *ngIf="sponsorSettings.allowPhoneNumberCollection"
                     formControlName="phoneNumber"
                     name="phoneNumber"
                     [(intlPhoneNumber)]="currentMemberService.memberEnrollmentData.phoneNumber"
                     [phoneType]="'phoneNumber'">
    </vpe-phonenumber>
    <vpe-phonenumber *ngIf="sponsorSettings.allowPhoneNumberCollection"
                     formControlName="homePhoneNumber"
                     name="homePhoneNumber"
                     [(intlPhoneNumber)]="currentMemberService.memberEnrollmentData.homePhoneNumber"
                     [phoneType]="'homePhoneNumber'">
    </vpe-phonenumber>
    <vpe-timezone formControlName="timezone"
                  [(ngModel)]="currentMemberService.memberEnrollmentData.timezone">
    </vpe-timezone>
    <vpe-custom-enrollment-data *ngIf="customEnrollmentDataField"
                                formControlName="customEnrollmentDataField"
                                [customEnrollmentDataField]="customEnrollmentDataField">
    </vpe-custom-enrollment-data>
    <div *ngIf="hasSecurityQuestions">
        <p class="note" translate="">enrollment.forms.SecurityQuestion.KeepAccountSafe</p>
        <vpe-security-question formControlName="securityQuestion1"
                               name="securityQuestion1"
                               [(ngModel)]="currentMemberService.memberEnrollmentData.securityQuestion1"
                               orderIndex="1"
                               [otherQuestions]="[ currentMemberService.memberEnrollmentData.securityQuestion2, currentMemberService.memberEnrollmentData.securityQuestion3 ]">
        </vpe-security-question>
        <vpe-security-question formControlName="securityQuestion2"
                               name="securityQuestion"
                               [(ngModel)]="currentMemberService.memberEnrollmentData.securityQuestion2"
                               orderIndex="2"
                               [otherQuestions]="[ currentMemberService.memberEnrollmentData.securityQuestion1, currentMemberService.memberEnrollmentData.securityQuestion3 ]">
        </vpe-security-question>
        <vpe-security-question formControlName="securityQuestion3"
                               name="securityQuestion"
                               [(ngModel)]="currentMemberService.memberEnrollmentData.securityQuestion3"
                               orderIndex="3"
                               [otherQuestions]="[ currentMemberService.memberEnrollmentData.securityQuestion1, currentMemberService.memberEnrollmentData.securityQuestion2 ]">
        </vpe-security-question>
    </div>
    <div [ngSwitch]="saveError">
        <small class="error-text" *ngSwitchCase="null"></small>
        <small class="error-text"
               *ngSwitchCase="enrollmentError.UsernameExists"
               [innerHtml]="'enrollment.forms.errors.thatUsernameIsAlready' | translate:{ loginUrl: loginUrl }">
        </small>
        <small class="error-text"
               *ngSwitchCase="enrollmentError.EmailExists"
               [innerHtml]="'enrollment.forms.errors.emailInUse' | translate:{ loginUrl: loginUrl }">
        </small>
        <small class="error-text" *ngSwitchDefault
               [innerHtml]="'enrollment.forms.errors.oopsSomethingsWrong' | translate:{ supportEmailLink: supportEmailLink }">
        </small>
    </div>
    <button class="create-account-button btn btn-orange"
            type="submit"
            translate=""
            [disabled]="!signUpForm.valid || loadingEnrollment">
        enrollment.forms.createMyAccount
    </button>
</form>
