<div class="phone-number-groove-override">
    <app-groove-phone-number-field class="groove-wrapper"
                                   [id]="field?.fieldName + '-input'"
                                   [fieldName]="field?.fieldName"
                                   [label]="field?.displayName"
                                   [isDisabled]="field?.editable === false"
                                   (valueChanged)="onChange($event)"
                                   (touched)="onTouch()"
                                   [countries]="countryOptions"
                                   [value]="phoneNumber"
                                   [selectedCountryCode]="selectedCountryCode"
                                   (errors)="onError($event)"
                                   [isRequired]="isRequired">
    </app-groove-phone-number-field>

    <small translate="" role="alert" class="error-text" [id]="field?.fieldName + '-valid-phone-number-error'"
        *ngIf="errors && errors[VALIDATORS.VALID_PHONE_NUMBER] && touched">
        enrollment.flex.errors.invalidPhoneNumber
    </small>

    <div *ngIf="fieldAttributes?.type === 'PhoneNumberAttributes' && getPhoneNumberAttributesCheckBoxText() && isRequired" class="phone-agree-checkbox-container">
        <groove-checkbox-field [isMedium]=true (change)="onCheckBoxChange($event)" [value]="checkboxValue"></groove-checkbox-field>
        <span>{{ getPhoneNumberAttributesCheckBoxText() }}</span>
    </div>
</div>
