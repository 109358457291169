import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Office } from 'src/app/shared/office.class';
import { Constants } from 'src/app/shared/constants.class';
import { BaseOrgHierarchyUnitComponent } from '../base-org-hierarchy-unit/base-org-hierarchy-unit.component';

@Component({
    selector: 'reactive-office',
    templateUrl: './reactive-office.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveOfficeComponent),
            multi: true,
        }
    ]
})
export class ReactiveOfficeComponent extends BaseOrgHierarchyUnitComponent<Office> {
    type = Constants.COMPONENT_TYPE.OFFICE;
}