<div>
    <vpg-segmented-progress-bar class="horizontal-progress-bar"
                                [steps]="progressBarSteps"
                                [currentProgress]="currentProgress"
                                [firstStepSetAsComplete]="true"
                                [isNumbered]="false"
                                [isHorizontal]="false"
                                [iconType]="iconType"
                                [ariaLabel]="'enrollment.screenReader.youAreOnProgressBar' | translate">
    </vpg-segmented-progress-bar>

    <div class="mobile-progress-bar">
        <div class="mobile-progress-bar-text"
            [innerHtml]="'enrollment.flex.progress.step' | translate: { currentStep: progressBarCurrentStep + 1, totalSteps: progressBarSteps.length }">
        </div>
    </div>
</div>
