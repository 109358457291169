<form-wrapper>
    <h1 class="page-title" translate="">enrollment.forms.almostDone</h1>
    <div class="verify-content">
        <p class="check-your-inbox" translate="">enrollment.forms.checkYourInbox</p>
        <div class="email-image">
            <img src="assets/images/email.svg">
        </div>
        <p class="allow-a-few-minutes" [innerHtml]="'enrollment.forms.allowAFewMinutes' | translate:{ returnLink: returnLink }"></p>
    </div>
</form-wrapper>
