import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { PhoneNumberCountryItem } from '@engineering/genesis-frontend';
import { GenericGrooveWrapperComponent } from '../generic-groove-wrapper/generic-groove-wrapper.component';
import { Constants } from 'src/app/shared/constants.class';

@Component({
    selector: 'app-groove-phone-number-field',
    templateUrl: './groove-phone-number-field.component.html',
    styleUrls: ['./groove-phone-number-field.component.scss']
})
export class GroovePhoneNumberFieldComponent extends GenericGrooveWrapperComponent implements OnInit {

    override defaultValue = '';

    @Input()
    countries: Array<PhoneNumberCountryItem>;

    @Input()
    selectedCountryCode: string;

    @Output()
    errors = new EventEmitter<ValidationErrors>();

    override ngOnInit() {
        super.ngOnInit();

        this.form.statusChanges.subscribe((status) => {
            if (status == Constants.FORM_STATUS.VALID) {
                this.form.controls[this.fieldName].updateValueAndValidity({ onlySelf: true, emitEvent: false });
            }
            this.errors.emit(this.form.controls[this.fieldName].errors);
        });
    }
}
