import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AsyncSubject, Observable } from 'rxjs';

import { EnrollmentGroup } from '../../../../enrollment-group.class';

@Injectable()
export class EnrollmentApiSponsorsEnrollmentGroupsService {
    private currentGroupsSubject: AsyncSubject<EnrollmentGroup[]>;
    private currentSponsorId: number;
    private currentLanguage: string;

    constructor(
        private http: HttpClient
    ) {
    }

    getEnrollmentGroups(sponsorId, language): Observable<Array<EnrollmentGroup>> {
        let SPONSOR_ENROLLMENT_GROUPS_URL: string = `/enrollment-api/sponsors/${ sponsorId }/enrollment-groups?language=${ language }`;

        if (this.currentSponsorId === sponsorId && this.currentLanguage === language) {
            return this.currentGroupsSubject;
        }

        this.currentGroupsSubject = new AsyncSubject();
        this.currentSponsorId = sponsorId;
        this.currentLanguage = language;

        this.http.get<EnrollmentGroup[]>(SPONSOR_ENROLLMENT_GROUPS_URL).subscribe((enrollmentGroups) => {
             this.currentGroupsSubject.next(enrollmentGroups);
             this.currentGroupsSubject.complete();
         });

         return this.currentGroupsSubject;
    }
}
