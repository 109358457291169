<form-wrapper>
    <div class="identify-wrapper">
        <h1 class="page-title" translate="">enrollment.forms.signUp</h1>
        <form class="container-fluid"
              (ngSubmit)="onSubmit()"
              [formGroup]="identifyForm"
              novalidate>
            <div class="identify-message">
                <p translate="">enrollment.forms.errors.oopsWeHadTroubleMatching</p>
                <p translate="">enrollment.forms.errors.pleaseEnterSomeInfo</p>
            </div>
            <div class="identify-form-item">
                <vpe-employee-id id="employeeId"
                                 name="employeeId"
                                 formControlName="employeeId"
                                 [(ngModel)]="member.employeeId"
                                 (emitButtonEnable)="detectButtonEnableChange($event)"
                                 [enrollmentGroup]="enrollmentGroup"
                                 [sponsorSettings]="sponsorSettings"
                                 [tieBreakerOverride]="enrollmentGroup.enrollmentValidationRule">
                </vpe-employee-id>
                <div [ngSwitch]="saveError">
                    <small class="error-text" *ngSwitchCase="null"></small>
                    <small class="error-text" *ngSwitchCase="'AlreadyEnrolled'" translate="">enrollment.forms.errors.helloAgain</small>
                    <small class="error-text" *ngSwitchDefault [innerHtml]="'enrollment.forms.errors.werentAbleToFind' | translate:{ supportEmailLink: sponsorSettings.supportEmail }"></small>
                </div>
            </div>
            <button class="btn btn-orange" type="submit" translate="" [disabled]="!identifyForm.valid || !enableButton">
                enrollment.forms.continue
            </button>
            <a class="go-back d-none d-md-block" uiSref="validate" [uiParams]="transition.params()">
                | &#60;
                <span translate="">enrollment.forms.goBack</span>
            </a>
        </form>
    </div>
</form-wrapper>
