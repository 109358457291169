import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';

import { EnrollmentErrorService } from '../shared/enrollment-error.service';

import { MemberEnrollmentData } from '../shared/member-enrollment-data.class';

@Injectable()
export class EnrollmentService {
    constructor(private http: HttpClient, private enrollmentErrorService: EnrollmentErrorService) { }

    enrollMember(member: MemberEnrollmentData) {
        let MEMBER_ENROLLMENT_URL: string = `/enrollment-api/sponsors/${ member.sponsorId }/enroll`;

        return this.http.post(MEMBER_ENROLLMENT_URL, member);
    }

    enrollMemberResolved(member: MemberEnrollmentData) {
        return this.enrollMember(member).pipe(
            catchError((error) => {
                throw this.enrollmentErrorService.handleError(error);
            }));
                
    }

    verifyMemberByCode(verificationCode, sponsorId) {
        let MEMBER_VERIFICATION_URL: string = `/enrollment-api/sponsors/${ sponsorId }/enroll/verify/${ verificationCode }`;

        return this.http.get<MemberEnrollmentData>(MEMBER_VERIFICATION_URL);
    }

    getMemberByCode(verificationCode) {
        let MEMBER_ELIGIBILITY_RECORD_URL: string = `/enrollment-api/member-verification/${ verificationCode }/eligibility-record`;

        return this.http.get<MemberEnrollmentData>(MEMBER_ELIGIBILITY_RECORD_URL)
    }
}
