<form [formGroup]="securityQuestionForm">
    <div class="no-side-padding groove-wrapper">
        <vpg-dropdown-next class="groove-dropdown-vpg-dropdown"
                           [formControlName]="questionFieldName"
                           [name]="questionFieldName"
                           [placeholder]="'enrollment.flex.securityQuestion.questionPlaceholder' | translate"
                           [ariaLabel]="questionLabel"
                           [label]="questionLabel"
                           [options]="data"
                           [optionLabel]="'text'"
                           (focusout)="onBlur()">
            <ng-template #errors>
                <vpg-control-error role="alert" *ngIf="securityQuestionForm.controls[questionFieldName].errors?.required">
                    {{ 'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: questionLabel } }}
                </vpg-control-error>
            </ng-template>
        </vpg-dropdown-next>
    </div>
    <div class="no-side-padding answer-container groove-wrapper">
        <vpg-input-field class="groove-input-vpg-input-field"
                         [formControlName]="answerFieldName"
                         [name]="answerFieldName"
                         [required]="isRequired"
                         [placeholder]="'enrollment.flex.securityQuestion.answerPlaceholder' | translate"
                         [ariaLabel]="answerLabel"
                         [label]="answerLabel"
                         (focusout)="onBlur()">
            <ng-template #errors>
                <vpg-control-error role="alert" *ngIf="securityQuestionForm.controls[answerFieldName].errors?.required">
                    {{ 'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: answerLabel } }}
                </vpg-control-error>
                <vpg-control-error role="alert" *ngIf="securityQuestionForm.controls[answerFieldName].errors?.minlength">
                    {{ 'enrollment.forms.SecurityQuestion.AnswersMustBe' | translate }}
                </vpg-control-error>
            </ng-template>
        </vpg-input-field>
    </div>
</form>
