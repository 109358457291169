<div class="bubble-celebration-container">
    <canvas class="bubble-celebration-canvas"
            aria-hidden="true" #bubbleAnimationArea=""
            [(attr.width)]="_canvasWidth"
            [(attr.height)]="_canvasHeight"
            [ngStyle]="{ top: topOffset + 'px', left: leftOffset + 'px' }">
    </canvas>
    <div class="bubble-celebration-content" #bubbleContentArea="">
        <ng-content></ng-content>
    </div>
</div>
