<div class="agreement-container">
    <div>
        <groove-checkbox-field class="groove-wrapper"
                               [id]="field.fieldName + '-groove-field'"
                               [fieldName]="field.fieldName"
                               [label]="'enrollment.forms.agreements.iHaveReadAndAgree' | translate: { title: agreement?.name }"
                               [isDisabled]="field.editable === false"
                               (valueChanged)="onChange($event)"
                               (touched)="onTouch()"
                               [placeholder]="field.placeholder"
                               [hasLink]="true"
                               [linkText]="agreement?.name"
                               (clickedOnLink)="openModal()">
        </groove-checkbox-field>
    </div>
</div>
