import { FlexFormHistoryCacheKey } from "./flex-form-history-cache-key.class";
import { FlexForm } from "./flex-form.class";

export class FlexFormHistoryEntry {
    flexForm: FlexForm;
    formGroupValues: { [key: string]: any };
    key: FlexFormHistoryCacheKey;

    constructor(
        flexForm: FlexForm,
        formGroupValues: { [key: string]: any },
        key: FlexFormHistoryCacheKey
    ) {
        this.flexForm = flexForm;
        this.formGroupValues = formGroupValues;
        this.key = key;
    }
}
