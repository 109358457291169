import { Timezone } from './timezone.class';
import { SecurityQuestion } from './security-question.class';
import { GenderIdentity } from "./gender-identity.class";
import { StateOfResidence } from "./state-of-residence.class";
import { ProductCode } from "./product.enum";
import { EligibleProduct } from "./eligible-product";
import { AgreementVM } from "./agreement-vm.class";

export class MemberEnrollmentData {
  public memberEligibilityId: number;
  public memberEligibilityType: string;
  public externalId: string;
  public sponsorId: number;
  public legacySponsorId: number;
  public employeeId: string;
  public firstName: string;
  public lastName: string;
  public emailAddress: string;
  public password: string;
  public language: string;
  public gender: string;
  public genderIdentity: GenderIdentity;
  public dateOfBirth: string;
  public timezone: Timezone;
  public agreements: Array<AgreementVM>;
  public company: string;
  public officeLocation: string;
  public businessUnit: string;
  public enrollmentCode: string;
  public partnerCode: string;
  public username: string;
  public enrollmentGroupId: number;
  public stateProvince: string;
  public phoneNumber: string;
  public homePhoneNumber: string;
  public country: string;
  public stateOfResidence: StateOfResidence;
  public securityQuestions: Array<SecurityQuestion>;
  public securityQuestion1: SecurityQuestion;
  public securityQuestion2: SecurityQuestion;
  public securityQuestion3: SecurityQuestion;
  public validationToken: string;
  public eligibleProducts: Array<EligibleProduct>;
  public companyId: string = null;
  public customEnrollmentData: string;

  constructor() {
      this.employeeId = '';
      this.firstName = '';
      this.lastName = '';
      this.emailAddress = '';
      this.password = '';
      this.company = null;
      this.officeLocation = null;
      this.businessUnit = null;
      this.validationToken = null;
  }

  setFromEligibilityToken(eligibilityTokenData: MemberEnrollmentData) {
      this.memberEligibilityId = eligibilityTokenData.memberEligibilityId;
      this.externalId = eligibilityTokenData.externalId;
      this.sponsorId = eligibilityTokenData.sponsorId;
      this.legacySponsorId = eligibilityTokenData.legacySponsorId;
      this.employeeId = eligibilityTokenData.employeeId;
      this.firstName = eligibilityTokenData.firstName;
      this.lastName = eligibilityTokenData.lastName;
      this.emailAddress = eligibilityTokenData.emailAddress;
      this.password = eligibilityTokenData.password;
      this.language = eligibilityTokenData.language;
      this.gender = eligibilityTokenData.gender;
      this.genderIdentity = eligibilityTokenData.genderIdentity;
      this.dateOfBirth = eligibilityTokenData.dateOfBirth;
      this.timezone = eligibilityTokenData.timezone;
      this.agreements = eligibilityTokenData.agreements;
      this.enrollmentCode = eligibilityTokenData.enrollmentCode;
      this.partnerCode = eligibilityTokenData.partnerCode;
      this.username = eligibilityTokenData.username;
      this.enrollmentGroupId = eligibilityTokenData.enrollmentGroupId;
      this.stateProvince = eligibilityTokenData.stateProvince;
      this.phoneNumber = eligibilityTokenData.phoneNumber;
      this.homePhoneNumber = eligibilityTokenData.homePhoneNumber;
      this.country = eligibilityTokenData.country;
      this.stateOfResidence = eligibilityTokenData.stateOfResidence;
      this.securityQuestions = eligibilityTokenData.securityQuestions;
      this.securityQuestion1 = eligibilityTokenData.securityQuestion1;
      this.securityQuestion2 = eligibilityTokenData.securityQuestion2;
      this.securityQuestion3 = eligibilityTokenData.securityQuestion3;
      this.validationToken = eligibilityTokenData.validationToken;
      this.eligibleProducts = eligibilityTokenData.eligibleProducts;
      this.memberEligibilityType = eligibilityTokenData.memberEligibilityType;

      if (eligibilityTokenData.company) {
          this.company = eligibilityTokenData.company;
      }

      if (eligibilityTokenData.officeLocation) {
          this.officeLocation = eligibilityTokenData.officeLocation;
      }

      if (eligibilityTokenData.businessUnit) {
          this.businessUnit = eligibilityTokenData.businessUnit;
      }
  }
}
