import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WaitlistEligible } from '../shared/waitlist-eligible.class';
import { AsyncSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WaitlistRegistrationOpen } from '../shared/waitlist-registration-open.class';

@Injectable({
    providedIn: 'root'
})
export class WaitlistService {

    private waitlistClosedError = 'Waitlist is closed.';
    private emailExistsError = 'Email address is already on waitlist.';
    private genericWaitlistError = 'Error saving to waitlist.';
    private ERRORS = [this.waitlistClosedError, this.emailExistsError, this.genericWaitlistError];
    private registrationOpen: AsyncSubject<any>;

    waitlistFormComplete: ReplaySubject<Boolean> = new ReplaySubject<Boolean>();

    constructor(
        private httpClient: HttpClient,
        private translateService: TranslateService
    ) {
        this.waitlistFormComplete.next(false);
    }

    setWaitlistFormComplete() {
        this.waitlistFormComplete.next(true);
    }

    saveWaitlistEligible(waitlistEligible: WaitlistEligible): Observable<WaitlistEligible> {
        const SAVE_URL = `enrollment-api/sponsors/${waitlistEligible.sponsorId}/waitlist`;

        return this.httpClient.post<WaitlistEligible>(SAVE_URL, waitlistEligible).pipe(
            catchError((error) => {
                throw error && error.error && error.error.message && this.ERRORS.includes(error.error.message)
                    ? this.translateErrorMessage(error.error.message)
                    : this.translateService.instant('enrollment.waitlist.waitlistSaveError')
            })
        );
    }

    translateErrorMessage(message: string) {
        switch (message) {
            case this.waitlistClosedError: return this.translateService.instant('enrollment.waitlist.waitlistClosed');
            case this.emailExistsError: return this.translateService.instant('enrollment.waitlist.emailOnWaitlist');
            default: return this.translateService.instant('enrollment.waitlist.waitlistSaveError')
        }
    }

    validateAgainstSponsorCompanyRegistrationCap(waitlistEligible: WaitlistEligible) {
        const VALIDATE_URL = `enrollment-api/sponsors/${waitlistEligible.sponsorId}/companies/${waitlistEligible.companyId}/caps/validate`;

        return this.httpClient.get(VALIDATE_URL);
    }

    validateEligibilityTokenAgainstRegistrationCap(sponsorId: number, eligibilityToken: string): Observable<WaitlistRegistrationOpen> {

        this.registrationOpen = new AsyncSubject();

        this.httpClient.get<any>(`enrollment-api/sponsors/${ sponsorId }/registration-cap/check`, {
            params: { eligibilityToken }
        }).subscribe((registrationStatus) => {
            this.registrationOpen.next(registrationStatus);
            this.registrationOpen.complete();
        });

        return this.registrationOpen;
    }
}
