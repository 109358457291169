import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class ErrorBannerService {
  private bannerVisibleSource = new Subject<boolean>();
  private errorMessage = new Subject<string>();
  
  bannerVisible$ = this.bannerVisibleSource.asObservable();

  setBannerVisible(boolean, error) {
    this.bannerVisibleSource.next(boolean);
    this.errorMessage.next(error);
  }

  getErrorMessage() {
    return this.errorMessage;
  }

}
