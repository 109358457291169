import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'agreement-modal',
    templateUrl: './agreement-modal.component.html',
    styleUrls: ['./agreement-modal.component.scss']
})
export class AgreementModalComponent implements OnInit {
    @Input() agreement: any;
    @Output() closeDialog: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();

    triggerElement!: HTMLElement;

    ngOnInit() {
        this.setTriggerElement();
    }

    close(): void {
        this.closeDialog.emit('Closed');
        this.triggerElement.focus();
    }

    private setTriggerElement(): void {
        this.triggerElement = document.activeElement as HTMLElement;
    }
}
