import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EnrollmentErrorService } from './enrollment-error.service';
import { MemberEnrollmentData } from './member-enrollment-data.class';

@Injectable()
export class MemberEligibilityUpdateService {
    constructor(
        private http: HttpClient,
        private enrollmentErrorService: EnrollmentErrorService
    ) { }

    updateMemberEligibility(member: MemberEnrollmentData): Observable<MemberEnrollmentData> {
        let MEMBER_UPDATE_URL: string = `/enrollment-api/sponsors/${ member.sponsorId }/enroll/update`;

        return this.http.post<MemberEnrollmentData>(MEMBER_UPDATE_URL, member)
            .pipe(
                catchError((error) => {
                    throw this.enrollmentErrorService.handleError(error);
                })
            );
    }
}
