import { Component, Input } from '@angular/core';
import { ProgressBarStepData } from 'src/app/shared/flex-forms/progress-bar-step-data.class';
import { SegmentedProgressBarIconType, SegmentedProgressBarStepModel } from '@engineering/genesis-frontend';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
    progressBarSteps: SegmentedProgressBarStepModel[] = [];
    stepData: ProgressBarStepData[] = [];
    progressBarCurrentStep: number = null;
    iconType: SegmentedProgressBarIconType = SegmentedProgressBarIconType.Fa;
    currentProgress: number = 0;

    constructor(
        private translateService: TranslateService
    ) {
    }

    @Input() set steps(data: ProgressBarStepData[]) {
        if (data) {
            this.stepData = data;
            this.applyStepDataToModel(data);
        }
    }

    applyStepDataToModel(stepData: ProgressBarStepData[]) {
        this.progressBarCurrentStep = stepData?.findIndex(step => step.isCurrentStep);
        this.currentProgress = this.progressBarCurrentStep * 100 / stepData.length;
        this.progressBarSteps = stepData?.map( (step: ProgressBarStepData, index: number) => <SegmentedProgressBarStepModel> {
            title: step.title,
            description: step.isCurrentStep ? step.description : '',
            iconClass: index < this.progressBarCurrentStep ? 'fa-light fa-check' : step.iconSource,
            topCap: (index + 1) * 100 / stepData.length,
            readerText: step.isCurrentStep ? this.translateService.instant('enrollment.screenReader.stepCount', { stepNumber: (this.progressBarCurrentStep + 1), stepCount: stepData.length }) : ''
        });
    }
}
