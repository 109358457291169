import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AsyncSubject, Observable } from 'rxjs';

@Injectable()
export class EnrollmentGroupsSettingsService {
    private currentSettingsSubject: AsyncSubject<Object>;
    private currentEnrollmentGroupId: number;
    private currentLanguage: string;

    constructor(
        private http: HttpClient
    ) {
    }

    getEnrollmentGroupSettings(sponsorId, enrollmentGroupId, language): Observable<Object> {
        let SPONSOR_ENROLLMENT_GROUPS_URL: string = `/enrollment-api/sponsors/${ sponsorId }/enrollment-groups/${ enrollmentGroupId }/settings?language=${ language }`;

        if (this.currentEnrollmentGroupId === enrollmentGroupId && this.currentLanguage === language) {
            return this.currentSettingsSubject;
        }

        this.currentSettingsSubject = new AsyncSubject();
        this.currentEnrollmentGroupId = enrollmentGroupId;
        this.currentLanguage = language;

        this.http.get(SPONSOR_ENROLLMENT_GROUPS_URL).subscribe((enrollmentGroupSettings) => {
             this.currentSettingsSubject.next(enrollmentGroupSettings);
             this.currentSettingsSubject.complete();
         });

         return this.currentSettingsSubject;
    }
}
