<div class="footer">
    <div class="legal">
        <a class="item focusable-link"
           role="link"
           tabindex="0"
           target="_blank"
           href="https://personifyhealth.com/terms-of-use/"
           translate="enrollment.footer.termsOfUse"
           [attr.aria-description]="'enrollment.screenReader.opensInNewTab' | translate">
        </a>
        <a class="item focusable-link"
           role="link"
           tabindex="0"
           target="_blank"
           href="https://personifyhealth.com/general-privacy-notice/"
           translate="enrollment.footer.privacyPolicy"
           [attr.aria-description]="'enrollment.screenReader.opensInNewTab' | translate">
        </a>
    </div>
    <div class="support" *ngIf="sponsorSettings">
        <a class="support-button"
           tabindex="0"
           target="_blank"
           [href]="supportLink"
           *ngIf="sponsorSettings.enableSupportLink"
           [attr.aria-description]="'enrollment.screenReader.opensInNewTab' | translate">
            <img class="icon" src="assets/images/icon_support.png" aria-hidden="true">
            <span translate="enrollment.footer.support"></span>
        </a>
        <webchat-link *ngIf="sponsorSettings.enableChatSupport" class="support-button" tabindex="0" [imageSrc]="'assets/images/icon_chat.svg'" [title]="'enrollment.footer.chat' | translate"></webchat-link>
    </div>
</div>
