import { Component, OnInit, Input } from '@angular/core';
import { WaitlistEligible } from 'src/app/shared/waitlist-eligible.class';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StateService, Transition } from '@uirouter/angular';
import { SponsorSettings } from 'src/app/shared/sponsor-settings.class';
import { WaitlistService } from '../../waitlist.service';
import { TranslateService } from '@ngx-translate/core';
import { AgreementsService } from 'src/app/shared/agreements.service';
import { Agreement } from 'src/app/shared/agreement.class';
import { CountryService } from 'src/app/shared/country.service';

import * as _ from 'lodash';

@Component({
    selector: 'waitlist-sponsor-cap-form',
    templateUrl: './waitlist-sponsor-cap-form.component.html',
    styleUrls: ['../../waitlist.component.scss']
})
export class WaitlistSponsorCapFormComponent implements OnInit {
    agreements: Array<Agreement> = [];
    privacyPolicyConsentAgreement: Agreement;
    privacyPolicyConsentAgreementAccepted: Boolean = false;
    language: String;
    saveError: String;
    waitingForSaveResponse: Boolean = false;
    waitlistEligible: WaitlistEligible = new WaitlistEligible();
    waitlistForm: UntypedFormGroup;

    @Input() sponsorSettings: SponsorSettings;

    constructor(
        private fb: UntypedFormBuilder,
        private state: StateService,
        private transition: Transition,
        private waitlistService: WaitlistService,
        private countryService: CountryService,
        private translateService: TranslateService,
        private agreementsService: AgreementsService
    ) { }

    ngOnInit() {
        this.language = this.translateService.currentLang || this.translateService.getDefaultLang();
        this.waitlistEligible.sponsorId = this.sponsorSettings.sponsorId;

        this.waitlistForm = this.fb.group({
            sponsorId: [this.waitlistEligible.sponsorId, Validators.required],
            firstName: [this.waitlistEligible.firstName, Validators.required],
            lastName: [this.waitlistEligible.lastName, Validators.required],
            emailAddress: [this.waitlistEligible.emailAddress, Validators.required],
            dataStorageCheckbox: [this.privacyPolicyConsentAgreementAccepted, Validators.requiredTrue]
        });

        this.countryService.getSelectedCountry().subscribe((country) => {
            this.agreementsService.getAgreementsList('VP', this.sponsorSettings.sponsorId, this.language, _.get(country, 'id', null)).subscribe((agreements) => {
                this.privacyPolicyConsentAgreement = _.find(agreements, (agreement) => {
                    return agreement.type === 'PrivacyPolicy';
                });

                if (this.privacyPolicyConsentAgreement) {
                    this.privacyPolicyConsentAgreement.accepted = false;
                    this.privacyPolicyConsentAgreement.showContent = false;
                    this.agreements.push(this.privacyPolicyConsentAgreement);
                }
            });
        });
    }

    toggleContent(agreement: Agreement, event: Event) {
        event.preventDefault();
        agreement.showContent = !agreement.showContent;
    }

    isSaveDisabled() {
        return !(this.waitlistForm.valid &&
            this.waitlistEligible.emailAddress &&
            this.waitlistEligible.firstName &&
            this.waitlistEligible.lastName &&
            this.waitlistEligible.sponsorId &&
            this.privacyPolicyConsentAgreement &&
            this.privacyPolicyConsentAgreementAccepted)
    }

    save() {
        this.waitlistEligible.sponsorId = this.sponsorSettings.sponsorId;
        this.waitlistEligible.language = this.translateService.currentLang || this.translateService.getDefaultLang();
        this.waitingForSaveResponse = true;

        this.waitlistService.saveWaitlistEligible(this.waitlistEligible).subscribe(() => {
            this.saveError = null;
            this.waitingForSaveResponse = false;
            this.waitlistService.setWaitlistFormComplete();
        }, (error) => {
            this.saveError = error;
            console.log(error);
        });
    }
}
