<form-wrapper>
    <h1 class="page-title" *ngIf="!isAbbreviatedEnrollment" translate="">enrollment.forms.signUp</h1>
    <div class="signup-header" *ngIf="!isAbbreviatedEnrollment">
        <div *ngIf="isScreenCopySet">{{ enrollmentGroupSettingsText }}</div>
        <div *ngIf="!isScreenCopySet" [ngSwitch]="ruleType">
            <div *jrSwitchCases="['NoEligibilityFile', 'NoEligibilityFileWithOnlyBirthYear']"
                 [innerHtml]="'enrollment.forms.welcomeBack' | translate: { firstName: currentMemberService.memberEnrollmentData.firstName }">
            </div>
            <div *ngSwitchDefault translate>enrollment.forms.oneLastStep</div>
        </div>
    </div>
    <div *ngIf="ruleType" [ngSwitch]="ruleType">
        <no-eligibility-file-signup-form *ngSwitchCase="'NoEligibilityFile'"
                                         [sponsorSettings]="_sponsorSettings"
                                         [customEnrollmentDataField]="customEnrollmentDataField">
        </no-eligibility-file-signup-form>
        <no-eligibility-file-signup-form *ngSwitchCase="'NoEligibilityFileWithOnlyBirthYear'"
                                         [sponsorSettings]="_sponsorSettings"
                                         [customEnrollmentDataField]="customEnrollmentDataField">
        </no-eligibility-file-signup-form>
        <lastname-dateofbirth-gender-signup-form *ngSwitchCase="'LastNameAndDateOfBirthAndGender'"
                                                 [sponsorSettings]="_sponsorSettings"
                                                 [isEmailConfirmationRequired]="isEmailConfirmationRequired"
                                                 [isEmailOptional]="isEmailOptional"
                                                 [customEnrollmentDataField]="customEnrollmentDataField">
        </lastname-dateofbirth-gender-signup-form>
        <lastname-dateofbirth-state-signup-form *jrSwitchCases="['LastNameAndDateOfBirthAndState', 'FirstNameAndLastNameAndDateOfBirth']"
                                                [sponsorSettings]="_sponsorSettings"
                                                [isEmailConfirmationRequired]="isEmailConfirmationRequired"
                                                [isEmailOptional]="isEmailOptional"
                                                [customEnrollmentDataField]="customEnrollmentDataField">
        </lastname-dateofbirth-state-signup-form>
        <employeeid-lastname-dateofbirth-signup-form *jrSwitchCases="['EmployeeIdAndLastNameAndDateOfBirth','EnrollmentCodeAndLastNameAndDateOfBirth']"
                                                     [sponsorSettings]="_sponsorSettings"
                                                     [isEmailConfirmationRequired]="isEmailConfirmationRequired"
                                                     [isEmailOptional]="isEmailOptional"
                                                     [customEnrollmentDataField]="customEnrollmentDataField">
        </employeeid-lastname-dateofbirth-signup-form>
        <employeeid-signup-form *ngSwitchCase="'EmployeeId'"
                                [sponsorSettings]="_sponsorSettings"
                                [isEmailConfirmationRequired]="isEmailConfirmationRequired"
                                [isEmailOptional]="isEmailOptional"
                                [isAbbreviatedEnrollment]="isAbbreviatedEnrollment"
                                [customEnrollmentDataField]="customEnrollmentDataField">
        </employeeid-signup-form>
        <basic-signup-form *ngSwitchDefault
                           [sponsorSettings]="_sponsorSettings"
                           [isEmailConfirmationRequired]="isEmailConfirmationRequired"
                           [isEmailOptional]="isEmailOptional"
                           [customEnrollmentDataField]="customEnrollmentDataField">
        </basic-signup-form>
    </div>
    <a class="go-back d-none d-md-block"
       uiSref="{{ goBackState }}"
       [uiParams]="transition.params()"
       *ngIf="(ruleType && ruleType !== 'NoEligibilityFile') && !isEmailConfirmationRequired && !isAbbreviatedEnrollment">
        <span aria-hidden="true">
            | &#60;
        </span>
        <span translate="">enrollment.forms.goBack</span>
    </a>
</form-wrapper>
