import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { Constants } from '../../../../shared/constants.class';
import { BaseFormField } from '../../../../shared/flex-forms/base-form-field.class';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reactive-username',
    templateUrl: './reactive-username.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveUsernameComponent)
        }
    ]
})
export class ReactiveUsernameComponent extends BaseFormField implements OnInit, ControlValueAccessor {
    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;

    username: string;

    customValidatorsWithMessages: { validatorName: string, validator: ValidatorFn }[] = [];

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.username = this.field.initialValue;
        this.customValidatorsWithMessages.push({
            validatorName: 'usernameValidator',
            validator: this.usernameValidator()
        });
    }

    writeValue(newUsername: string): void {
        this.username = newUsername;
    }

    onChange(newValue: any): void {
        this.username = newValue;
        this.propagateChange(this.username);
    }

    onTouch(): void {
        this.propagateTouch();
    }

    private usernameValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && !Constants.REGEX.USERNAME.test(control.value as string)) {
                return {
                    usernameValidator: 'Invalid',
                    message: this.translateService.instant('enrollment.forms.usernamesMustHave')
                };
            }

            return null;
        };
    }
}

