import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Language } from './language.class';

@Injectable({
    providedIn: 'root'
})
export class LanguagesService {
    private languageList: Observable<Array<Language>>;
    private defaultLanguageSet: BehaviorSubject<boolean>

    constructor(private http: HttpClient) {
        let LANGUAGES_URL: string = '/enrollment-api/languages';

        this.languageList = this.http.get<Language[]>(LANGUAGES_URL)
            .pipe(
            shareReplay(1))
        this.defaultLanguageSet = new BehaviorSubject<boolean>(false)
    }

    getLanguages(): Observable<Array<Language>> {
        return this.languageList;
    }

    setDefaultLanguageStatus(status: boolean) {
        this.defaultLanguageSet.next(status)
    }

    isDefaultLanguageSet() {
        return this.defaultLanguageSet.asObservable();
    }
}
