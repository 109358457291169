import { StateService, Transition, UIRouter } from '@uirouter/angular';
import { LanguagesService } from './shared/languages.service';

import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';

import { CurrentMemberService } from './shared/current-member.service';
import { EnrollmentApiSponsorsEnrollmentGroupsService } from './shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-api-sponsors-enrollment-groups.service';
import { EnrollmentGroupsSettingsService } from './shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-groups-settings.service';
import { SponsorEnrollmentGroupsService } from './shared/sponsor-enrollment-groups.service';
import { SponsorSettingsService } from './shared/sponsor-settings.service';
import { WaitlistService } from './waitlist/waitlist.service';

import { AgreementsComponent } from './agreements/agreements.component';
import { WaitlistRegistrationOpen } from './shared/waitlist-registration-open.class';
import { SignUpComponent } from './signup/signup.component';
import { EnrollmentGroupSelectionComponent } from './sponsors/groups/enrollment-group-selection.component';
import { ValidationComponent } from './validation/validation.component';
import { VerifyComponent } from './verify/verify.component';
import { RegistrationClosedAgainComponent } from './closed-registration/registration-closed-again.component';

import { IdentifyComponent } from './identify/identify.component';

import { EnrollmentGroup } from './shared/enrollment-group.class';
import { SponsorSettings } from './shared/sponsor-settings.class';

import { ClosedRegistrationComponent } from './closed-registration/closed-registration.component';
import { MaintenanceRegistrationComponent } from './maintenance-registration/maintenance-registration.component';
import { ProductSelectComponent } from './productselect/product-select.component';
import { RegistrationCapType } from './shared/registration-cap-type.enum';
import { RegistrationDetails } from './shared/registration-details.class';
import { RegistrationStatus } from './shared/registration-status.enum'
import { WaitlistComponent } from './waitlist/waitlist.component';
import { FlexFormPageComponent } from './flex-forms/flex-form-page/flex-form-page.component';
import { Constants } from './shared/constants.class';
import { FlexFormEnrollmentGroupsService } from './shared/flex-forms/flex-form-enrollment-groups.service';
import { GenesisApiEnrollmentSponsorsService } from './shared/genesis-api/services/genesis-api-enrollment-sponsors.service';
import { GenesisSponsor } from './shared/genesis-sponsor.class';

export const states = [
    {
        name: 'home',
        url: '/?language',
        component: FlexFormPageComponent,
        resolve: [
            {
                token: 'setLanguage',
                deps: [Transition, TranslateService, LanguagesService],
                resolveFn: setLanguage
            }
        ]
    },
    {
        name: 'legacyHome',
        url: '/:legacySponsorId',
        component: FlexFormPageComponent,
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsByLegacySponsorId
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'sponsorSettings'],
                resolveFn: redirectLegacyHome
            }
        ]
    },
    {
        name: 'legacySignup',
        url: '/:legacySponsorId/signup?token',
        component: FlexFormPageComponent,
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroups',
                deps: [EnrollmentApiSponsorsEnrollmentGroupsService, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroupsList
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'sponsorSettings', 'enrollmentGroups'],
                resolveFn: redirectLegacySignup
            }
        ]
    },
    {
        name: 'enrollmentGroupSelection',
        url: '/sponsors/:sponsorId/enrollmentGroups?language',
        component: EnrollmentGroupSelectionComponent,
        resolve: [
            {
                token: 'registrationDetails',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getRegistrationDetailsBySponsorId
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails'],
                resolveFn: redirectToClosedMaintenanceOrWaitlist
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'setLanguage',
                deps: [Transition, TranslateService, LanguagesService],
                resolveFn: setLanguage
            },
            {
                token: 'initialEnrollmentGroups',
                deps: [EnrollmentApiSponsorsEnrollmentGroupsService, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroupsList
            },
            {
                token: 'checkForSingleGroup',
                deps: [Transition, StateService, 'sponsorSettings', 'initialEnrollmentGroups'],
                resolveFn: checkForSingleEnrollmentGroup
            },
            {
                token: 'genesisSponsor',
                deps: [ Transition, GenesisApiEnrollmentSponsorsService ],
                resolveFn: getGenesisSponsor
            },
            {
                token: 'redirectToFlexEnrollmentGroupSelection',
                deps: [ Transition, StateService, 'sponsorSettings', 'genesisSponsor' ],
                resolveFn: redirectToFlexEnrollmentGroupSelection
            }
        ]
    },
    {
        name: 'selectedSponsorRedirect',
        url: '/sponsors/:sponsorId?language',
        redirectTo: 'enrollmentGroupSelection'
    },
    {
        name: 'enrollmentGroupRedirect',
        url: '/sponsors/:sponsorId/',
        redirectTo: 'enrollmentGroupSelection'
    },
    {
        name: 'productSelect',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId/productSelect',
        component: ProductSelectComponent,
        resolve: [
            {
                token: 'productList',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            },
            {
                token: 'checkMemberData',
                deps: [Transition, StateService, CurrentMemberService],
                resolveFn: requireMemberData
            }
        ]
    },
    {
        name: 'closedRegistration',
        url: '/sponsors/:sponsorId/closed',
        component: ClosedRegistrationComponent,
        resolve: [
            {
                token: 'registrationDetails',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getRegistrationDetailsBySponsorId
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails'],
                resolveFn: redirectToEnrollmentGroupSelection
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails'],
                resolveFn: redirectToMaintenance
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails', 'sponsorSettings'],
                resolveFn: redirectToWaitlist
            }
        ]
    },
    {
        name: 'maintenanceRegistration',
        url: '/sponsors/:sponsorId/maintenance',
        component: MaintenanceRegistrationComponent,
        resolve: [
            {
                token: 'registrationDetails',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getRegistrationDetailsBySponsorId
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails'],
                resolveFn: redirectToEnrollmentGroupSelection
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails'],
                resolveFn: redirectToClosed
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails', 'sponsorSettings'],
                resolveFn: redirectToWaitlist
            }
        ]
    },
    {
        name: 'validate',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId?redirectFrom&eligibilityToken&postEnrollmentRedirectPath',
        component: ValidationComponent,
        resolve: [
            {
                token: 'registrationDetails',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getRegistrationDetailsBySponsorId
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            },
            {
                token: 'enrollmentGroupSettings',
                deps: [EnrollmentGroupsSettingsService, TranslateService, 'enrollmentGroup'],
                resolveFn: getEnrollmentGroupSettings
            },
            {
                token: 'registrationOpenAfterWaitlistEmailRedirect',
                deps: [Transition, 'registrationDetails', WaitlistService],
                resolveFn: getRegistrationInfoAfterWaitlistEmailRedirect
            },
            {
                token: 'redirectFromV2ToFlex',
                deps: [Transition, StateService, 'sponsorSettings', 'enrollmentGroup'],
                resolveFn: redirectFromV2ToFlex
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails', 'registrationOpenAfterWaitlistEmailRedirect', WaitlistService],
                resolveFn: redirectToClosedMaintenanceOrWaitlist
            }
        ]
    },
    {
        name: 'identify',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId/identify',
        component: IdentifyComponent,
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            },
            {
                token: 'checkMemberData',
                deps: [Transition, StateService, CurrentMemberService],
                resolveFn: requireMemberData
            }
        ]
    },
    {
        name: 'signup',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId/signup?token',
        component: SignUpComponent,
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            },
            {
                token: 'enrollmentGroupSettings',
                deps: [EnrollmentGroupsSettingsService, TranslateService, 'enrollmentGroup'],
                resolveFn: getEnrollmentGroupSettings
            },
            {
                token: 'redirectFromV2ToFlex',
                deps: [Transition, StateService, 'sponsorSettings', 'enrollmentGroup'],
                resolveFn: redirectFromV2ToFlex
            }
        ]
    },
    {
        name: 'verify',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId/verify',
        component: VerifyComponent,
        resolve: [
            {
                token: 'checkMemberData',
                deps: [Transition, StateService, CurrentMemberService],
                resolveFn: requireMemberData
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            }
        ]
    },
    {
        name: 'agreements',
        url: '/products/:product/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId/agreements?eligibilityToken&redirect',
        component: AgreementsComponent,
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            },
            {
                token: 'checkMemberData',
                deps: [Transition, StateService, CurrentMemberService],
                resolveFn: requireMemberData
            }
        ]
    },
    {
        name: 'waitlist',
        url: '/sponsors/:sponsorId/waitlist',
        component: WaitlistComponent,
        resolve: [
            {
                token: 'registrationDetails',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getRegistrationDetailsBySponsorId
            },
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'redirect',
                deps: [Transition, StateService, 'registrationDetails', 'sponsorSettings'],
                resolveFn: redirectToClosed
            }
        ]
    },
    {
        name: 'registrationClosedAgain',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId/registrationClosedAgain?eligibilityToken',
        component: RegistrationClosedAgainComponent
    },
    {
        name: 'flex_no_step',
        url: '/enrollmentGroups/:enrollmentGroupExternalId?redirectFrom&emailVerification&eligibilityToken&postEnrollmentRedirectPath',
        resolve: [
            {
                token: 'redirect',
                deps: [Transition, StateService],
                resolveFn: redirectToFlex
            }
        ]
    },
    {
        name: 'flex',
        url: '/enrollmentGroups/:enrollmentGroupExternalId/step/:step?redirectFrom&emailVerification&eligibilityToken&postEnrollmentRedirectPath',
        component: FlexFormPageComponent
    },
    {
        name: 'flexEnrollmentGroupRedirect',
        url: '/sponsor/:sponsorExternalId?language',
        redirectTo: 'flexEnrollmentGroupSelection'
    },
    {
        name:'flexEnrollmentGroupSelection',
        url: '/sponsor/:sponsorExternalId/enrollment-groups?language',
        component: FlexFormPageComponent,
        resolve: [
            {
                token: 'setLanguage',
                deps: [Transition, TranslateService, LanguagesService],
                resolveFn: setLanguage
            },
            {
                token: 'sponsorSettingsBySponsorGuid',
                deps: [ SponsorSettingsService, Transition ],
                resolveFn: getSponsorSettingsBySponsorGuid
            },
            {
                token: 'isV2EnrollmentVersion',
                deps: [ Transition, StateService, 'sponsorSettingsBySponsorGuid' ],
                resolveFn: isV2EnrollmentVersion
            },
            {
                token: 'enrollmentGroupsBySponsorGuid',
                deps: [ FlexFormEnrollmentGroupsService, Transition ],
                resolveFn: getEnrollmentGroupsListBySponsorGuid
            },
            {
                token: 'isSingleEnrollmentGroup',
                deps: [ Transition, StateService, 'enrollmentGroupsBySponsorGuid' ],
                resolveFn: isSingleEnrollmentGroup
            }
        ]
    }
];

export function routerSetup(router: UIRouter) {
    router.urlService.rules.otherwise({ state: 'home' });
}

export function getEnrollmentGroupsList(
    enrollmentApiSponsorsEnrollmentGroupsService: EnrollmentApiSponsorsEnrollmentGroupsService,
    translateService: TranslateService,
    sponsorSettings: SponsorSettings
) {
    return enrollmentApiSponsorsEnrollmentGroupsService.getEnrollmentGroups(sponsorSettings.sponsorId, translateService.currentLang).toPromise();
}

export function getEnrollmentGroupSettings(
    enrollmentGroupsSettingsService: EnrollmentGroupsSettingsService,
    translateService: TranslateService,
    enrollmentGroup: EnrollmentGroup
) {
    return enrollmentGroupsSettingsService.getEnrollmentGroupSettings(enrollmentGroup.sponsorId, enrollmentGroup.id, translateService.currentLang).toPromise();
}

export function getEnrollmentGroup(
    sponsorEnrollmentGroupsService: SponsorEnrollmentGroupsService,
    transition: Transition,
    translateService: TranslateService,
    sponsorSettings: SponsorSettings
) {
    return sponsorEnrollmentGroupsService.loadEnrollmentGroup(sponsorSettings.sponsorId, transition.params().enrollmentGroupId, translateService.currentLang).toPromise();
}

export function getSponsorSettingsBySponsorId(transition: Transition, sponsorSettingsService: SponsorSettingsService) {
    return sponsorSettingsService.getSponsorSettingsById(transition.params().sponsorId).toPromise();
}
export function getSponsorSettingsByLegacySponsorId(transition: Transition, sponsorSettingsService: SponsorSettingsService) {
    return sponsorSettingsService.getSponsorSettingsByLegacyId(transition.params().legacySponsorId).toPromise();
}
export function getRegistrationDetailsBySponsorId(transition: Transition, sponsorSettingsService: SponsorSettingsService) {
    return sponsorSettingsService.getRegistrationDetailsBySponsorId(transition.params().sponsorId).toPromise();
}
export function getRegistrationInfoAfterWaitlistEmailRedirect(transition: Transition, registrationDetails: RegistrationDetails,
                                                              waitlistService: WaitlistService) {
    const waitlistEmail = 'WaitlistEmail';
    if (transition.params().redirectFrom === waitlistEmail
        && registrationDetails.sponsorSettings.capType === RegistrationCapType.Company
        && transition.params().eligibilityToken
        && registrationDetails.sponsorSettings.enrollmentVersion !== Constants.ENROLLMENT_VERSION.API_ENROLLMENT_BETA) {
        return waitlistService.validateEligibilityTokenAgainstRegistrationCap(
            registrationDetails.sponsorSettings.sponsorId,
            transition.params().eligibilityToken).toPromise();
    }
    return null;
}

export function requireMemberData(
    transition: Transition,
    state: StateService,
    currentMemberService: CurrentMemberService
) {
    if (!currentMemberService.memberEnrollmentData.sponsorId) {
        transition.abort();
        state.go('validate', transition.params());
    }

    return true;
}

export function getAbbreviatedEnrollment(
    transition: Transition,
    state: StateService,
    enrollmentGroup: EnrollmentGroup
) {
    return enrollmentGroup.abbreviatedEnrollment;
}

export function redirectLegacyHome(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings,
) {
    transition.abort();
    state.go('enrollmentGroupSelection', { sponsorId: sponsorSettings.sponsorId });

    return true;
}

export function redirectToClosedMaintenanceOrWaitlist(
    transition: Transition,
    state: StateService,
    registrationDetails: RegistrationDetails,
    registrationOpenAfterWaitlistEmailRedirect: WaitlistRegistrationOpen
) {
    const params = {
        'sponsorId': registrationDetails.sponsorSettings.sponsorId,
        'enrollmentGroupId': transition.params().enrollmentGroupId,
        'eligibilityToken': transition.params().eligibilityToken
    };
    const constants = {
        STATE: {
            registrationClosedAgain: 'registrationClosedAgain',
            waitlist: 'waitlist',
            closedRegistration: 'closedRegistration',
            maintenanceRegistration: 'maintenanceRegistration'
        },
        WAITLIST_EMAIL: 'WaitlistEmail'
    };

    if (registrationDetails.sponsorSettings.enrollmentVersion === Constants.ENROLLMENT_VERSION.API_ENROLLMENT_BETA) {
        return false;
    }

    if (registrationDetails.registrationStatus === RegistrationStatus.Open) {
        if (registrationOpenAfterWaitlistEmailRedirect
                && !registrationOpenAfterWaitlistEmailRedirect.registrationOpen) {
            state.go(constants.STATE.registrationClosedAgain, params);
        }
        return false;
    }

    transition.abort();

    if (registrationDetails.waitlistOpen) {
        if (transition.params().redirectFrom === constants.WAITLIST_EMAIL) {
            state.go(constants.STATE.registrationClosedAgain, params);
        } else {
            state.go(constants.STATE.waitlist, params);
        }
    } else if (registrationDetails.registrationStatus == RegistrationStatus.Closed) {
        state.go(constants.STATE.closedRegistration, params);
    } else if (registrationDetails.registrationStatus == RegistrationStatus.Maintenance) {
        state.go(constants.STATE.maintenanceRegistration, params);
    }

    return true;
}

export function redirectToWaitlist(
    transition: Transition,
    state: StateService,
    registrationDetails: RegistrationDetails,
    sponsorSettings: SponsorSettings
) {
    const params = {
        'sponsorId': registrationDetails.sponsorSettings.sponsorId
    };

    if (registrationDetails.waitlistOpen) {
        transition.abort();
        state.go('waitlist', params);
        return true;
    }

    return false;
}

export function redirectToClosed(
    transition: Transition,
    state: StateService,
    registrationDetails: RegistrationDetails,
    sponsorSettings: SponsorSettings
) {
    const params = {
        'sponsorId': registrationDetails.sponsorSettings.sponsorId
    };

    if (!registrationDetails.waitlistOpen && registrationDetails.registrationStatus == RegistrationStatus.Closed) {
        transition.abort();
        state.go('closedRegistration', params);
        return true;
    }

    return false;
}

export function redirectToMaintenance(
    transition: Transition,
    state: StateService,
    registrationDetails: RegistrationDetails,
    sponsorSettings: SponsorSettings
) {
    const params = {
        'sponsorId': registrationDetails.sponsorSettings.sponsorId
    };

    if (registrationDetails.registrationStatus == RegistrationStatus.Maintenance) {
        transition.abort();
        state.go('maintenanceRegistration', params);
        return true;
    }

    return false;
}

export function redirectToEnrollmentGroupSelection(
    transition: Transition,
    state: StateService,
    registrationDetails: RegistrationDetails
) {
    const params = { 'sponsorId': registrationDetails.sponsorSettings.sponsorId };

    if (registrationDetails.registrationStatus === RegistrationStatus.Open) {
        transition.abort();
        state.go('enrollmentGroupSelection', params);
        return true;
    } else {
        return false;
    }
}

export function redirectLegacySignup(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings,
    enrollmentGroups: EnrollmentGroup[]
) {
    let selectedEnrollmentGroup = enrollmentGroups[0];
    transition.abort();
    state.go('signup', {
        sponsorId: sponsorSettings.sponsorId,
        enrollmentGroupId: selectedEnrollmentGroup.id,
        token: transition.params().token
    });

    return true;
}

export function checkForSingleEnrollmentGroup(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings,
    groups: Array<EnrollmentGroup>
) {
    var visibleEnrollmentGroups = _.reject(groups, function (enrollmentGroup) {
        return enrollmentGroup.hidden;
    });

    if (visibleEnrollmentGroups.length === 1) {
        transition.abort();
        state.go('validate', _.extend({ enrollmentGroupId: visibleEnrollmentGroups[0].id }, transition.params()));
    }
}

export function setLanguage(
    transition: Transition,
    translateService: TranslateService,
    languagesService: LanguagesService
) {
    if (transition.params().language) {
        languagesService.isDefaultLanguageSet().subscribe((defaultLanguageSet) => {
            if (defaultLanguageSet) {
                translateService.use(transition.params().language);
            }
        })
    }
}

export function redirectToFlex(
    transition: Transition,
    state: StateService
) {
    transition.abort();
    state.go('flex', _.extend({step: 1}, transition.params()));
    return true;
}

export function redirectFromV2ToFlex(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings,
    enrollmentGroup: EnrollmentGroup
) {
    if (sponsorSettings.enrollmentVersion === Constants.ENROLLMENT_VERSION.API_ENROLLMENT_BETA) {
        transition.abort();
        state.go('flex', _.extend({
            step: 1,
            enrollmentGroupExternalId: enrollmentGroup.externalId,
            emailVerification: transition.params().token
        }, transition.params()));
        return true;
    } else {
        return false;
    }
}

export function getEnrollmentGroupsListBySponsorGuid(
    flexFormEnrollmentGroupsService: FlexFormEnrollmentGroupsService,
    transition: Transition
) {
    return flexFormEnrollmentGroupsService.getEnrollmentGroupsBySponsorGuid(transition.params().sponsorExternalId).toPromise();
}

export function isSingleEnrollmentGroup(
    transition: Transition,
    state: StateService,
    groups: EnrollmentGroup[]
) {
    if (groups.length === 1) {
        transition.abort();
        state.go('flex', _.extend({ enrollmentGroupExternalId: groups[0].externalId, step: 1 }, transition.params()), { location: 'replace' });
        return true;
    }
}

export function getSponsorSettingsBySponsorGuid(
    sponsorSettingsService: SponsorSettingsService,
    transition: Transition
) {
    return sponsorSettingsService.getSponsorSettingsByExternalId(transition.params().sponsorExternalId).toPromise();
}

export function isV2EnrollmentVersion(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings
) {
    if (sponsorSettings.enrollmentVersion === Constants.ENROLLMENT_VERSION.V2_ENROLLMENT) {
        transition.abort();
        state.go('enrollmentGroupSelection', _.extend({ sponsorId: sponsorSettings.sponsorId }, transition.params()), { location: 'replace' });
        return true;
    }
}

export function getGenesisSponsor(
    transition: Transition,
    genesisEnrollmentSponsorService: GenesisApiEnrollmentSponsorsService
) {
    return genesisEnrollmentSponsorService.getSponsorInfoBySponsorId(transition.params().sponsorId).toPromise();
}

export function redirectToFlexEnrollmentGroupSelection(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings,
    genesisSponsor: GenesisSponsor
) {
    if (sponsorSettings.enrollmentVersion === Constants.ENROLLMENT_VERSION.API_ENROLLMENT_BETA) {
        transition.abort();
        state.go('flexEnrollmentGroupSelection', _.extend({ sponsorExternalId: genesisSponsor.guid }, transition.params()), { location: 'replace' });
        return true;
    }
}
