<div #appContentArea class="app-container" [ngStyle]="{ 'background-image': 'url(' + coverPhoto + ')' }">
    <header [ngClass]="{'app-header': isHeaderVisible}" [isHeaderVisible]="isHeaderVisible" [sponsorSettings]="sponsorSettings"></header>
    <ie-warning-banner></ie-warning-banner>
    <div class="app-content container-fluid">
        <div class="app-content-inner row">
            <div class="app-content-container-wrapper">
                <bubble-celebration #celebration="" [celebrationHeight]="100" [celebrationWidth]="100" [particleCount]="50"
                    [animationDuration]="1500" [colors]="celebrationColors">
                    <div class="app-content-container">
                        <ui-view></ui-view>
                    </div>
                </bubble-celebration>
            </div>
        </div>
    </div>

    <div class="footer-container">
        <footer [sponsorSettings]="sponsorSettings"></footer>
    </div>
</div>
