<form class="groove-input-form" [formGroup]="form">
    <vpg-input-field class="groove-input-vpg-input-field"
                     [formControlName]="fieldName"
                     [name]="fieldName"
                     [required]="isRequired"
                     [placeholder]="placeholder"
                     [ariaLabel]="label"
                     [label]="label"
                     [type]="type"
                     [maxLength]="maxLength"
                     (focusout)="onBlur()">
        <ng-template #suffix *ngIf="hasIcon">
            <vpg-icon-next (vpg-interactive-element)="toggleFieldVisible()"
                           [icon]="fieldVisible ? 'eye' : 'eye-slash'"
                           [screenReaderText]="(fieldVisible ? 'enrollment.forms.arialLabelIDVisible' : 'enrollment.forms.arialLabelIDHidden') | translate">
            </vpg-icon-next>
        </ng-template>
        <ng-template #errors>
            <vpg-control-error [id]="fieldName + '-required-error-message'"
                               role="alert"
                               *ngIf="form.controls[fieldName].errors?.required">
                {{ requiredErrorMessage }}
            </vpg-control-error>
            <vpg-control-error [id]="fieldName + '-min-birth-year-error-message'"
                               role="alert"
                               *ngIf="form.controls[fieldName].errors?.minBirthYear">
                {{ form.controls[fieldName].errors?.message }}
            </vpg-control-error>
            <vpg-control-error [id]="fieldName + '-max-birth-year-error-message'"
                               role="alert"
                               *ngIf="form.controls[fieldName].errors?.maxBirthYear">
                {{ form.controls[fieldName].errors?.message }}
            </vpg-control-error>
            <vpg-control-error [id]="fieldName + '-username-format-error-message'"
                               role="alert"
                               *ngIf="form.controls[fieldName].errors?.usernameValidator">
                {{ form.controls[fieldName].errors?.message }}
            </vpg-control-error>
            <vpg-control-error [id]="fieldName + '-min-date-of-birth-error-message'"
                               role="alert"
                               *ngIf="!form.controls[fieldName].errors?.dateFormat && form.controls[fieldName].errors?.minDateOfBirth">
                {{ form.controls[fieldName].errors?.message }}
            </vpg-control-error>
            <vpg-control-error [id]="fieldName + '-max-date-of-birth-error-message'"
                               role="alert"
                               *ngIf="!form.controls[fieldName].errors?.dateFormat && form.controls[fieldName].errors?.maxDateOfBirth">
                {{ form.controls[fieldName].errors?.message }}
            </vpg-control-error>
            <vpg-control-error [id]="fieldName + '-date-format-error-message'"
                               role="alert"
                               *ngIf="form.controls[fieldName].errors?.dateFormat">
                {{ form.controls[fieldName].errors?.message }}
            </vpg-control-error>
        </ng-template>
    </vpg-input-field>
</form>
