import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Timezone } from './timezone.class';

@Injectable()
export class TimezonesService {
    private timezoneList: Observable<Array<Timezone>>;

    constructor(private http: HttpClient) {
        let TIMEZONES_URL: string = '/enrollment-api/timezones';

        this.timezoneList = this.http.get<Timezone[]>(TIMEZONES_URL);
    }

    getTimezones() {
        return this.timezoneList;
    }
}