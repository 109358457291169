import { Component, Input } from '@angular/core';
import { GenericGrooveWrapperComponent } from '../generic-groove-wrapper/generic-groove-wrapper.component';
import { InputType } from '@engineering/genesis-frontend/domain/types';

@Component({
    selector: 'groove-input-field',
    templateUrl: './groove-input-field.component.html',
    styleUrls: ['./groove-input-field.component.scss']
})
export class GrooveInputFieldComponent extends GenericGrooveWrapperComponent {
    @Input()
    type: InputType = 'text';

    @Input()
    maxLength: number;

    @Input()
    hasIcon: boolean = false;

    fieldVisible: boolean = false

    ngOnInit() {
        super.ngOnInit();

        if (this.maxLength == 0) {
            this.maxLength = null
        }
    }

    toggleFieldVisible() {
        this.fieldVisible = !this.fieldVisible;
        this.type = this.fieldVisible ? 'text' : 'password';
    }
}
