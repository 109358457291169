import { Component, OnInit } from '@angular/core';

import { BaseValidationForm } from '../../shared/base-validation-form.class';

@Component({
    selector: 'basic-validation-form',
    templateUrl: './basic-validation-form.component.html',
    styleUrls: ['../../validation.component.scss']
})
export class BasicValidationFormComponent extends BaseValidationForm implements OnInit {

    ngOnInit() {
        super.ngOnInit()
    }

    onSubmit() {
        super.onSubmit();
    }
}
