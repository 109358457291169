import { Component, Input, OnInit } from '@angular/core';

import { Transition } from "@uirouter/angular";
import { AnalyticsTrackingCommandsService} from '../analytics-tracking/analyticsTrackingCommands.service';
import { EmailSentPageModel } from '../analytics-tracking/domain/email-sent-page.model';
import { CurrentMemberService } from '../shared/current-member.service';
import { EnrollmentGroup } from '../shared/enrollment-group.class';
import { SponsorSettings } from '../shared/sponsor-settings.class';

import * as _ from 'lodash';

@Component({
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
    returnLink: string = "";

    @Input() sponsorSettings: SponsorSettings;
    @Input() enrollmentGroup: EnrollmentGroup;

    constructor(
        transition: Transition,
        private analyticsTrackingCommandsService: AnalyticsTrackingCommandsService,
        public currentMemberService: CurrentMemberService
    ) {
        this.returnLink = `/#/sponsors/${ transition.params().sponsorId }/enrollmentGroups/${ transition.params().enrollmentGroupId }`;
    }

    ngOnInit() {
        this.trackEmailSentPageViewed();
    }

    private trackEmailSentPageViewed() {
        const emailSentPageModel: EmailSentPageModel = {
            validation_rule: _.lowerCase(this.enrollmentGroup.enrollmentValidationRule),
            sponsor_id: this.currentMemberService.memberEnrollmentData.sponsorId,
            sponsor_name: this.sponsorSettings.sponsorName.toLowerCase(),
            external_eligibility_id: this.currentMemberService.memberEnrollmentData.externalId
        }

        this.analyticsTrackingCommandsService.trackSignUpEmailSentPageViewed(
            emailSentPageModel,
            this.sponsorSettings.excludeMixpanelTracking
        );
    }

}
