<form class="container-fluid" novalidate=""
  *ngIf="currentMemberService.memberEnrollmentData && !isAbbreviatedEnrollment"
  [formGroup]="signUpForm"
  (ngSubmit)="save()">

  <div class="form-group" *ngIf="isEmailConfirmationRequired">
      <div>
        {{ currentMemberService.memberEnrollmentData.emailAddress }}
      </div>
  </div>

  <vpe-email *ngIf="!isEmailConfirmationRequired"
    formControlName="email"
    [(ngModel)]="currentMemberService.memberEnrollmentData.emailAddress"
    [sponsorSettings]="sponsorSettings"
    [emailOptional]="isEmailOptional">
  </vpe-email>

  <vpe-password id="password" name="password"
    formControlName="password"
    *ngIf="passwordRequired"
    [(ngModel)]="currentMemberService.memberEnrollmentData.password">
  </vpe-password>

  <vpe-country id="country"
    formControlName="country"
    [(ngModel)]="currentMemberService.memberEnrollmentData.country">
  </vpe-country>

  <vpe-phonenumber name="phoneNumber" *ngIf="sponsorSettings.allowPhoneNumberCollection"
    formControlName="phoneNumber"
    [(intlPhoneNumber)]="currentMemberService.memberEnrollmentData.phoneNumber"
    [phoneType]="'phoneNumber'">
  </vpe-phonenumber>

  <vpe-phonenumber name="homePhoneNumber"
    formControlName="homePhoneNumber"
    *ngIf="sponsorSettings.allowPhoneNumberCollection"
    [(intlPhoneNumber)]="currentMemberService.memberEnrollmentData.homePhoneNumber"
    [phoneType]="'homePhoneNumber'">
  </vpe-phonenumber>

  <vpe-birthdate id="birthdate"
    formControlName="dateOfBirth"
    *ngIf="!birthdateFilled"
    [(ngModel)]="currentMemberService.memberEnrollmentData.dateOfBirth">
  </vpe-birthdate>

  <vpe-gender-identity formControlName="genderIdentity"
    *ngIf="!genderIdentityFilled"
    [(ngModel)]="currentMemberService.memberEnrollmentData.genderIdentity"
    [sponsorSettings]="sponsorSettings">
  </vpe-gender-identity>

  <vpe-timezone formControlName="timezone"
    [(ngModel)]="currentMemberService.memberEnrollmentData.timezone">
  </vpe-timezone>

  <vpe-custom-enrollment-data formControlName="customEnrollmentDataField"
    *ngIf="customEnrollmentDataField"
    [customEnrollmentDataField]="customEnrollmentDataField">
  </vpe-custom-enrollment-data>

  <div *ngIf="hasSecurityQuestions">
      <p class="note" translate="">enrollment.forms.SecurityQuestion.KeepAccountSafe</p>

      <vpe-security-question name="securityQuestion1" orderIndex="1"
        formControlName="securityQuestion1"
        [(ngModel)]="currentMemberService.memberEnrollmentData.securityQuestion1"
        [otherQuestions]="[ currentMemberService.memberEnrollmentData.securityQuestion2, currentMemberService.memberEnrollmentData.securityQuestion3 ]">
      </vpe-security-question>

      <vpe-security-question name="securityQuestion" orderIndex="2"
        formControlName="securityQuestion2"
        [(ngModel)]="currentMemberService.memberEnrollmentData.securityQuestion2"
        [otherQuestions]="[ currentMemberService.memberEnrollmentData.securityQuestion1, currentMemberService.memberEnrollmentData.securityQuestion3 ]">
      </vpe-security-question>

      <vpe-security-question name="securityQuestion" orderIndex="3"
        formControlName="securityQuestion3"
        [(ngModel)]="currentMemberService.memberEnrollmentData.securityQuestion3"
        [otherQuestions]="[ currentMemberService.memberEnrollmentData.securityQuestion1, currentMemberService.memberEnrollmentData.securityQuestion2 ]">
      </vpe-security-question>
  </div>

  <div id="ldg-signup-save-error" aria-live="polite" *ngIf="saveError !== null">
    <small class="sr-only" translate="">enrollment.screenReader.error</small>

    <div [ngSwitch]="saveError">
      <small class="error-text"
        *ngSwitchCase="enrollmentError.UsernameExists"
        [innerHtml]="'enrollment.forms.errors.thatUsernameIsAlready' | translate:{ loginUrl: loginUrl }">
      </small>

      <small class="error-text"
        *ngSwitchCase="enrollmentError.EmailExists"
        [innerHtml]="'enrollment.forms.errors.emailInUse' | translate:{ loginUrl: loginUrl }">
      </small>

      <small class="error-text"
        *ngSwitchDefault=""
        [innerHtml]="'enrollment.forms.errors.oopsSomethingsWrong' | translate:{ supportEmailLink: supportEmailLink }">
      </small>
    </div>
  </div>

  <button class="create-account-button btn btn-orange"
    type="submit"
    translate=""
    aria-describedby="ldg-signup-save-error"
    [disabled]="!signUpForm.valid || loadingEnrollment">
      enrollment.forms.createMyAccount
  </button>
</form>
