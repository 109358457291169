<form-wrapper>
    <div [hidden]="!initialized">
        <h1 class="page-title" translate="">enrollment.forms.signUp</h1>
        <div class="container-fluid">
            <p *ngIf="hasGroups" translate="">enrollment.sponsors.groups.pleaseSelectGroup</p>
            <p *ngIf="!hasGroups" translate="">enrollment.sponsors.groups.noAvailableGroups</p></div>
        <div class="container-fluid">
            <form [formGroup]="groupSelectForm"
                  novalidate=""
                  (ngSubmit)="onContinue()"
                  *ngIf="hasGroups">
                <div class="form-group">
                    <div *ngFor="let group of enrollmentGroups">
                        <label class="enrollment-group-wrapper">
                            <input type="radio"
                                   name="enrollmentGroup"
                                   formControlName="enrollmentGroup"
                                   [value]="group"
                                   (change)="onChange()">
                            <span>{{ group.title }}</span>
                        </label>
                    </div>
                    <button class="create-account-button btn btn-orange"
                            type="submit"
                            translate=""
                            [disabled]="selectedEnrollmentGroup === null">
                        enrollment.forms.continue
                    </button>
                </div>
            </form>
        </div>
    </div>
</form-wrapper>
