<div class="container-fluid" *ngIf="showErrorBanner">
    <div class="error-banner-container row">
        <div [ngSwitch]="errorType">
            <span *ngSwitchCase="'expired-enrollment-link'" translate="">
                enrollment.forms.errors.linkExpired
            </span>

            <span *ngSwitchCase="'invalid-enrollment-link'" translate="">
                enrollment.forms.errors.linkInvalid
            </span>
        </div>

        <a class="close-banner" (click)="closeErrorBanner()">&times;</a>
    </div>
</div>
