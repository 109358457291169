import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { BubbleCelebrationComponent } from './utils/bubble-celebration/bubble-celebration.component';

import { ErrorBannerService } from './header/error-banner/error-banner.service';
import { MainCelebrationTriggerService } from './main-celebration-trigger.service';
import { SponsorSettings } from './shared/sponsor-settings.class';
import { SponsorSettingsService } from './shared/sponsor-settings.service';
import { Theme, ThemeManagerService, ThemeProperties } from '@engineering/genesis-frontend';
import { PageNameService } from './shared/page-name.service';
import { Constants } from './shared/constants.class';
import { environment } from '../environments/environment';

const closedCoverPhoto = 'https://file.virginpulse.com/api/file/sPKk6K1IRqazbLKCRe5R';
const waitlistPhoto = 'https://file.virginpulse.com/api/file/hTWpZqXdQMSsIx1iKhW8';
const maintenanceCoverPhoto = 'https://file.virginpulse.com/api/file/5RSWNtqSQE7TslfVEhF0';
const flexFormPhoto = 'https://file.virginpulse.com/api/file/6te3IHgJS7Ct2XSVfJvl';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    coverPhoto = '';
    sponsorSettings: SponsorSettings;
    pageName = '';

    celebrationColors = [
        'rgb(124, 222, 226)',
        'rgb(13, 183, 194)',
        'rgb(0, 148, 165)',
        'rgb(0, 128, 133)',
        'rgb(0, 141, 156)',
        'rgb(124, 222, 227)'
    ];

    @ViewChild('celebration', { static: true }) private celebration: BubbleCelebrationComponent;
    @ViewChild('appContentArea', { static: true }) private appContentArea: ElementRef;

    constructor(
        private mainCelebrationTrigger: MainCelebrationTriggerService,
        private sponsorSettingsService: SponsorSettingsService,
        private errorBannerService: ErrorBannerService,
        private themeManagerService: ThemeManagerService,
        private pageNameService: PageNameService
    ) {}

    ngOnInit() {
        const theme: Theme = {
            name: 'Enrollment',
            themeProperties: {
                '--VPGrooveColor-ButtonBackgroundColor': '#042961',
                '--VPGrooveColor-ButtonTextColor': '#FFF',
                '--VPGrooveColor-LinkColor': '#3671E2'
            } as ThemeProperties
        }

        this.themeManagerService.registerTheme(theme);

        this.setBackgroundPhoto(null);

        this.pageNameService.getPageName().subscribe((pageName) => {
            this.pageName = pageName;
            this.setBackgroundPhoto(this.pageName);
        });

        this.sponsorSettingsService.getRegistrationDetails().subscribe((details) => {
            this.sponsorSettings = details?.sponsorSettings;
            this.setBackgroundPhoto(this.pageName);
        });

        this.mainCelebrationTrigger.eventTrigger.subscribe(() => {
            this.celebration.animate();
        });

        this.errorBannerService.bannerVisible$.subscribe(
            (visible) => {
                if (visible) {
                    this.errorBannerService.getErrorMessage().subscribe(
                        (errorType) => {
                            if (errorType === 'expired-enrollment-link') {
                                setTimeout(() => {
                                    this.appContentArea.nativeElement.scrollTop = this.appContentArea.nativeElement.scrollHeight;
                                }, 500);
                            }
                        });
                }
            });

        this.appendGrooveCssStylesheet();
    }

    get isHeaderVisible(): boolean {
        return this.pageName !== Constants.PAGE_NAME.FLEX
                && this.pageName !== Constants.PAGE_NAME.HOME
                && this.pageName !== Constants.PAGE_NAME.FLEX_ENROLLMENT_GROUP_SELECTION
                && this.sponsorSettings?.enrollmentVersion === Constants.ENROLLMENT_VERSION.V2_ENROLLMENT;
    }

    appendGrooveCssStylesheet = () => {
        const link: any = document.createElement('link');

        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = environment.grooveCss.url;
        link.media = 'print';
        link.onload = function() {
            this.media = 'all';
        };

        document.head.appendChild(link);
    };

    private setBackgroundPhoto(page) {
        if (page === Constants.PAGE_NAME.WAITLIST || page === Constants.PAGE_NAME.REGISTRATION_CLOSED_AGAIN) {
            this.coverPhoto = waitlistPhoto;
        } else if (page === Constants.PAGE_NAME.CLOSED_REGISTRATION) {
            this.coverPhoto = closedCoverPhoto;
        } else if (page === Constants.PAGE_NAME.MAINTENANCE_REGISTRATION) {
            this.coverPhoto = maintenanceCoverPhoto;
        } else if (this.sponsorSettings && this.sponsorSettings.coverPhotoUrl) {
            this.coverPhoto = this.sponsorSettings.coverPhotoUrl;
        } else if (this.sponsorSettings?.enrollmentVersion === Constants.ENROLLMENT_VERSION.API_ENROLLMENT_BETA) {
            this.coverPhoto = flexFormPhoto;
        } else if (this.pageName === Constants.PAGE_NAME.HOME || this.pageName === Constants.PAGE_NAME.FLEX_ENROLLMENT_GROUP_SELECTION) {
            this.coverPhoto = flexFormPhoto;
        } else if (this.coverPhoto !== flexFormPhoto) {
            this.coverPhoto = flexFormPhoto;
        }
    }
}
