import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GenesisApiEnrollmentSponsorsService {

    constructor(private http: HttpClient) {}

    getSponsorInfoBySponsorId(sponsorId) {
        let ENROLLMENT_SPONSORS_URL: string = `/genesis-api/enrollment/sponsors/${ sponsorId }`;

        return this.http.get(ENROLLMENT_SPONSORS_URL);
    }
}