export type EnrollmentErrorType =
'MissingIds' |
'AlreadyEnrolled' |
'DuplicateRecords' |
'InformationMismatch' |
'InformationMissing' |
'EnrollmentFailed' |
'UsernameExists' |
'EmailExists';

export let EnrollmentError: any = {
    MissingIds: 'MissingIds',
    AlreadyEnrolled: 'AlreadyEnrolled',
    DuplicateRecords: 'DuplicateRecords',
    InformationMismatch: 'InformationMismatch',
    InformationMissing: 'InformationMissing',
    EnrollmentFailed: 'EnrollmentFailed',
    UsernameExists: 'UsernameExists',
    EmailExists: 'EmailExists'
}
