import { Component, forwardRef } from '@angular/core';
import { StateOfResidenceService } from '../../../shared/state-of-residence.service';
import { CountryService } from '../../../shared/country.service';
import { StateOfResidence } from '../../../shared/state-of-residence.class';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

@Component({
    selector: 'vpe-state-of-residence',
    templateUrl: './state-of-residence.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StateOfResidenceComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StateOfResidenceComponent),
            multi: true,
        }
    ]
})
export class StateOfResidenceComponent implements ControlValueAccessor, Validator {
    states: Array<StateOfResidence>;
    currentLanguage: string;
    currentState: StateOfResidence = null;
    isCountryUS: boolean = false;

    constructor(private statesService: StateOfResidenceService, private translateService: TranslateService, private countryService: CountryService) {
        countryService.getSelectedCountry().subscribe((country) => {
            if (_.get(country, 'countryCode', null) === 'US') {
                this.isCountryUS = true;
            } else {
                this.isCountryUS = false;
                this.currentState = null;
            }
            this.onChange();
        });

        translateService.onLangChange.subscribe(() => {
            this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
            this.statesService.getStates(this.currentLanguage).subscribe((states) => {
                this.states = states;
                if (this.currentState) {
                    this.currentState = _.find(this.states, {id: this.currentState.id});
                }
            });
        });
    }

    public writeValue(obj: any) {
        this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
        this.statesService.getStates(this.currentLanguage).subscribe((states) => {
            if (!this.states) {
                this.states = states;
            }
            let state: StateOfResidence;

            if (obj) {
                state = _.find(this.states, obj);
            }

            if (state !== this.currentState) {
                this.currentState = state;
                this.onChange();
            }
        });
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.propagateChange(this.currentState);
    }

    public onBlur() {
        this.propagateTouch(true);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (this.isCountryUS && !this.currentState) {
            return { 'required' : 'stateOfResidence' };
        }
        return null;
    }
}
