import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { BaseFormField } from '../../../../shared/flex-forms/base-form-field.class';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';
import { RegistrationDetails } from '../../../../shared/registration-details.class';

@Component({
    selector: 'reactive-confirmation-email-sent',
    templateUrl: './reactive-confirmation-email-sent.component.html',
    styleUrls: ['./reactive-confirmation-email-sent.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveConfirmationEmailSentComponent)
        }
    ]
})
export class ReactiveConfirmationEmailSentComponent extends BaseFormField implements OnInit, ControlValueAccessor {
    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;
    @Input() registrationDetails: RegistrationDetails;

    emailAddress: string;
    memberServicesEmail: string;

    constructor() {
        super();
    }

    writeValue(emailAddress: string): void {
        this.emailAddress = emailAddress;
    }

    ngOnInit(): void {
        this.emailAddress = this.field.initialValue;
        if (this.registrationDetails?.sponsorSettings?.supportEmail != null) {
            this.memberServicesEmail = this.registrationDetails.sponsorSettings.supportEmail;
        } else {
            this.memberServicesEmail = "support@virginpulse.com";
        }
    }
}
