import { Component, Input } from '@angular/core';
import { StateService, Transition } from "@uirouter/angular";
import { CurrentMemberService } from "../shared/current-member.service";
import { EligibleProduct } from "../shared/eligible-product";
import { AgreementRedirect } from '../shared/agreement-redirect.enum';
import { ValidationTransitionService } from '../shared/validation-transition.service';
import { ProductHelper } from '../shared/product-helper.class';

import * as _ from 'lodash';
import { EnrollmentGroup } from "../shared/enrollment-group.class";
import { EnrollmentGroupUtil } from "../shared/enrollment-group-util";

@Component({
    templateUrl: './product-select.component.html',
    styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent {

    redirect: AgreementRedirect;

    productList: Array<EligibleProduct>;

    @Input() enrollmentGroup: EnrollmentGroup;

    constructor(
        private currentMemberService: CurrentMemberService,
        private validationTransitionService: ValidationTransitionService,
        private transition: Transition,
        private state: StateService

    ) {
        this.productList = this.currentMemberService.memberEnrollmentData.eligibleProducts
            .filter(product => _.includes(ProductHelper.eligibleProducts, product.productCode));
    }

    goToAgreements(product: EligibleProduct) {
        if (!product || product.accepted) {
            return;
        }

        const products = this.currentMemberService.memberEnrollmentData.eligibleProducts;
        const next = this.validationTransitionService.getValidationTransition(this.transition.params(), products, product.productCode);
        this.state.go(next.target, next.params);
    }

    isDisabled() {
        return !_.some(this.productList, { accepted: true });
    }

    continue() {
        if (EnrollmentGroupUtil.isOpenEnrollment(this.enrollmentGroup) || EnrollmentGroupUtil.isEmailConfirmationRequired(this.enrollmentGroup.requireEmailConfirmation, this.currentMemberService.memberEnrollmentData.emailAddress)) {
            this.state.go('verify', this.transition.params());
        } else {
            this.state.go('signup', this.transition.params());
        }
    }

}
