<div>
    <span *ngIf="field.displayName" [id]="field.fieldName + '-label'" class="no-side-padding faux-groove-header4 faux-groove-label security-questions-header">
        {{field.displayName}}
    </span>

    <p class="note no-side-padding security-questions-description" translate="">enrollment.forms.SecurityQuestion.KeepAccountSafe</p>

    <div class="no-side-padding" *ngFor="let securityQuestion of securityQuestionsWithAnswers; let i = index">
        <div class="no-side-padding" [ngClass]="{'question-separator': i !== securityQuestionsWithAnswers.length - 1}">
            <reactive-security-question [id]="field.fieldName + '-input'"
                                        [questionFieldName]="field.fieldName + '-question-' + i"
                                        [questionLabel]="field.displayName"
                                        [questionDisabled]="field.editable === false"
                                        (questionValueChanged)="onQuestionChange(i, $event)"
                                        [answerFieldName]="field.fieldName + '-answer-' + i"
                                        [answerLabel]="CONSTANTS[i].ANSWER_LABEL | translate"
                                        [answerDisabled]="!securityQuestionsWithAnswers[i].securityQuestionId"
                                        (answerValueChanged)="onAnswerChange(i, $event)"
                                        (touched)="onTouch()"
                                        [data]="grooveOptions[i]"
                                        [isRequired]="isRequired">
            </reactive-security-question>
        </div>
    </div>
</div>
