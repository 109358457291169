import { EnrollmentGroup } from './enrollment-group.class';
import { Utils } from '../utils/Utils.class';

export class EnrollmentGroupUtil {
    static isOpenEnrollment(group: EnrollmentGroup): boolean {
        return group.enrollmentValidationRule === 'NoEligibilityFile'
            || group.enrollmentValidationRule === 'NoEligibilityFileWithOnlyBirthYear';
    }

    static isEmailConfirmationRequired(requiredConfirmation: boolean, email: string): boolean {
        return requiredConfirmation && !Utils.isNullOrUndefined(email);
    }
}
