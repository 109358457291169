import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BusinessUnit } from 'src/app/shared/business-unit.class';
import { Constants } from 'src/app/shared/constants.class';
import { BaseOrgHierarchyUnitComponent } from '../base-org-hierarchy-unit/base-org-hierarchy-unit.component';

@Component({
    selector: 'reactive-business-unit',
    templateUrl: './reactive-business-unit.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveBusinessUnitComponent),
            multi: true,
        }
    ]
})
export class ReactiveBusinessUnitComponent extends BaseOrgHierarchyUnitComponent<BusinessUnit> {
    type = Constants.COMPONENT_TYPE.BUSINESS_UNIT;
}
