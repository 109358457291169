<div>
    <button type="button"
            class="btn product-select"
            [ngClass]="{'vp-passport-background': product.name === 'VP Passport'}"
            *ngIf="product && !product.accepted"
            (click)="goToAgreement()">
        <img class="product-image" [src]="product.productImage" alt=""/>
        <div class="product-text">
            <span class="product-name">{{product.name}}</span>
            <span class="product-description">{{product.description}}</span>
        </div>
        <i class="fa fa-angle-right right-angle-icon"></i>
    </button>

    <div *ngIf="product?.accepted" class="product-select-accepted">
        <vpg-icon class="confirmation-checkmark-small" [className]="completeIconClassName"></vpg-icon>
        <div class="product-text">
            <span class="product-name-accepted">{{product.name}}</span>
            <span class="product-description-accepted">{{product.description}}</span>
        </div>
    </div>
</div>
