<div>
    <groove-input-field class="groove-wrapper"
                        [id]="field?.fieldName + '-input'"
                        [fieldName]="field?.fieldName"
                        [label]="field?.displayName"
                        (valueChanged)="onChange($event)"
                        (touched)="onTouch()"
                        [placeholder]="field?.placeholder"
                        [value]="dateOfBirth"
                        [type]="inputType"
                        [isRequired]="isRequired"
                        [customValidatorsWithMessages]="customValidatorsWithMessages">
    </groove-input-field>
    <vpe-age-consent-warning [birthdate]="dateOfBirth"
                             [id]="field.fieldName + '-age-consent-warning'">
    </vpe-age-consent-warning>
</div>
