<div class="form-group">
    <label for="timezone" translate="">
        enrollment.forms.timezone
    </label>
    <div>
        <select class="form-input timezone"
                id="timezone"
                (change)="onChange()"
                (blur)="onBlur()"
                [(ngModel)]="timezone">
            <option *ngFor="let tz of timezones"
                    [ngValue]="tz">
                {{ tz.description }}
            </option>
        </select>
    </div>
</div>
