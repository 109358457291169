import { Component, Input, forwardRef } from '@angular/core';
import {
    FormsModule,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

export const icons = {
    show: "assets/images/icon_show.svg",
    hide: "assets/images/icon_hide.svg",
    dot: "assets/images/icon_dot.svg",
    pass: "assets/images/Icon_pass.svg",
    fail: "assets/images/Icon_fail.svg"
}

@Component({
    selector: 'vpe-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordComponent),
            multi: true,
        }
    ]
})
export class PasswordComponent implements ControlValueAccessor, Validator {
    password: string;
    showPassword = false;
    passwordConfirm: string;
    touched = false;
    touchedConfirm = false;
    icons = icons;
    private passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%*()+\-_]).{8,50}$/;

    private passwordRuleRegex = {
        upperCase: /^(?=.*?[A-Z]).+$/,
        lowerCase: /^(?=.*?[a-z]).+$/,
        number: /^(?=.*?[0-9]).+$/,
        special: /^(?=.*?[#!@$%*()+\-_]).+$/,
        length: /^(?=.*?.).{8,50}$/
    };

    public isPasswordRuleSatisfied(rule: string) {
        if (this.password == undefined) {
            return false
        } else {
            return this.passwordRuleRegex[rule].test(this.password)
        }
    }

    public writeValue(obj: any) {
        if (obj) {
            this.password = obj.toString();
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.propagateChange(this.password);
    }

    public onBlur() {
        this.touched = true;
        this.propagateTouch(true);
    }

    public onConfirmBlur() {
        this.touchedConfirm = true;
    }

    public isValidPassword() {
        return this.passwordRegex.test(this.password);
    }

    public areEqualPasswords() {
        return this.password === this.passwordConfirm;
    }

    private getErrors() {

    }

    public toggleIcon(): boolean {
      this.showPassword = !this.showPassword;
      return true;
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.isValidPassword()) {
            return { 'invalid' : 'password' };
        }

        if (!this.areEqualPasswords()) {
            return { 'inequal' : 'password' };
        }

        return null;
    }
}
