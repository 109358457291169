import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AsyncSubject, Observable, ReplaySubject } from 'rxjs';

import { Company } from './company.class';
import { BusinessUnit } from './business-unit.class';
import { Office } from './office.class';
import { Constants } from './constants.class';

@Injectable()
export class SponsorCompaniesService {
  private subject: AsyncSubject<Array<Company>> = new AsyncSubject<Array<Company>>();
  private currentSponsorId: number;
  private cachedCompanies: Array<Company>;

  private cachedEnrollmentGroupExternalId: string = null;
  private cachedEnrollmentGroupCompanies: Array<Company> = null;

  businessUnits: ReplaySubject<Array<BusinessUnit>> = new ReplaySubject(1);
  offices: ReplaySubject<Array<Office>> = new ReplaySubject(1);

  constructor(private http: HttpClient) { }

  getSponsorCompanies(sponsorId: number): Observable<Array<Company>> {
    let SPONSOR_COMPANIES_URL: string = `/enrollment-api/sponsors/${sponsorId}/companies`;

    if (sponsorId === this.currentSponsorId) {
      return this.subject;
    }

    this.currentSponsorId = sponsorId;
    this.subject = new AsyncSubject();

    this.http.get<Company[]>(SPONSOR_COMPANIES_URL).subscribe((companies) => {
      this.subject.next(companies);
      this.subject.complete();
    });

    return this.subject;
  }

  async getSponsorCompaniesByEnrollmentGroup(enrollmentGroupExternalId: string): Promise<Array<Company>> {
    let url = `/enrollment-api/enrollment-groups/${enrollmentGroupExternalId}/companies`;

    if (enrollmentGroupExternalId === this.cachedEnrollmentGroupExternalId) {
      return this.cachedEnrollmentGroupCompanies;
    }

    let companies = await this.http.get<Company[]>(url).toPromise();
    this.cachedEnrollmentGroupExternalId = enrollmentGroupExternalId;
    this.cachedEnrollmentGroupCompanies = companies;
    return companies;
  }

  getSponsorCompaniesPromise(sponsorId: number): Promise<Array<Company>> {
    let SPONSOR_COMPANIES_URL: string = `/enrollment-api/sponsors/${sponsorId}/companies`;

    if (sponsorId === this.currentSponsorId) {
      return Promise.resolve(this.cachedCompanies);
    }

    this.currentSponsorId = sponsorId;

    return new Promise((resolve, reject) => {
      this.http.get<Company[]>(SPONSOR_COMPANIES_URL).subscribe((companies: Company[]) => {
        this.cachedCompanies = companies;
        resolve(companies);
      }, (err) => {
        reject(err);
      });
    });
  }

  updateOrgHierarchy(company: Company) {
    this.businessUnits.next(company?.businessUnits);
    this.offices.next(company?.offices);
  }

  getSubjectByType(type: string): ReplaySubject<Array<any>> {
    switch (type) {
      case Constants.COMPONENT_TYPE.BUSINESS_UNIT:
        return this.businessUnits;

      case Constants.COMPONENT_TYPE.OFFICE:
        return this.offices;

      default:
        return null;
    }
  }
}
