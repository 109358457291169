<div>
    <div class="enroll-success-container" *ngIf="!isAbbreviatedEnrollment">
        <vpg-icon class="confirmation-checkmark" [className]="completeIconClassName"></vpg-icon>
        <button
            vpg-button
            class="take-me-there-button"
            [id]="'take-me-there-button'"
            [textContent]="btnlabel"
            [attr.ariaLabel]="btnAriaLabel"
            (click)="initiateLogin()">
        </button>
    </div>
    <div class="login-pending-spinner"
         *ngIf="isAbbreviatedEnrollment"
         id="login-pending-spinner">
        <img src="assets/images/spinnerLoading.gif">
    </div>
</div>
