import { Component, Input, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';
import { CurrentMemberService } from "../../../shared/current-member.service";

@Component({
    selector: 'vpe-custom-enrollment-data',
    templateUrl: './custom-enrollment-data.component.html',

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomEnrollmentDataComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CustomEnrollmentDataComponent),
            multi: true,
        }
    ]
})

export class CustomEnrollmentDataComponent implements ControlValueAccessor, Validator {
    _customEnrollmentDataField: string;
    customEnrollmentData: string;

    @Input() set customEnrollmentDataField(customEnrollmentDataField: string) {
        this._customEnrollmentDataField = customEnrollmentDataField
    };

    constructor(
        public currentMemberService: CurrentMemberService
    ) { }

    public writeValue(obj: any) {
        if (obj) {
            this.customEnrollmentData = obj.toString();
            this.currentMemberService.memberEnrollmentData.customEnrollmentData = this.customEnrollmentData;
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.currentMemberService.memberEnrollmentData.customEnrollmentData = this.customEnrollmentData;
        this.propagateChange(this.customEnrollmentData)
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        return this.getError();
    }

    public getError(): {[key: string]: any} {
        if (!this.customEnrollmentData) {
            return { 'required': 'customEnrollmentData' };
        }

        if (this.customEnrollmentData.length > 100) {
            return { 'tooLong' : 'customEnrollmentData' };
        }

        return null;
    }
}
