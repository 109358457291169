import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ie-warning-banner',
    templateUrl: './ie-warning-banner.component.html',
    styleUrls: ['./ie-warning-banner.component.scss']
})
export class IeWarningBannerComponent implements OnInit {
    shouldShowBanner: boolean;
    hideWarningBanner = 'hideWarningBanner';

    constructor() { }

    isIE() {
        return window.navigator.userAgent.includes('MSIE') || window.navigator.userAgent.includes('Trident');
    }

    ngOnInit() {
        this.shouldShowBanner = sessionStorage.getItem(this.hideWarningBanner) === null && this.isIE()
    }

    closeWarningBanner() {
        sessionStorage.setItem(this.hideWarningBanner, 'true');
        this.shouldShowBanner = false;
    }
}
