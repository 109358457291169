import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';

import { ValidationPageModel } from '../../../analytics-tracking/domain/validation-page.model';
import { EnrollmentError } from '../../../shared/enrollment-error-type.enum';
import { BaseValidationForm } from '../../shared/base-validation-form.class';

@Component({
    selector: 'lastname-dateofbirth-gender-validation-form',
    templateUrl: './lastname-dateofbirth-gender-validation-form.component.html',
    styleUrls: ['../../validation.component.scss']
})
export class LastNameDateOfBirthGenderValidationFormComponent extends BaseValidationForm implements OnInit {

    @Input() set memberData(memberData) {
        if (memberData) {
            this.member = memberData;
            this.member.enrollmentGroupId = Number(this.transition.params().enrollmentGroupId);

            this.validationForm = this.fb.group({
                firstName: [this.member.firstName, Validators.required],
                lastName: [this.member.lastName, Validators.required],
                birthdate: [this.member.dateOfBirth, Validators.required],
                genderIdentity: [this.member.genderIdentity, Validators.required],
                country: [this.member.country, Validators.required],
                stateOfResidence: [this.member.stateOfResidence]
            });
        }
    }

    ngOnInit() {
        if (this.member.employeeId) {
            this.member.employeeId = null;
        }

        super.ngOnInit();
    }

    onSubmit() {
        if (!this.sponsorSettings) {
            return;
        }

        this.member.sponsorId = this.sponsorSettings.sponsorId;
        this.member.legacySponsorId = this.sponsorSettings.legacySponsorId;
        this.loadingValidation = true;

        this.sponsorValidationService.validateMemberResolved(this.member)
            .subscribe((result) => {
                this.member = result;
                this.currentMemberService.memberEnrollmentData = result;
                this.saveError = null;

                const transition = this.validationTransitionService.getValidationTransition(
                    this.transition.params(), this.currentMemberService.memberEnrollmentData.eligibleProducts);

                this.state.go(transition.target, transition.params);
                const validationPageModel: ValidationPageModel = {
                    sponsor_id: this.sponsorSettings.sponsorId,
                    sponsor_name: this.sponsorSettings.sponsorName,
                    validation_rule: _.lowerCase(this._enrollmentGroup.enrollmentValidationRule),
                    country: _.lowerCase(this.member.country),
                    external_member_id: this.member.externalId
                };
                this.analyticsTrackingCommandsService.trackSignUpDemographicInfoComplete(
                    validationPageModel,
                    this.sponsorSettings.excludeMixpanelTracking
                );
            },
        (error) => {
            this.loadingValidation = false;
            if (error === EnrollmentError.DuplicateRecords) {
                this.currentMemberService.memberEnrollmentData = this.member;
                this.state.go('identify', this.transition.params());
            } else {
                this.saveError = error;
            }
        });
    }
}
