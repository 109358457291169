import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GenesisApiLegacySponsorsService {

    constructor(private http: HttpClient) {}

    getSponsorInfoByLegacySponsorId(legacySponsorId) {
        let LEGACY_SPONSORS_URL: string = `/genesis-api/legacy-sponsors/${ legacySponsorId }`;

        return this.http.get(LEGACY_SPONSORS_URL);
    }
}