// Angular core
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TransitionService, UIRouterModule } from '@uirouter/angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieModule, CookieService } from 'ngx-cookie';
import { VpgrooveModule } from '@engineering/genesis-frontend';
// Routing
import { routerSetup, states } from './routing.config';

// Services
import { environment } from '../environments/environment';
import { AnalyticsTrackingService } from './analytics-tracking/analyticsTracking.service';
import { AnalyticsTrackingCommandsService } from './analytics-tracking/analyticsTrackingCommands.service';
import { ErrorBannerService } from './header/error-banner/error-banner.service';
import { MainCelebrationTriggerService } from './main-celebration-trigger.service';
import { AgreementsService } from './shared/agreements.service';
import { CountryService } from './shared/country.service';
import { CurrentMemberService } from './shared/current-member.service';
import { EligibleProductService } from './shared/eligible-product.service';
import { EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService } from './shared/enrollment-api/services/sponsors/enrollment-groups/eligibility/tokens/enrollment-api-sponsors-enrollment-groups-eligibility-tokens.service';
import { EnrollmentApiSponsorsEnrollmentGroupsService } from './shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-api-sponsors-enrollment-groups.service';
import { EnrollmentGroupsSettingsService } from './shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-groups-settings.service';
import { SponsorStateProvincesService } from './shared/enrollment-api/services/sponsors/sponsor-state-provinces.service';
import { EnrollmentErrorService } from './shared/enrollment-error.service';
import { GenderIdentityService } from './shared/gender-identity.service';
import { GenesisApiEnrollmentSponsorsService } from './shared/genesis-api/services/genesis-api-enrollment-sponsors.service';
import { GenesisApiLegacySponsorsService } from './shared/genesis-api/services/genesis-api-legacy-sponsors.service';
import { GenesisSponsorService } from './shared/genesis-sponsor.service';
import { LanguagesService } from './shared/languages.service';
import { MemberEligibilityCreationService } from './shared/member-eligibility-creation.service';
import { MemberEligibilityUpdateService } from './shared/member-eligibility-update.service';
import { PageNameService } from './shared/page-name.service';
import { SecurityQuestionsService } from './shared/security-questions.service';
import { SponsorCompaniesService } from './shared/sponsor-companies.service';
import { SponsorEnrollmentGroupsService } from './shared/sponsor-enrollment-groups.service';
import { SponsorSettingsService } from './shared/sponsor-settings.service';
import { StateOfResidenceService } from './shared/state-of-residence.service';
import { TimezonesService } from './shared/timezones.service';
import { ValidationTransitionService } from './shared/validation-transition.service';
import { EnrollmentService } from './signup/enrollment.service';
import { SponsorValidationService } from './validation/sponsor-validation.service';
import { PasswordService } from './shared/password.service';

// Authentication
import { KeycloakLoginService } from './shared/authentication/keycloak-login.service';
import { WolverineLoginService } from './shared/authentication/wolverine-login.service';
import { AudioFingerprintService } from './shared/authentication/audioFingerprint.service';
import { EnrollmentFingerprintService } from './shared/authentication/fingerprint.service';

// Application
import { AgreementsComponent } from './agreements/agreements.component';
import { AppComponent } from './app.component';
import { ClosedRegistrationComponent } from './closed-registration/closed-registration.component';
import { FooterComponent } from './footer/footer.component';
import { ErrorBannerComponent } from './header/error-banner/error-banner.component';
import { HeaderComponent } from './header/header.component';
import { IdentifyComponent } from './identify/identify.component';
import { ProductSelectComponent } from './productselect/product-select.component'
import { EnrollmentHttpInterceptor } from './shared/interceptors/enrollmentHttpInterceptor.class';
import { SignUpComponent } from './signup/signup.component';
import { EnrollmentGroupSelectionComponent } from './sponsors/groups/enrollment-group-selection.component';
import { BubbleCelebrationComponent } from './utils/bubble-celebration/bubble-celebration.component';
import { SwitchCasesDirective } from './utils/jr-switch-cases.directive';
import { ValidationComponent } from './validation/validation.component';
import { VerifyComponent } from './verify/verify.component';
import { IeWarningBannerComponent } from './ie-warning-banner/ie-warning-banner.component';

// Form Variations
import { BasicSignUpFormComponent } from './signup/forms/basic-signup-form/basic-signup-form.component';
import { EmployeeIdLastNameDateOfBirthSignUpFormComponent } from './signup/forms/employeeid-lastname-dateofbirth-signup-form/employeeid-lastname-dateofbirth-signup-form.component';
import { EmployeeIdSignUpFormComponent } from './signup/forms/employeeid-signup-form/employeeid-signup-form.component';
import { LastNameDateOfBirthGenderSignUpFormComponent } from './signup/forms/lastname-dateofbirth-gender-signup-form/lastname-dateofbirth-gender-signup-form.component';
import { LastNameDateOfBirthStateSignUpFormComponent } from './signup/forms/lastname-dateofbirth-state-signup-form/lastname-dateofbirth-state-signup-form.component';
import { NoEligibilityFileSignupFormComponent } from './signup/forms/no-eligibility-file-signup-form/no-eligibility-file-signup-form.component';
import { FormWrapperComponent } from './utils/form-wrapper/form-wrapper.component';
import { BasicValidationFormComponent } from './validation/forms/basic-validation-form/basic-validation-form.component';
import { EmployeeIdLastNameDateOfBirthValidationFormComponent } from './validation/forms/employeeid-lastname-dateofbirth-validation-form/employeeid-lastname-dateofbirth-validation-form.component';
import { EmployeeIdValidationFormComponent } from './validation/forms/employeeid-validation-form/employeeid-validation-form.component';
import { EnrollmentCodeValidationFormComponent } from './validation/forms/enrollment-code-validation-form/enrollment-code-validation-form.component';
import { EnrollmentCodeLastNameDateOfBirthValidationFormComponent } from './validation/forms/enrollmentcode-lastname-dateofbirth-validation-form/enrollmentcode-lastname-dateofbirth-validation-form.component';
import { FirstNameLastNameDateOfBirthValidationFormComponent } from './validation/forms/firstname-lastname-dateofbirth-validation-form/firstname-lastname-dateofbirth-validation-form.component';
import { LastNameDateOfBirthGenderValidationFormComponent } from './validation/forms/lastname-dateofbirth-gender-validation-form/lastname-dateofbirth-gender-validation-form.component';
import { LastNameDateOfBirthStateValidationFormComponent } from './validation/forms/lastname-dateofbirth-state-validation-form/lastname-dateofbirth-state-validation-form.component';
import { NoEligibilityFileBirthYearOnlyValidationFormComponent } from './validation/forms/no-eligibility-file-birth-year-only-validation-form/no-eligibility-file-birth-year-only-validation-form.component';
import { NoEligibilityFileValidationFormComponent } from './validation/forms/no-eligibility-file-validation-form/no-eligibility-file-validation-form.component';
import { PartnerCodeValidationFormComponent } from './validation/forms/partner-code-validation-form/partner-code-validation-form.component';

// Custom Form Controls
import { RegistrationClosedAgainComponent } from './closed-registration/registration-closed-again.component';
import { AgeConsentWarningComponent } from './utils/forms/age-consent-warning/age-consent-warning.component';
import { BirthdateComponent } from './utils/forms/birthdate/birthdate.component';
import { BirthyearComponent } from './utils/forms/birthyear/birthyear.component';
import { CountryComponent } from './utils/forms/country/country.component';
import { EmailComponent } from './utils/forms/email/email.component';
import { EmployeeIdComponent } from './utils/forms/employee-id/employee-id.component';
import { EnrollmentCodeComponent } from './utils/forms/enrollment-code/enrollment-code.component';
import { GenderIdentityComponent } from './utils/forms/gender/gender-identity.component';
import { OrgHierarchyComponent } from './utils/forms/org-hierarchy/org-hierarchy.component';
import { PartnerCodeComponent } from './utils/forms/partner-code/partner-code.component';
import { PasswordComponent } from './utils/forms/password/password.component';
import { PhoneNumberComponent } from './utils/forms/phonenumber/phonenumber.component';
import { SecurityQuestionComponent } from './utils/forms/security-questions/security-question.component';
import { StateOfResidenceComponent } from './utils/forms/state-of-residence/state-of-residence.component';
import { StateProvinceComponent } from './utils/forms/state-province/state-province.component';
import { TimezoneComponent } from './utils/forms/timezone/timezone.component';
import { UsernameComponent } from './utils/forms/username/username.component';
import { WaitlistCompanyCapComponent } from './waitlist/waitlist-cap-type/company-cap/waitlist-company-cap.component';
import { WaitlistSponsorCapComponent } from './waitlist/waitlist-cap-type/sponsor-cap/waitlist-sponsor-cap.component';
import { WaitlistCompanyCapFormComponent } from './waitlist/waitlist-form/company-cap/waitlist-company-cap-form.component';
import { WaitlistSponsorCapFormComponent } from './waitlist/waitlist-form/sponsor-cap/waitlist-sponsor-cap-form.component';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { MaintenanceRegistrationComponent } from './maintenance-registration/maintenance-registration.component';
import { ProductBoxComponent } from './productselect/product-box/product-box.component';
import { CustomEnrollmentDataComponent} from './utils/forms/custom-enrollment-data/custom-enrollment-data.component';
import { FlexFormFieldComponent } from './flex-forms/flex-form-field/flex-form-field.component';
import { FlexFormComponent } from './flex-forms/flex-form/flex-form.component';
import { FlexFormPageComponent } from './flex-forms/flex-form-page/flex-form-page.component';
import { FlexFormService } from './shared/flex-forms/flex-form.service';
import { BaseOrgHierarchyUnitComponent } from './utils/forms/reactive/base-org-hierarchy-unit/base-org-hierarchy-unit.component';
import { ReactiveBusinessUnitComponent } from './utils/forms/reactive/reactive-business-unit/reactive-business-unit.component';
import { ReactiveOfficeComponent } from './utils/forms/reactive/reactive-office/reactive-office.component';
import { ReactiveStateProvinceComponent } from './utils/forms/reactive/reactive-state-province/reactive-state-province.component';
import { ReactiveCountryComponent } from './utils/forms/reactive/reactive-country/reactive-country.component';
import { ReactiveCompanyComponent } from './utils/forms/reactive/reactive-company/reactive-company.component';
import { ReactiveDropdownComponent } from './utils/forms/reactive/reactive-dropdown/reactive-dropdown.component';
import { ReactiveStateOfResidenceComponent } from './utils/forms/reactive/reactive-state-of-residence/reactive-state-of-residence.component';
import { ReactiveEmailAddressComponent } from './utils/forms/reactive/reactive-email-address/reactive-email-address.component';
import { ReactiveDateOfBirthComponent } from './utils/forms/reactive/reactive-date-of-birth/reactive-date-of-birth.component';
import { ReactiveBirthYearComponent } from './utils/forms/reactive/reactive-birth-year/reactive-birth-year.component';
import { ReactiveConfirmationEmailSentComponent } from './utils/forms/reactive/reactive-confirmation-email-sent/reactive-confirmation-email-sent.component';
import { ReactivePasswordComponent } from './utils/forms/reactive/reactive-password/reactive-password.component';
import { ReactivePhoneNumberComponent } from './utils/forms/reactive/reactive-phone-number/reactive-phone-number.component';
import { ReactiveTextboxComponent } from './utils/forms/reactive/reactive-textbox/reactive-textbox.component';
import { ReactiveUsernameComponent } from './utils/forms/reactive/reactive-username/reactive-username.component';
import { ReactiveAgreementCheckboxComponent } from './utils/forms/reactive/reactive-agreement-checkbox/reactive-agreement-checkbox.component';
import { ReactiveSecurityQuestionsComponent } from './utils/forms/reactive/reactive-security-questions/reactive-security-questions.component';
import { ReactiveEnrollmentSuccessConfirmationComponent } from './utils/forms/reactive/reactive-enrollment-success-confirmation/reactive-enrollment-success-confirmation.component';
import { ModalComponent } from './utils/forms/modal/modal.component';
import { FlexFormHeaderComponent } from './flex-forms/flex-form-header/flex-form-header.component';
import { LanguageComponent } from './utils/forms/language/language.component';
import { ProgressBarComponent } from './utils/forms/progress-bar/progress-bar.component';
import { ReactivePhoneNumberGrooveComponent } from './utils/forms/reactive/reactive-phone-number-groove/reactive-phone-number-groove.component';
import { GenericGrooveWrapperComponent } from './utils/forms/groove/generic-groove-wrapper/generic-groove-wrapper.component';
import { GrooveInputFieldComponent } from './utils/forms/groove/groove-input-field/groove-input-field.component';
import { GroovePasswordFieldComponent } from './utils/forms/groove/groove-password-field/groove-password-field.component';
import { GroovePhoneNumberFieldComponent } from './utils/forms/groove/groove-phone-number-field/groove-phone-number-field.component';
import { GrooveSearchFieldComponent } from './utils/forms/groove/groove-search-field/groove-search-field.component';
import { GrooveCheckboxFieldComponent } from './utils/forms/groove/groove-checkbox-field/groove-checkbox-field.component';
import { ReactiveAppDownloadComponent } from './utils/forms/reactive/reactive-app-download/reactive-app-download.component';
import { GrooveDropdownFieldComponent } from './utils/forms/groove/groove-dropdown-field/groove-dropdown-field.component';
import { ReactiveWaitlistConfirmationComponent } from './utils/forms/reactive/reactive-waitlist-confirmation/reactive-waitlist-confirmation.component';
import { ReactiveProductSelectComponent } from './utils/forms/reactive/reactive-product-select/reactive-product-select.component';
import { ReactiveSponsorSearchComponent } from './utils/forms/reactive/reactive-sponsor-search/reactive-sponsor-search.component';
import { FlexFormEnrollmentGroupsService } from './shared/flex-forms/flex-form-enrollment-groups.service';
import { ReactiveSecurityQuestionComponent } from './utils/forms/reactive/reactive-security-questions/reactive-security-question/reactive-security-question.component';
import { AgreementModalComponent } from './utils/forms/reactive/reactive-agreement-checkbox/agreement-modal/agreement-modal.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeFingerprint(
    fingerprintService: EnrollmentFingerprintService
) {
    return () => fingerprintService.saveCustomFingerprint();
}

export function initializeStorageListener(
    fingerprintService: EnrollmentFingerprintService
) {
    return () => fingerprintService.initStorageListener();
}

@NgModule({
    declarations: [
        AppComponent,
        ValidationComponent,
        HeaderComponent,
        FooterComponent,
        SignUpComponent,
        EnrollmentGroupSelectionComponent,
        VerifyComponent,
        BubbleCelebrationComponent,
        ErrorBannerComponent,
        IdentifyComponent,
        AgreementsComponent,
        ClosedRegistrationComponent,
        SwitchCasesDirective,
        ProductSelectComponent,
        IeWarningBannerComponent,

        FormWrapperComponent,
        BasicSignUpFormComponent,
        LastNameDateOfBirthGenderSignUpFormComponent,
        LastNameDateOfBirthStateSignUpFormComponent,
        EmployeeIdLastNameDateOfBirthSignUpFormComponent,
        NoEligibilityFileSignupFormComponent,
        BasicValidationFormComponent,
        LastNameDateOfBirthGenderValidationFormComponent,
        LastNameDateOfBirthStateValidationFormComponent,
        EmployeeIdLastNameDateOfBirthValidationFormComponent,
        NoEligibilityFileValidationFormComponent,
        NoEligibilityFileBirthYearOnlyValidationFormComponent,
        EnrollmentCodeValidationFormComponent,
        EnrollmentCodeLastNameDateOfBirthValidationFormComponent,
        FirstNameLastNameDateOfBirthValidationFormComponent,
        EmployeeIdValidationFormComponent,
        EmployeeIdSignUpFormComponent,
        PartnerCodeValidationFormComponent,

        AgeConsentWarningComponent,
        BirthdateComponent,
        BirthyearComponent,
        EmployeeIdComponent,
        EnrollmentCodeComponent,
        PasswordComponent,
        TimezoneComponent,
        GenderIdentityComponent,
        StateProvinceComponent,
        EmailComponent,
        OrgHierarchyComponent,
        UsernameComponent,
        SecurityQuestionComponent,
        PartnerCodeComponent,
        PhoneNumberComponent,
        CountryComponent,
        StateOfResidenceComponent,
        WaitlistComponent,
        MaintenanceRegistrationComponent,
        ProductBoxComponent,
        WaitlistCompanyCapComponent,
        WaitlistSponsorCapComponent,
        WaitlistSponsorCapFormComponent,
        WaitlistCompanyCapFormComponent,
        RegistrationClosedAgainComponent,
        CustomEnrollmentDataComponent,
        FlexFormFieldComponent,
        FlexFormComponent,
        FlexFormPageComponent,
        ProgressBarComponent,
        BaseOrgHierarchyUnitComponent,
        ReactiveBusinessUnitComponent,
        ReactiveOfficeComponent,
        ReactiveStateProvinceComponent,
        ReactiveCountryComponent,
        ReactiveCompanyComponent,
        ReactiveDropdownComponent,
        ReactiveStateOfResidenceComponent,
        ReactiveEmailAddressComponent,
        ReactiveDateOfBirthComponent,
        ReactiveBirthYearComponent,
        ReactiveConfirmationEmailSentComponent,
        ReactivePasswordComponent,
        ReactivePhoneNumberComponent,
        ReactiveTextboxComponent,
        ReactiveUsernameComponent,
        ReactiveAgreementCheckboxComponent,
        ReactiveSecurityQuestionsComponent,
        ReactiveEnrollmentSuccessConfirmationComponent,
        ReactiveWaitlistConfirmationComponent,
        ModalComponent,
        FlexFormHeaderComponent,
        LanguageComponent,
        ReactivePhoneNumberGrooveComponent,
        GenericGrooveWrapperComponent,
        GrooveInputFieldComponent,
        GroovePasswordFieldComponent,
        GroovePhoneNumberFieldComponent,
        GrooveSearchFieldComponent,
        GrooveCheckboxFieldComponent,
        ReactiveAppDownloadComponent,
        GrooveDropdownFieldComponent,
        ReactiveProductSelectComponent,
        ReactiveSponsorSearchComponent,
        ReactiveSecurityQuestionComponent,
        AgreementModalComponent
    ],
    imports: [
        UIRouterModule.forRoot({
            states: states,
            useHash: true,
            config: routerSetup
        }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        CookieModule.forRoot(),
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        TrimValueAccessorModule,
        Ng2TelInputModule,
        VpgrooveModule
    ],
    providers: [
        CookieService,
        CurrentMemberService,
        EnrollmentService,
        LanguagesService,
        GenderIdentityService,
        TimezonesService,
        SponsorSettingsService,
        SponsorValidationService,
        MainCelebrationTriggerService,
        SponsorCompaniesService,
        GenesisSponsorService,
        ErrorBannerService,
        SponsorEnrollmentGroupsService,
        GenesisApiLegacySponsorsService,
        GenesisApiEnrollmentSponsorsService,
        EnrollmentApiSponsorsEnrollmentGroupsService,
        EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService,
        AgreementsService,
        EnrollmentErrorService,
        KeycloakLoginService,
        WolverineLoginService,
        MemberEligibilityCreationService,
        MemberEligibilityUpdateService,
        SecurityQuestionsService,
        EnrollmentGroupsSettingsService,
        SponsorStateProvincesService,
        CountryService,
        ValidationTransitionService,
        StateOfResidenceService,
        AnalyticsTrackingService,
        AnalyticsTrackingCommandsService,
        AudioFingerprintService,
        EnrollmentFingerprintService,
        EligibleProductService,
        PageNameService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EnrollmentHttpInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeFingerprint,
            deps: [EnrollmentFingerprintService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeStorageListener,
            deps: [EnrollmentFingerprintService],
            multi: true
        },
        FlexFormService,
        PasswordService,
        FlexFormEnrollmentGroupsService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    readonly languageCodePrefixLength = 2;
    constructor(
        translateService: TranslateService,
        languagesService: LanguagesService,
        currentMemberService: CurrentMemberService,
        countryService: CountryService,
        private transitionService: TransitionService,
        private sponsorSettingsService: SponsorSettingsService,
        private pageNameService: PageNameService
    ) {
        this.transitionService.onSuccess({}, (transition) => {
            this.pageNameService.setPageName(transition?.to()?.name || '');

            if (transition.params().sponsorId) {
                this.sponsorSettingsService.getRegistrationDetailsBySponsorId(transition.params().sponsorId).subscribe();
            } else if (transition.params().enrollmentGroupExternalId) {
                this.sponsorSettingsService.getRegistrationDetailsByEnrollmentGroupExternalId(
                    transition.params().enrollmentGroupExternalId
                ).subscribe();
            } else if (transition.params().sponsorExternalId) {
                this.sponsorSettingsService.getRegistrationDetailsBySponsorGuid(
                    transition.params().sponsorExternalId
                ).subscribe();
            }
        });

        translateService.setDefaultLang(environment.defaultLanguage);
        translateService.onLangChange.subscribe((event) => {
            currentMemberService.memberEnrollmentData.language = event.lang;
            moment.locale(event.lang);
        });
        countryService.setSelectedCountry(null);

        languagesService.getLanguages().subscribe((languages) => {
            let defaultLanguage: string = translateService.getBrowserCultureLang();

            if (defaultLanguage && _.some(languages, { 'code': defaultLanguage })) {
                translateService.use(defaultLanguage);
            } else if (defaultLanguage.length === this.languageCodePrefixLength) {
                let foundLanguage = _.find(languages, function (item) {
                    return _.startsWith(item.code, defaultLanguage);
                });

                if (foundLanguage) {
                    defaultLanguage = foundLanguage.code;
                    translateService.use(defaultLanguage);
                }
            }
            languagesService.setDefaultLanguageStatus(true)
        });

        AnalyticsTrackingService.init();
    }
}
