import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SponsorStateProvincesService {
    constructor(private http: HttpClient) { }

    getSponsorStatesAndProvinces(sponsorId) {
        return this.http.get<string[]>(`/enrollment-api/sponsors/${sponsorId}/stateProvinces`);
    }
}
