<div *ngIf="_sponsorSettings">
    <div *ngIf="!waitlistFormComplete">
        <div class="waitlist-header">
            <h1 class="page-title" translate>enrollment.waitlist.claimYourSpot</h1>
            <p class="page-text" translate>enrollment.waitlist.joinTheWaitlist</p>
        </div>
        <form-wrapper>
            <waitlist-sponsor-cap-form [sponsorSettings]="_sponsorSettings"></waitlist-sponsor-cap-form>
        </form-wrapper>
    </div>
    <div *ngIf="waitlistFormComplete">
        <div class="waitlist-header">
            <h1 class="page-title" translate>enrollment.waitlist.youreOnTheList</h1>
            <p class="page-text" translate>enrollment.waitlist.weveGotYouOnTheWaitlist</p>
        </div>
    </div>
</div>
