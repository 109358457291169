import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
import { Constants } from 'src/app/shared/constants.class';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { SponsorValidationService } from 'src/app/validation/sponsor-validation.service';

@Component({
    selector: 'reactive-email-address',
    templateUrl: './reactive-email-address.component.html',
    styleUrls: ['./reactive-email-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveEmailAddressComponent),
            multi: true,
        }
    ]
})
export class ReactiveEmailAddressComponent extends BaseFormField implements ControlValueAccessor {

    _field: FlexFormField;

    emailAddress: string = '';
    emailConfirmation: string = '';

    errors: ValidationErrors = {};
    isEmailValid: boolean = false;
    isValidationPending: boolean = false;
    emailConfirmationTouched = false;

    debouncedValidationFn = _.debounce((email: string) => {
        this.validateEmail(email);
    }, 1000);

    @Input() enrollmentGroupExternalId: string;

    @Input()
    get field() {
        return this._field;
    }

    set field(newField: FlexFormField) {
        this._field = newField;

        if (this.field?.initialValue) {
            this.emailAddress = this.field?.initialValue;
        }
    }

    @Input() form: UntypedFormGroup;

    get fieldErrors(): ValidationErrors {
        return this.form?.controls[this.field?.fieldName]?.errors ?? {};
    }

    get VALIDATORS() {
        return Constants.VALIDATORS;
    }

    constructor(private sponsorValidationService: SponsorValidationService) {
        super();
    }

    onEmailChange(newEmailAddress: string) {
        this.emailAddress = newEmailAddress.trim();
        this.propagateChange(newEmailAddress);
        this.debouncedValidationFn(newEmailAddress);
    }

    onConfirmationChange(newConfirmation: string) {
        this.emailConfirmation = newConfirmation.trim();
        this.updateErrors();
    }

    writeValue(newEmailAddress: string) {
        if (newEmailAddress) {
            this.emailAddress = newEmailAddress;
        }
    }

    onEmailTouch() {
        this.propagateTouch();
    }

    onConfirmationTouch() {
        this.emailConfirmationTouched = true;
        this.propagateTouch();
    }

    validateEmail(emailAddress: string) {
        this.isValidationPending = true;

        this.sponsorValidationService.validateEmailAddress(
            this.enrollmentGroupExternalId,
            emailAddress
        ).then((isEmailAddressValid: boolean) => {
            this.isEmailValid = isEmailAddressValid;
            this.updateErrors();
            this.isValidationPending = false;
        }, () => {
            this.isValidationPending = false;
        });
    }

    updateErrors() {
        let isEmailConfirmationValid = this.emailAddress?.localeCompare(this.emailConfirmation, undefined, { sensitivity: 'accent' }) === 0;
        let errors = {};

        if (!this.isEmailValid) {
            errors[Constants.VALIDATORS.EMAIL_ADDRESS] = true;
        }

        if (!isEmailConfirmationValid) {
            errors[Constants.VALIDATORS.CONFIRMATION] = true;
        }

        if (this.isEmailValid && isEmailConfirmationValid) {
            errors = null;
        }

        this.form?.controls[this.field?.fieldName]?.setErrors(errors);
    }
}
