import { Country } from '../country.class';
import { FlexFormDropdownData } from './flex-form-dropdown-data.class';
import { FlexFormAgreementData } from "./flex-form-agreement-data.class";
import { FlexFormEnrollmentSuccessRedirectData } from './flex-form-enrollment-success-redirect-data.class';
import { FlexFormProductData } from './flex-form-product-data.class';
import { FieldAttributes } from './field-attributes.interface';

export class FlexFormField {
    fieldName: string;
    displayName: string;
    required: boolean;
    editable: boolean;
    initialValue: any | undefined;
    componentType: string;
    displayOrder: number;
    columns: number;
    values: FlexFormDropdownData[] | Country[] | FlexFormAgreementData[] | FlexFormEnrollmentSuccessRedirectData[] | FlexFormProductData[];
    placeholder: string;
    description: string;
    sensitive: boolean;
    maxLength: number;
    fieldAttributes: FieldAttributes;
    identityApiProvider: string;

    constructor(options: {
        fieldName?: string,
        displayName?: string,
        required?: boolean,
        editable?: boolean,
        initialValue?: any,
        componentType?: string,
        displayOrder?: number,
        columns?: number,
        values?: FlexFormDropdownData[] | Country[] | FlexFormAgreementData[] | FlexFormEnrollmentSuccessRedirectData[] | FlexFormProductData[],
        placeholder?: string,
        description?: string,
        sensitive?: boolean,
        maxLength?: number,
        fieldAttributes?: FieldAttributes;
    }) {
        this.fieldName = options.fieldName || '';
        this.displayName = options.displayName || '';
        this.required = options.required || false;
        this.editable = !(options.editable === false)
        this.initialValue = options.initialValue;
        this.componentType = options.componentType || '';
        this.displayOrder = options.displayOrder == undefined ? 1 : options.displayOrder;
        this.columns = options.columns || null;
        this.values = options.values || null;
        this.placeholder = options.placeholder || '';
        this.description = options.description || '';
        this.sensitive = options.sensitive || false;
        this.maxLength = options.maxLength || 0;
        this.fieldAttributes = options.fieldAttributes || { type: 'NoFieldAttributes' }
    }
}
