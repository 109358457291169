import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { StateService, Transition } from "@uirouter/angular";

import { AnalyticsTrackingCommandsService } from '../../../analytics-tracking/analyticsTrackingCommands.service';
import { SignUpCompleteModel } from '../../../analytics-tracking/domain/sign-up-complete.model';
import { TrackingConstants } from '../../../analytics-tracking/domain/tracking-constants.enum';
import { EnrollmentService }  from '../../enrollment.service';
import { CurrentMemberService } from '../../../shared/current-member.service';
import { SponsorSettings } from '../../../shared/sponsor-settings.class';
import { EnrollmentErrorType, EnrollmentError } from '../../../shared/enrollment-error-type.enum';
import { environment } from '../../../../environments/environment';
import { PasswordResetChallenge } from '../../../shared/passwordResetChallenge.enum';

@Component({
    selector: 'no-eligibility-file-signup-form',
    templateUrl: './no-eligibility-file-signup-form.component.html',
    styleUrls: ['./no-eligibility-file-signup-form.component.scss']
})
export class NoEligibilityFileSignupFormComponent implements OnInit{
    enrollmentError = EnrollmentError;
    noEligibilityFileSignUpForm: UntypedFormGroup;
    saveError: EnrollmentErrorType = null;
    supportEmailLink: string = '';
    hasUsernameOnlyLogin: boolean = false;
    hasSecurityQuestions: boolean = false;
    loadingEnrollment: boolean = false;
    loginUrl: string = environment.genesis.url;

    @Input() sponsorSettings: SponsorSettings;
    @Input() customEnrollmentDataField: string;

    constructor(
        public currentMemberService: CurrentMemberService,
        private state: StateService,
        private transition: Transition,
        private enrollment: EnrollmentService,
        private fb: UntypedFormBuilder,
        private analyticsTrackingCommandsService: AnalyticsTrackingCommandsService
    ) {}

    ngOnInit() {
        if (this.noEligibilityFileSignUpForm) {
            return;
        }

        this.noEligibilityFileSignUpForm = this.fb.group({
            username: ['', Validators.required],
            password: [''],
            securityQuestion1: [''],
            securityQuestion2: [''],
            securityQuestion3: [''],
            customEnrollmentDataField: ['']
        });

        this.hasUsernameOnlyLogin = this.sponsorSettings.usernameOnlyLogin;
        if(!this.hasUsernameOnlyLogin) {
            this.noEligibilityFileSignUpForm.removeControl('username');
        }
        this.supportEmailLink = this.sponsorSettings.supportEmail;
        if (this.sponsorSettings.passwordResetChallenge === PasswordResetChallenge.SecurityQuestions) {
            this.hasSecurityQuestions = true;
        }
    }

    save() {
        this.loadingEnrollment = true;
        this.saveError = null;
        if (this.hasSecurityQuestions) {
            this.currentMemberService.memberEnrollmentData.securityQuestions = [
                this.currentMemberService.memberEnrollmentData.securityQuestion1,
                this.currentMemberService.memberEnrollmentData.securityQuestion2,
                this.currentMemberService.memberEnrollmentData.securityQuestion3
            ];
        }
        this.enrollment.enrollMemberResolved(this.currentMemberService.memberEnrollmentData).subscribe(() => {
            this.state.go('confirm', this.transition.params());
            this.trackSignUpComplete();
        },
        (error) => {
            this.saveError = error;
            this.loadingEnrollment = false;
        });
    }

    trackSignUpComplete() {
        const signUpCompleteModel: SignUpCompleteModel = {
            member_id: this.currentMemberService.memberEnrollmentData.externalId,
            enrollment_type: TrackingConstants.OpenEnrollment,
            sponsor_id: this.sponsorSettings.sponsorId,
            sponsor_name: this.sponsorSettings.sponsorName,
            is_primary: (this.currentMemberService.memberEnrollmentData.memberEligibilityType === TrackingConstants.Primary)
                ? true : false,
            is_international: (this.currentMemberService.memberEnrollmentData.country !== TrackingConstants.UnitedStates)
                ? true : false,
            year_of_birth: moment(this.currentMemberService.memberEnrollmentData.dateOfBirth).format('YYYY'),
            country: _.lowerCase(this.currentMemberService.memberEnrollmentData.country),
            gender: _.lowerCase(this.currentMemberService.memberEnrollmentData.gender)
        }
        this.analyticsTrackingCommandsService.trackSignUpComplete(
            signUpCompleteModel,
            this.sponsorSettings.excludeMixpanelTracking
        );
    }
}
