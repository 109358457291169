import { Component, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';

import * as createjs from 'createjs-module';

import { BubbleCelebrationParticle } from './bubble-celebration-particle';

@Component({
    selector: 'bubble-celebration',
    templateUrl: './bubble-celebration.component.html',
    styleUrls: ['./bubble-celebration.component.scss']
})
export class BubbleCelebrationComponent implements AfterViewInit {
    private readonly DEFAULT_WIDTH = 50;
    private readonly DEFAULT_HEIGHT = 50;
    private readonly DEFAULT_PARTICLE_COUNT = 25;
    private readonly DEFAULT_ANIMATION_DURATION = 1000;
    private readonly DEFAULT_COLORS =  [
        '#fecb45',
        '#fdb62b',
        '#fd9c27',
        '#fd7422',
        '#1692aa',
        '#64c4c7'
    ];

    @Input() width: number;
    @Input() height: number;
    @Input() celebrationWidth: number = this.DEFAULT_WIDTH;
    @Input() celebrationHeight: number = this.DEFAULT_HEIGHT;
    @Input() particleCount: number = this.DEFAULT_PARTICLE_COUNT;
    @Input() colors: Array<string> = this.DEFAULT_COLORS;
    @Input() animationDuration: number = this.DEFAULT_ANIMATION_DURATION;
    @Input() containerHeight: number;
    @Input() containerWidth: number;

    @ViewChild('bubbleAnimationArea', { static: true }) bubbleAnimationArea: ElementRef;
    @ViewChild('bubbleContentArea', { static: true }) bubbleContentArea: ElementRef;

    private _width: number;
    private _height: number;
    public _canvasWidth: number;
    public _canvasHeight: number;
    private stage: createjs.Stage;
    private hasAnimated: boolean;
    private celebrationParticles: Array<BubbleCelebrationParticle>;
    topOffset: number;
    leftOffset: number;

    ngAfterViewInit() {
        this.stage = new createjs.Stage(this.bubbleAnimationArea.nativeElement);
    }

    initAnimation() {
        let HALF = 0.5;
        let DOUBLE = 2;
        this._width = this.containerWidth || this.bubbleContentArea.nativeElement.clientWidth;
        this._height = this.containerHeight || this.bubbleContentArea.nativeElement.clientHeight;
        this._canvasWidth = this.width || (this._width + this.celebrationWidth * DOUBLE);
        this._canvasHeight = this._canvasHeight || (this._height + this.celebrationHeight * DOUBLE);
        this.topOffset = (this._height - this._canvasHeight) * HALF;
        this.leftOffset = (this._width - this._canvasWidth) * HALF;

        this.createParticles();
    }

    random(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    animate() {
        this.initAnimation();

        let minAnimationTimeMultiplier = 0.25;
        let alpha = 0.5;
        let fadeDuration = 50;

        let minAnimationTime = this.animationDuration * minAnimationTimeMultiplier;

        if (!this.hasAnimated) {
            createjs.Ticker.addEventListener('tick', this.stage);
            this.hasAnimated = true;
        }

        this.celebrationParticles.forEach((particle) => {
            this.initializeParticlePosition(particle);
            createjs.Tween.get(particle.element)
                .to({
                    x: particle.x,
                    y: particle.y,
                    alpha: alpha,
                    scaleX: 1,
                    scaleY: 1
                }, this.random(minAnimationTime, this.animationDuration), createjs.Ease.sineOut)
                .to({alpha: 0}, fadeDuration, createjs.Ease.linear);
        });
    }

    createParticles() {
        let FRAMES_PER_SECOND = 32;
        let MIN_OPACITY = 0.9;
        let MAX_OPACITY = 0.99;
        let MIN_SIZE_MULTIPLIER = 0.5;
        let MAX_SIZE_MULTIPLIER = 1.25;
        let MIN_DIAMETER = 7;
        let HALF = 0.5;
        let DOUBLE = 2;

        let heightCelebrationRegion = ((this._canvasHeight - this._height) * HALF) + 1;
        let widthCelebrationRegion = ((this._canvasWidth - this._width) * HALF) + 1;

        let perimeter = (this._canvasHeight + this._canvasWidth) * DOUBLE;
        let increment = perimeter / (this.particleCount);

        this.celebrationParticles = [];


        for (let i = 0; i < this.particleCount; i++){
            let particle: BubbleCelebrationParticle = new BubbleCelebrationParticle(
                this.random(MIN_DIAMETER, this._height * HALF),
                this.random(MIN_OPACITY, MAX_OPACITY),
                this.random(MAX_SIZE_MULTIPLIER, MIN_SIZE_MULTIPLIER),
                this.colors[i % this.colors.length]
            );

            let perimeterLocation: number = increment * i;

            // set particle ending location
            if(perimeterLocation < this._canvasWidth) {
                particle.x = perimeterLocation;
                particle.y = this.random(0, heightCelebrationRegion) + (particle.radius) ;
                if (particle.x > this._canvasWidth - particle.diameter) {
                    particle.x = this._canvasWidth - particle.diameter;
                }
            } else if (perimeterLocation < (this._canvasWidth + this._canvasHeight)) {
                particle.x = this._canvasWidth - this.random(0, widthCelebrationRegion) - (particle.radius);
                particle.y = perimeterLocation - this._canvasWidth;
                if(this._canvasHeight - particle.diameter  < particle.y){
                    particle.y = this._canvasHeight - particle.diameter;
                }
            } else if (perimeterLocation < ((this._canvasWidth * DOUBLE) + this._canvasHeight )) {
                particle.x = (this._canvasWidth * DOUBLE) + this._canvasHeight - perimeterLocation;
                particle.y = this._canvasHeight - this.random(0, heightCelebrationRegion) - (particle.radius );
                if (particle.x > this._canvasWidth - particle.diameter) {
                    particle.x = this._canvasWidth - particle.diameter;
                }
            } else {
                particle.x = this.random(0, widthCelebrationRegion) + (particle.radius);
                particle.y = perimeter - perimeterLocation;
                if (this._canvasHeight - particle.diameter  < particle.y){
                    particle.y = this._canvasHeight - particle.diameter;
                }
            }


            particle.element = new createjs.Shape();

            particle.element.alpha = particle.opacity;
            particle.element.scaleX = particle.scale;
            particle.element.scaleY = particle.scale;
            particle.element.graphics.beginFill(particle.color).drawCircle(0, 0, particle.radius);

            this.celebrationParticles.push(particle);
            this.stage.addChild(particle.element);
        }

        createjs.Ticker.setFPS(FRAMES_PER_SECOND);
    };

    initializeParticlePosition(particle: BubbleCelebrationParticle) {
        let HALF = 0.5;
        let centerHeight = this._canvasHeight * HALF;
        let centerWidth = this._canvasWidth * HALF;

        particle.element.x = centerWidth;
        particle.element.y = centerHeight;
    }
}
