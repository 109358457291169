import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class CachedSubject<T> {
    private cacheKey: any;
    private cacheValue: T;
    private subject: BehaviorSubject<T>;

    constructor() {
        this.cacheKey = null;
        this.cacheValue = null;
        this.subject = new BehaviorSubject<T>(null);
    }

    get(key: any, updateMethod: () => Observable<T>): Observable<T> {
        if (this.cacheKey === key) {
            return of(this.cacheValue);
        } else {
            return updateMethod().pipe(
                map(newValue => {
                    this.cacheKey = key;
                    this.cacheValue = newValue;
                    this.subject.next(newValue);
                    return newValue;
                })
            )
        }
    }

    getSubject(): BehaviorSubject<T> {
        return this.subject;
    }
}
