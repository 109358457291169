import { Input, Directive, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StateService, Transition } from '@uirouter/angular';
import * as _ from 'lodash';
import { CurrentMemberService } from 'src/app/shared/current-member.service';
import { MemberEligibilityCreationService } from 'src/app/shared/member-eligibility-creation.service';
import { SponsorSettings } from 'src/app/shared/sponsor-settings.class';
import { SponsorSettingsService } from 'src/app/shared/sponsor-settings.service';
import { ValidationTransitionService } from 'src/app/shared/validation-transition.service';
import { environment } from 'src/environments/environment';
import { AnalyticsTrackingCommandsService } from '../../analytics-tracking/analyticsTrackingCommands.service';
import { ValidationPageModel } from '../../analytics-tracking/domain/validation-page.model';
import { RegistrationCapType } from '../../shared/registration-cap-type.enum';
import { WaitlistEligible } from '../../shared/waitlist-eligible.class';
import { WaitlistService } from '../../waitlist/waitlist.service';
import { SponsorValidationService } from '../sponsor-validation.service';
import { BaseValidationForm } from './base-validation-form.class';
import { CountryService } from '../../shared/country.service';

@Directive()
export class NoEligibilityFileBaseValidationForm extends BaseValidationForm implements OnInit {
    sponsorName: string;
    sponsorNameLoaded = false;
    noSponsorNameSet = true;
    loginUrl: string = environment.genesis.url;
    _sponsorSettings: SponsorSettings;
    companyId: number;

    @Input() set sponsorSettings(settings: SponsorSettings) {
        if (settings) {
            this._sponsorSettings = settings;
            if (settings.sponsorName != null) {
                this.sponsorName = settings.sponsorName;
                this.noSponsorNameSet = false;
            }
        }
    }

    @Input() set memberData(memberData) { }

    @Input() validationRule: string;

    constructor(
        currentMemberService: CurrentMemberService,
        fb: UntypedFormBuilder,
        state: StateService,
        transition: Transition,
        sponsorSettingsService: SponsorSettingsService,
        sponsorValidationService: SponsorValidationService,
        translateService: TranslateService,
        memberEligibilityCreationService: MemberEligibilityCreationService,
        validationTransitionService: ValidationTransitionService,
        waitlistService: WaitlistService,
        analyticsTrackingCommandsService: AnalyticsTrackingCommandsService,
        countryService: CountryService
    ) {
        super(
            sponsorValidationService,
            currentMemberService,
            null,
            translateService,
            validationTransitionService,
            state,
            transition,
            fb,
            waitlistService,
            analyticsTrackingCommandsService,
            countryService
        )
    }

    ngOnInit() {
        this.member.language = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setDefaultSponsorName();
            this.member.language = event.lang;
        });

        this.setDefaultSponsorName();

        const validationPageModel: ValidationPageModel = {
            sponsor_id: this._sponsorSettings.sponsorId,
            sponsor_name: this._sponsorSettings.sponsorName,
            validation_rule: _.lowerCase(this.validationRule),
            eligibility_token: (this.transition.params().eligibilityToken) ? true : false
        };
        this.analyticsTrackingCommandsService.trackSignUpDemographicPageViewed(
            validationPageModel,
            this._sponsorSettings.excludeMixpanelTracking
        );
    }

    onSubmit() {
        this.member.sponsorId = this._sponsorSettings.sponsorId;
        this.member.legacySponsorId = Number(this._sponsorSettings.legacySponsorId);
        this.member.enrollmentGroupId = Number(this.transition.params().enrollmentGroupId);
        this.loadingValidation = true;

        this.companyId = Number(this.member.companyId);

        this.sponsorValidationService
            .validateMemberResolved(this.member)
            .subscribe((result) => {
                this.member = result;
                this.currentMemberService.memberEnrollmentData = result;
                this.currentMemberService.memberEnrollmentData.companyId = String(this.companyId);
                this.saveError = null;

                if (this._sponsorSettings.capType === RegistrationCapType.Company) {
                    const waitlistEligible = this.generateWaitlistEligibleObject();

                    this.waitlistService.validateAgainstSponsorCompanyRegistrationCap(waitlistEligible).subscribe( (response) => {
                        if (response['registrationOpen'] && this.companyId) {
                            this.goToAgreementsOrProductSelectPage();
                        } else {
                            this.state.go('waitlist', this.transition.params());
                        }
                    });
                } else {
                    this.goToAgreementsOrProductSelectPage();
                }

                const validationPageModel: ValidationPageModel = {
                    sponsor_id: this._sponsorSettings.sponsorId,
                    sponsor_name: this._sponsorSettings.sponsorName,
                    validation_rule: _.lowerCase(this.validationRule),
                    country: _.lowerCase(this.member.country),
                    external_member_id: this.member.externalId
                };
                this.analyticsTrackingCommandsService.trackSignUpDemographicInfoComplete(
                    validationPageModel,
                    this._sponsorSettings.excludeMixpanelTracking
                );
            },
            (error) => {
                this.saveError = error;
                this.loadingValidation = false;
            });
    }

    goToAgreementsOrProductSelectPage() {
        const transition = this.validationTransitionService.getValidationTransition(
            this.transition.params(), this.currentMemberService.memberEnrollmentData.eligibleProducts);

        this.state.go(transition.target, transition.params);
    }

    isSubmitDisabled() {
        return !(this.validationForm.valid &&
            this.member.company &&
            this.member.businessUnit &&
            this.member.officeLocation) ||
            this.loadingValidation;
    }

    setDefaultSponsorName() {
        if (this.noSponsorNameSet) {
            this.translateService.get('enrollment.forms.yourCompany').subscribe((yourCompany: string) => {
                this.sponsorName = yourCompany;
            });
        }
    }

    generateWaitlistEligibleObject() {
        const waitlistEligible = new WaitlistEligible();
        waitlistEligible.sponsorId = this.member.sponsorId;
        waitlistEligible.emailAddress = this.member.emailAddress;
        waitlistEligible.firstName = this.member.firstName;
        waitlistEligible.lastName = this.member.lastName;
        waitlistEligible.companyId = this.companyId;

        return waitlistEligible;
    }
}
