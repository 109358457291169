<div *ngIf="shouldShowBanner" class="ie-warning-banner">
    <div class="warning-banner-icon-wrapper">
        <img [src]="'assets/images/megaphone-solid.svg'" />
    </div>
    <div class="warning-banner-content">
        <div tabindex="0">
            <p class="ie-warning-banner-title" translate="">enrollment.ieWarningBanner.bannerTitle</p>
            <p class="ie-warning-banner-subtitle" translate="">enrollment.ieWarningBanner.bannerMessage</p>
        </div>
        <button (click)="closeWarningBanner()" aria-label="Close banner" class="close-warning-banner">
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>
