import { Injectable } from '@angular/core';

import { EnrollmentError } from './enrollment-error-type.enum';

@Injectable()
export class EnrollmentErrorService {
    handleError(error) {
        switch (error.status) {
            case 400:
                return EnrollmentError.MissingIds;
            case 403:
                return EnrollmentError.UsernameExists;
            case 405:
                return EnrollmentError.EmailExists;
            case 406:
                return EnrollmentError.DuplicateRecords;
            case 409:
                return EnrollmentError.AlreadyEnrolled;
            case 412:
                return EnrollmentError.InformationMismatch;
            case 417:
                return EnrollmentError.MissingInformation;
            default:
                return EnrollmentError.EnrollmentFailed;
        }
    }
}
