<div class="form-group">
    <label [for]="inputId"
           [translate]="translationPhoneNumber">
    </label>
    <div class="child-width-100">
        <input type="text"
               [id]="inputId"
               ng2TelInput
               class="form-input phone-number"
               [(ngModel)]="phoneNumber"
               (ngModelChange)="onChange()"
               (keypress)="numberOnly($event)"
               (blur)="onBlur()"
               [maxLength]="maxInputLength"
               (intlTelInputObject)="telInputObject($event)"
               [ng2TelInputOptions]="{initialCountry: countryIso2Code, preferredCountries: ['us'], separateDialCode: true}"
               (countryChange)="onCountryChange($event)">
    </div>
    <div *ngIf="touched">
        <small class="error-text"
               translate=""
               *ngIf="!isValidPhoneNumber"
               role="alert"
               aria-live="assertive">
            enrollment.forms.errors.invalidPhoneNumber
        </small>
    </div>
</div>
