import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  get(key: string): any {
    let storedValue = sessionStorage.getItem(key);
    if (!storedValue) {
        return null;
    }

    try {
        return JSON.parse(storedValue);
    } catch(ex: any) {
        return storedValue;
    }
  }

  set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}
