<div>
    <div class="app-download-container">
        <div class="horiz-rule">
            <span translate="">enrollment.forms.or</span>
        </div>
        <p class="vp-app-helper-text" translate="">enrollment.forms.accessAccountFromMobile</p>
        <div class="mobile-store-container">
            <a id="app-store-anchor" href="https://itunes.apple.com/us/app/virgin-pulse/id793322895?mt=8">
                <img class="app-store-img" src="{{ 'enrollment.images.appstoreLogo' | translate }}" alt="{{ 'enrollment.screenReader.appStore' | translate }}">
            </a>
            <a id="play-store-anchor" href="https://play.google.com/store/apps/details?id=com.virginpulse.virginpulse&hl=en">
                <img class="play-store-img" src="{{ 'enrollment.images.googleplayLogo' | translate }}" alt="{{ 'enrollment.screenReader.googlePlay' | translate }}">
            </a>
        </div>
    </div>
</div>
