import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { MemberEnrollmentData } from 'src/app/shared/member-enrollment-data.class';


@Injectable()
export class EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService {
    constructor(
        private http: HttpClient
    ) { }

    getMemberEligibilityFromToken(
        sponsorId: number, 
        enrollmentGroupId: number, 
        token: string
    ): Observable<MemberEnrollmentData> {
        let tokenURL : string = `/enrollment-api/sponsors/${ sponsorId }/enrollment-groups/${ enrollmentGroupId }/eligibility/tokens/${ token }`;

        return this.http.get<MemberEnrollmentData>(tokenURL);
    }
}