<div class="form-group form-group-name">
    <label translate="" for="birthYear">
        enrollment.forms.birthyear
    </label>
    <div>
        <input class="form-input d-none d-md-block birthYear"
               id="birthYear"
               type="number"
               [(ngModel)]="birthYear"
               [ngClass]="{'validation-error': getError() }"
               placeholder="{{'enrollment.forms.year' | translate }}"
               triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
               [popover]="popoverText"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()">
        <select class="form-input d-md-none mobile-picker birthYear"
                [(ngModel)]="birthYear"
                [ngClass]="{'validation-error': getError() }"
                triggers="{{ popoverText && passwordResetTypeDOB ? 'focus' : 'none' }}"
                [popover]="popoverText"
                (change)="onChange()"
                (blur)="onBlur()">
            <option [ngValue]="null" disabled translate="">
                enrollment.forms.year
            </option>
            <option *ngFor="let year of availableYears"
                    [ngValue]="year">{{ year }}
            </option>
        </select>
        <small class="error-text"
               *ngIf="touched && getError()">
            <span *ngIf="getError().required" 
                [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: 'enrollment.forms.birthyear' | translate }">
            </span>
            <span translate="" *ngIf="getError().invalid">
                enrollment.forms.errors.beSureToUseANumberThatIsADay
            </span>
            <span translate="" *ngIf="getError().lowAge">
                enrollment.forms.errors.youMustBeAtLeast
            </span>
            <span translate="" *ngIf="getError().highAge">
                enrollment.forms.errors.beSureToUseYourActualDateOfBirth
            </span>
        </small>
    </div>
    <vpe-age-consent-warning [birthdate]="getBirthdateAsMoment()"></vpe-age-consent-warning>
</div>
