import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { InputType } from '@engineering/genesis-frontend/domain/types';

@Component({
    selector: 'reactive-textbox',
    templateUrl: './reactive-textbox.component.html',
    styleUrls: ['./reactive-textbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveTextboxComponent),
            multi: true,
        }
    ]
})
export class ReactiveTextboxComponent extends BaseFormField implements ControlValueAccessor {

    value: string;
    _field: FlexFormField;

    fieldTypeToUse: InputType = 'text';
    hasIcon = false;

    @Input()
    get field(): FlexFormField {
        return this._field;
    }

    set field(newField: FlexFormField) {
        this._field = newField;

        if (this._field.initialValue) {
            this.value = this._field.initialValue;
        }

        if (this._field.sensitive) {
            this.fieldTypeToUse = 'password';
            this.hasIcon = true;
        }
    }

    onChange(event: any) {
        this.value = event.trim();
        this.propagateChange(this.value);
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(newValue: string) {
        this.value = newValue;
    }
}
