<form [formGroup]="form">
    <vpg-dropdown-next class="groove-dropdown-vpg-dropdown"
                       [formControlName]="'language'"
                       [name]="'language'"
                       [placeholder]="'enrollment.header.setYourLanguage' | translate"
                       [ariaLabel]="'enrollment.forms.labelSelectLanguage' | translate"
                       [label]="'enrollment.forms.labelSelectLanguage' | translate"
                       [options]="dropdownOptions"
                       [optionLabel]="'text'">
    </vpg-dropdown-next>
</form>
