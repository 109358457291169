import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Language } from '../shared/language.class';
import { LanguagesService } from '../shared/languages.service';
import { SponsorSettings } from '../shared/sponsor-settings.class';
import { environment } from '../../environments/environment';
import { CountryService } from '../shared/country.service';
import { Constants } from '../shared/constants.class';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    sponsorLogo: string = Constants.DEFAULT_LOGO_URL;
    languages: Array<Language>;
    selectedLanguage: Language;
    loginLink: string = environment.signIn.vpLink;
    _sponsorSettings: SponsorSettings;
    countrySet: boolean = false;
    showSignInLink: boolean = true;

    @Input() set sponsorSettings(settings: SponsorSettings) {
        this._sponsorSettings = settings;
        this.sponsorLogo = _.get(settings, 'logoUrl') || Constants.DEFAULT_LOGO_URL;
        this.showSignInLink = (settings?.customEligibilityDataSource?.name !== Constants.CUSTOM_ELIGIBILITY_DATA_SOURCE.AETNA_VSI);
    }

    @Input()
    isHeaderVisible: Boolean;

    constructor(
        private languagesService: LanguagesService,
        public translateService: TranslateService,
        private countryService: CountryService
    ) { }

    ngOnInit() {
        this.languagesService.getLanguages()
            .subscribe((languages) => {
                this.languages = languages;

                this.selectedLanguage = _.find(
                    languages,
                    {
                        'code': this.translateService.currentLang || this.translateService.getDefaultLang()
                    }
                );
                this.translateService.use(this.selectedLanguage.code);

                this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                    let newLanguage = _.find(this.languages, { 'code': event.lang });
                    if (newLanguage) {
                        this.selectedLanguage = newLanguage;
                        moment.locale(event.lang);
                    }
                });
            });

            this.countryService.getSelectedCountry().subscribe((country) => {
                this.countrySet = country != null;
            });
    }

    setLanguage() {
        this.translateService.use(this.selectedLanguage.code);
        document.querySelector('html').setAttribute('lang', this.selectedLanguage.code.split('-')[0]);
    }
}
