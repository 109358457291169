import { CustomValidationField } from './custom-validation-field.class';
import { FieldType } from './field-type.enum';
import * as _ from 'lodash';

export class EnrollmentGroup {
  public id: number;
  public sponsorId: number;
  public groupName: string;
  public enrollmentValidationRule: string;
  public title: string;
  public default: boolean;
  public hidden: boolean;
  public requireEmailConfirmation: boolean;
  public emailOptional: boolean;
  public abbreviatedEnrollment: boolean;
  public displayOrgHierarchy: boolean;
  public customEnrollmentDataField: string;
  public externalId: string;

  public customValidationFields: Array<CustomValidationField>;

  getFieldName(originalFieldName: FieldType): string {
      let customFieldName = _.find(this.customValidationFields, { fieldType: originalFieldName });
      if (customFieldName) {
          return customFieldName.replacement;
      }

      return null;
  }
}
