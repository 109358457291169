<form class="container-fluid"
      (ngSubmit)="save()"
      [formGroup]="waitlistForm"
      novalidate>
    <div class="form-group form-group-name">
        <label translate="">enrollment.forms.name</label>
        <div class="name-wrapper">
            <div class="firstName-col">
                <input class="form-input firstName"
                       [attr.aria-label]=" 'enrollment.forms.firstName' | translate "
                       id="firstName"
                       name="firstName"
                       formControlName="firstName"
                       [(ngModel)]="waitlistEligible.firstName"
                       placeholder="{{'enrollment.forms.firstName' | translate }}"
                       [ngClass]="{'validation-error': !waitlistForm.controls.firstName.valid}"
                       required
                       aria-describedby="nef-blank-error">
            </div>
            <div class="lastName-col">
                <input class="form-input lastName"
                       [attr.aria-label]=" 'enrollment.forms.lastName' | translate "
                       id="lastName"
                       name="lastName"
                       formControlName="lastName"
                       [(ngModel)]="waitlistEligible.lastName"
                       placeholder="{{ 'enrollment.forms.lastName' | translate }}"
                       [ngClass]="{'validation-error': !waitlistForm.controls.lastName.valid}"
                       required
                       aria-describedby="nef-blank-error">
            </div>
        </div>
        <div *ngIf="(waitlistForm.controls.lastName.touched && !waitlistForm.controls.lastName.valid) || (waitlistForm.controls.firstName.touched && !waitlistForm.controls.firstName.valid)"
             id="nef-blank-error">
            <small class="sr-only" translate="">enrollment.screenReader.error</small>
            <small class="error-text" [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: 'enrollment.forms.name' | translate }">
            </small>
        </div>
    </div>
    <vpe-email formControlName="emailAddress"
               [(ngModel)]="waitlistEligible.emailAddress"
               [sponsorSettings]="sponsorSettings"
               [validateEmailDomain]="false">
    </vpe-email>
    <div class="privacy-policy-container">
        <h3 class="privacy-policy-title"
            [innerHtml]="privacyPolicyConsentAgreement ? privacyPolicyConsentAgreement.title : ''">
        </h3>
        <div class="privacy-policy-content"
             [innerHtml]="'enrollment.forms.agreements.waitlistAgreementSummary' | translate: { title: privacyPolicyConsentAgreement ? privacyPolicyConsentAgreement.title : '' } ">
        </div>
    </div>
    <div class="agreements-checkboxes">
        <div class="checkbox-row">
            <input class="sr-only agreement-checkbox"
                   type="checkbox"
                   [attr.name]="'data-consent-checkbox'"
                   [attr.id]="'data-consent-checkbox'"
                   formControlName="dataStorageCheckbox"
                   [ngClass]="{ 'validation-error': !waitlistForm.controls.dataStorageCheckbox.valid }"
                   [(ngModel)]="privacyPolicyConsentAgreementAccepted">
            <label class="agreement-label" [attr.for]="'data-consent-checkbox'">
                <div class="checkbox-container"
                     [attr.aria-checked]=" privacyPolicyConsentAgreementAccepted ? 'true' : 'false' "
                     [ngClass]="{ 'checked': privacyPolicyConsentAgreementAccepted }">
                    <i class="checkmark" *ngIf="privacyPolicyConsentAgreementAccepted" aria-hidden="true">✓</i>
                </div>
                <span class="accept-text"
                      [innerHtml]="'enrollment.forms.agreements.iHaveReadAndAgree' | translate: { title: privacyPolicyConsentAgreement ? privacyPolicyConsentAgreement.title : '' } ">
                </span>
            </label>
        </div>
    </div>
    <div class="agreement-container" *ngFor="let agreement of agreements">
        <div class="toggle-content-link"
             (click)="toggleContent(agreement, $event)"
             tabindex="0"
             role="button">
            <span class="show-link" *ngIf="!agreement.showContent">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
                <span [innerHtml]="'enrollment.forms.agreements.showFullAgreement' | translate: { agreementTitle: agreement ? agreement.title : '' } "></span>
            </span>
            <span class="hide-link" *ngIf="agreement.showContent">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
                <span [innerHtml]="'enrollment.forms.agreements.hideFullAgreement' | translate: { agreementTitle: agreement ? agreement.title : '' } "></span>
            </span>
        </div>
        <div class="agreement-content" *ngIf="agreement.showContent">
            <div [ngClass]="{ 'agreement-content-scrollbox': agreement.type !== 'DataConsent' }"
                 [innerHtml]="agreement ? agreement.content : ''">
            </div>
        </div>
    </div>
    <button class="btn btn-orange"
            type="submit"
            translate=""
            [disabled]="isSaveDisabled()"
            aria-describedby="nef-save-error">
        enrollment.forms.signUp
    </button>
    <div class="save-error-container"
         *ngIf="saveError !== null"
         id="nef-save-error"
         aria-live="polite">
        <small class="sr-only" translate="">enrollment.screenReader.error</small>
        <div>
            <small class="error-text">{{ saveError }}</small>
        </div>
    </div>
</form>
