import { FieldWithConfirmation } from "./field-with-confirmation";

export class PasswordWithConfirmation implements FieldWithConfirmation {
    password: string;
    matchesConfirmation: boolean;

    constructor(password?: string, matchesConfirmation?: boolean) {
        this.password = password ?? '';
        this.matchesConfirmation = matchesConfirmation ?? false;
    }
}