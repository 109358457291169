import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GenderIdentity } from "./gender-identity.class";

@Injectable()
export class GenderIdentityService {
  private genderIdentityList: Observable<Array<GenderIdentity>>;

  constructor(
    private http: HttpClient
  ) {}

  getGenderIdentities(currentLanguage: string, sponsorId: number) {
    let GENDER_IDENTITY_URL: string = '/enrollment-api/sponsors/'+ sponsorId +'/gender-identities' ;

    this.genderIdentityList = this.http.get<GenderIdentity[]>(GENDER_IDENTITY_URL, {
      params: {
        language: currentLanguage
      }
    });

    return this.genderIdentityList;
  }
}
