<form class="container-fluid"
      (ngSubmit)="onSubmit()"
      [formGroup]="validationForm"
      novalidate>
    <div class="form-group form-group-name">
        <label translate="">enrollment.forms.name</label>
        <div class="name-wrapper">
            <div class="firstName-col">
                <input class="form-input firstName"
                       [attr.aria-label]=" 'enrollment.forms.firstName' | translate "
                       id="firstName"
                       name="firstName"
                       [(ngModel)]="member.firstName"
                       formControlName="firstName"
                       placeholder="{{'enrollment.forms.firstName' | translate }}"
                       [ngClass]="{'validation-error': !validationForm.controls.firstName.valid}"
                       required>
            </div>
            <div class="lastName-col">
                <input class="form-input lastName"
                       [attr.aria-label]=" 'enrollment.forms.lastName' | translate "
                       id="lastName"
                       name="lastName"
                       [(ngModel)]="member.lastName"
                       formControlName="lastName"
                       placeholder="{{ lastNameText ? lastNameText : ('enrollment.forms.lastName' | translate) }}"
                       [ngClass]="{'validation-error': !validationForm.controls.lastName.valid}"
                       required>
            </div>
        </div>
        <small class="error-text"
               role="alert"
               aria-live="assertive"
               *ngIf="(validationForm.controls.lastName.touched && !validationForm.controls.lastName.valid) || (validationForm.controls.firstName.touched && !validationForm.controls.firstName.valid)"
               [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: 'enrollment.forms.name' | translate }">
        </small>
    </div>
    <vpe-enrollment-code id="enrollmentCode"
                         name="enrollmentCode"
                         formControlName="enrollmentCode"
                         [(ngModel)]="member.enrollmentCode"
                         [enrollmentGroup]="_enrollmentGroup">
    </vpe-enrollment-code>
    <vpe-country id="country" formControlName="country" [(ngModel)]="member.country"></vpe-country>
    <vpe-state-of-residence id="stateOfResidence"
                            formControlName="stateOfResidence"
                            [(ngModel)]="member.stateOfResidence">
    </vpe-state-of-residence>
    <div [ngSwitch]="saveError">
        <small class="error-text"
               *ngSwitchCase="null"
               role="alert"
               aria-live="assertive">
        </small>
        <small class="error-text"
               *ngSwitchCase="enrollmentError.AlreadyEnrolled"
               translate="" role="alert"
               aria-live="assertive">enrollment.forms.errors.helloAgain
        </small>
        <small class="error-text"
               *ngSwitchDefault
               translate=""
               role="alert"
               aria-live="assertive">
            enrollment.forms.errors.yourDetailsDontMatch
        </small>
    </div>
    <button class="btn btn-orange"
            type="submit"
            translate=""
            [disabled]="!validationForm.valid || loadingValidation">
        enrollment.forms.continue
    </button>
</form>
