import {Component, Input, OnInit} from '@angular/core';

import { WaitlistComponent } from '../../waitlist.component';
import { SponsorSettings } from '../../../shared/sponsor-settings.class';

@Component({
    selector: 'waitlist-sponsor-cap',
    templateUrl: './waitlist-sponsor-cap.component.html',
    styleUrls: ['../../waitlist.component.scss']
})
export class WaitlistSponsorCapComponent extends WaitlistComponent implements OnInit {
    waitlistFormComplete: Boolean;

    ngOnInit() {
        super.ngOnInit()
    }
}
