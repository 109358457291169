<div [formGroup]="form" class="flex-form-field">
    <div class="flex-form-field-container flex-form-sponsor-search-field">
        <vpg-search [minCharacters]="MIN_CHARACTERS"
                    [id]="'searchString'"
                    [name]="'searchString'"
                    [results]="sponsorSearchData"
                    (searched)="searchSponsors($event)"
                    (valueChange)="selectSponsor($event)"
                    [placeholder]="'enrollment.forms.sponsorSearch' | translate">
        </vpg-search>
    </div>
</div>
