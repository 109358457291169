<form class="container-fluid"
      (ngSubmit)="onSubmit()"
      [formGroup]="validationForm"
      novalidate>
    <div *ngIf="sponsorNameLoaded">
        <p [innerHtml]="'enrollment.forms.invitesYouToJoin' | translate: {sponsorName: sponsorName} "></p>
    </div>
    <div class="form-group form-group-name">
        <label translate="">enrollment.forms.name</label>
        <div class="name-wrapper">
            <div class="firstName-col">
                <input class="form-input firstName"
                       [attr.aria-label]=" 'enrollment.forms.firstName' | translate "
                       id="firstName"
                       name="firstName"
                       formControlName="firstName"
                       [(ngModel)]="member.firstName"
                       placeholder="{{'enrollment.forms.firstName' | translate }}"
                       [ngClass]="{'validation-error': !validationForm.controls.firstName.valid}"
                       required
                       aria-describedby="nef-blank-error">
            </div>
            <div class="lastName-col">
                <input class="form-input lastName"
                       [attr.aria-label]=" 'enrollment.forms.lastName' | translate "
                       id="lastName"
                       name="lastName"
                       formControlName="lastName"
                       [(ngModel)]="member.lastName"
                       placeholder="{{ 'enrollment.forms.lastName' | translate }}"
                       [ngClass]="{'validation-error': !validationForm.controls.lastName.valid}"
                       required
                       aria-describedby="nef-blank-error">
            </div>
        </div>
        <div *ngIf="(validationForm.controls.lastName.touched && !validationForm.controls.lastName.valid) || (validationForm.controls.firstName.touched && !validationForm.controls.firstName.valid)"
             id="nef-blank-error">
            <small class="sr-only" translate="">enrollment.screenReader.error</small>
            <small class="error-text" [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: 'enrollment.forms.name' | translate }">
            </small>
        </div>
    </div>
    <vpe-email formControlName="email"
               [(ngModel)]="member.emailAddress"
               popoverText="{{ (_sponsorSettings.isDomainRestricted ? 'enrollment.forms.youMustUseYourWorkEmail' : 'enrollment.forms.pleaseUseYourPreferredEmail') | translate }}"
               [sponsorSettings]="_sponsorSettings">
    </vpe-email>
    <vpe-org-hierarchy [member]="member"
                       [sponsorSettings]="_sponsorSettings">
    </vpe-org-hierarchy>
    <vpe-gender-identity formControlName="genderIdentity"
                         [(ngModel)]="member.genderIdentity"
                         [sponsorSettings]="_sponsorSettings">
    </vpe-gender-identity>
    <vpe-birthdate id="birthdate"
                   name="birthdate"
                   formControlName="birthdate"
                   [(ngModel)]="member.dateOfBirth"
                   popoverText="{{ 'enrollment.forms.yourDateOfBirthIsRequired' | translate | translate }}"
                   [passwordResetChallenge]="_sponsorSettings.passwordResetChallenge">
    </vpe-birthdate>
    <vpe-country id="country" formControlName="country" [(ngModel)]="member.country"></vpe-country>
    <vpe-state-of-residence id="stateOfResidence"
                            formControlName="stateOfResidence"
                            [(ngModel)]="member.stateOfResidence">
    </vpe-state-of-residence>
    <vpe-phonenumber *ngIf="_sponsorSettings.allowPhoneNumberCollection"
                     formControlName="phoneNumber"
                     name="phoneNumber" [(intlPhoneNumber)]="member.phoneNumber"
                     [phoneType]="'phoneNumber'">
    </vpe-phonenumber>
    <vpe-phonenumber *ngIf="_sponsorSettings.allowPhoneNumberCollection"
                     formControlName="homePhoneNumber"
                     name="homePhoneNumber" [(intlPhoneNumber)]="member.homePhoneNumber"
                     [phoneType]="'homePhoneNumber'">
    </vpe-phonenumber>
    <vpe-timezone formControlName="timezone"
                  [(ngModel)]="member.timezone">
    </vpe-timezone>
    <div *ngIf="saveError !== null"
         id="nef-save-error"
         aria-live="polite">
        <small class="sr-only" translate="">enrollment.screenReader.error</small>
        <div [ngSwitch]="saveError">
            <small class="error-text"
                   *ngSwitchCase="enrollmentError.AlreadyEnrolled"
                   translate="">enrollment.forms.errors.helloAgain
            </small>
            <small class="error-text"
                   *ngSwitchCase="enrollmentError.EmailExists"
                   [innerHtml]="'enrollment.forms.errors.emailInUse' | translate:{ loginUrl: loginUrl }">
            </small>
            <small class="error-text"
                   *ngSwitchDefault
                   translate="">
                enrollment.forms.errors.yourDetailsDontMatch
            </small>
        </div>
    </div>
    <button class="btn btn-orange"
            type="submit" translate=""
            [disabled]="isSubmitDisabled()"
            aria-describedby="nef-save-error">
        enrollment.forms.submit
    </button>
</form>
