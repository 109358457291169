import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reactive-security-question',
    templateUrl: './reactive-security-question.component.html',
    styleUrls: ['./reactive-security-question.component.scss']
})

export class ReactiveSecurityQuestionComponent implements OnInit {
    @Input() data: any;

    @Input() answerFieldName: string = '';
    @Input() answerDisabled: boolean = false;
    @Input() answerLabel: string = '';

    @Output() answerValueChanged: EventEmitter<string> = new EventEmitter(true);

    @Input() questionFieldName: string = '';
    @Input() questionDisabled: boolean = false;
    @Input() questionLabel: string = '';

    @Output() questionValueChanged: EventEmitter<string> = new EventEmitter(true);

    @Output() touched = new EventEmitter<boolean>(true);

    @Input() isRequired: boolean = false;

    defaultValue = null;

    securityQuestionForm: UntypedFormGroup;
    customValidatorsQuestion: string[] = [];

    constructor(
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        const validatorsQuestion: ValidatorFn[] = [];
        const validatorsAnswer: ValidatorFn[] = [];

        validatorsAnswer.push(Validators.minLength(2));

        if (this.isRequired) {
            validatorsQuestion.push(Validators.required);
            validatorsQuestion.push(this.questionRequired());
            this.customValidatorsQuestion.push('required');

            validatorsAnswer.push(Validators.required);
        }

        let fields = {};
        fields[this.questionFieldName] = new UntypedFormControl({
            value: this.defaultValue,
            disabled: this.questionDisabled
        }, validatorsQuestion);

        fields[this.answerFieldName] = new UntypedFormControl({
            value: this.defaultValue,
            disabled: this.answerDisabled
        }, validatorsAnswer);

        this.securityQuestionForm = new UntypedFormGroup(fields);

        this.securityQuestionForm.controls[this.questionFieldName].valueChanges.subscribe((newValue) => {
            this.questionValueChanged.emit(newValue);
            this.securityQuestionForm.controls[this.answerFieldName].enable();
            this.securityQuestionForm.controls[this.answerFieldName].setValue(null);
        });

        this.securityQuestionForm.controls[this.answerFieldName].valueChanges.subscribe((newValue) => {
            this.answerValueChanged.emit(newValue);
        });
    }

    onBlur() {
        this.touched.emit(true);
    }

    private questionRequired(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return { required: 'Invalid', message: this.translateService.instant('enrollment.forms.errors.fieldIsRequired', { fieldName: this.questionLabel }) };
            }

            return null;
        };
    }
}
