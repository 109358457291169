import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { Validators } from '@angular/forms';
import { ValidationPageModel } from '../../../analytics-tracking/domain/validation-page.model';
import { BaseValidationForm } from '../../shared/base-validation-form.class';

@Component({
    selector: 'employeeid-validation-form',
    templateUrl: './employeeid-validation-form.component.html',
    styleUrls: ['../../validation.component.scss']
})
export class EmployeeIdValidationFormComponent extends BaseValidationForm implements OnInit, OnChanges {

    displayOrgHierarchyDropdowns = false;

    @Input() set memberData(memberData: any) {
        if (memberData) {
            this.member = memberData;
        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.validationForm = this.fb.group({
            company: [this.member.company, Validators.required],
            businessUnit: [this.member.businessUnit, Validators.required],
            officeLocation: [this.member.officeLocation, Validators.required]
        });

        if (this.transition.params().postEnrollmentRedirectPath) {
            try {
                this.currentMemberService.postEnrollmentRedirectPath = decodeURIComponent(this.transition.params().postEnrollmentRedirectPath);
            } catch (ex) { }
        }
    }

    ngOnChanges() {
        if (this.areInputsResolved()) {
            this.updateMember();
            this.initializeTracking();

            if (this._enrollmentGroup.displayOrgHierarchy) {
                this.displayOrgHierarchyDropdowns = true;
            } else {
                this.onSubmit();
            }
        }
    }

    onSubmit() {
        this.loadingValidation = true;

        this.sponsorValidationService.validateMemberResolved(this.member).subscribe((result) => {
            this.loadingValidation = false;
            this.saveError = null;

            this.currentMemberService.memberEnrollmentData = result;
            this.currentMemberService.memberEnrollmentData.company = this.member.company;
            this.currentMemberService.memberEnrollmentData.businessUnit = this.member.businessUnit;
            this.currentMemberService.memberEnrollmentData.officeLocation = this.member.officeLocation;

            if (this.currentMemberService.memberEnrollmentData && this.currentMemberService.memberEnrollmentData.employeeId) {
                let transition = this.validationTransitionService.getValidationTransition(
                    this.transition.params(),
                    this.currentMemberService.memberEnrollmentData.eligibleProducts
                );
                this.state.go(transition.target, transition.params);
            }
        }, (error) => {
            this.loadingValidation = false;
            this.saveError = error;
        });
    }

    areInputsResolved(): Boolean {
        if (this.sponsorSettings && this.sponsorSettings.sponsorId &&
                this.member && this.member.employeeId &&
                this._enrollmentGroup && this._enrollmentGroup.groupName) {
            return true;
        } else {
            return false;
        }
    }

    updateMember() {
        this.member.language = this.translateService.currentLang;
        this.member.enrollmentGroupId = Number(this.transition.params().enrollmentGroupId);
        this.member.sponsorId = this.sponsorSettings.sponsorId;
        this.member.legacySponsorId = this.sponsorSettings.legacySponsorId;
    }

    initializeTracking() {
        const validationPageModel: ValidationPageModel = {
            sponsor_id: this.sponsorSettings.sponsorId,
            sponsor_name: this.sponsorSettings.sponsorName,
            validation_rule: _.lowerCase(this._enrollmentGroup.enrollmentValidationRule),
            eligibility_token: (this.transition.params().eligibilityToken) ? true : false
        };
        this.analyticsTrackingCommandsService.trackSignUpDemographicPageViewed(
            validationPageModel,
            this.sponsorSettings.excludeMixpanelTracking
        );
    }

    isSubmitDisabled(): Boolean {
        return !this.member.company ||
            !this.member.businessUnit ||
            !this.member.officeLocation ||
            this.loadingValidation;
    }

    onMemberChange(orgHierachyAutoSelected: Boolean) {
        if (orgHierachyAutoSelected) {
            this.onSubmit();
        }
    }
}
