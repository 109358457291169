<div *ngIf="_sponsorSettings">
    <div *ngIf="!waitlistFormComplete">
        <div class="waitlist-header waitlist-company-cap-header">
            <h1 class="page-title" translate>enrollment.waitlist.wereSorry</h1>
            <p class="page-text" translate>enrollment.waitlist.registrationIsFull</p>
        </div>
        <form-wrapper>
            <waitlist-company-cap-form [sponsorSettings]="_sponsorSettings"></waitlist-company-cap-form>
        </form-wrapper>
    </div>
    <div *ngIf="waitlistFormComplete">
        <div class="waitlist-header waitlist-company-cap-header">
            <h1 class="page-title" translate>enrollment.waitlist.youAreOnTheList</h1>
            <p class="page-text" translate>enrollment.waitlist.wellSendYouNotification</p>
        </div>
    </div>
</div>
