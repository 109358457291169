import {Component, Input, OnInit} from '@angular/core';

import { WaitlistComponent } from '../../waitlist.component';
import { SponsorSettings } from '../../../shared/sponsor-settings.class';

@Component({
    selector: 'waitlist-company-cap',
    templateUrl: './waitlist-company-cap.component.html',
    styleUrls: ['../../waitlist.component.scss']
})
export class WaitlistCompanyCapComponent extends WaitlistComponent implements OnInit {
    waitlistFormComplete: Boolean;
    _sponsorSettings: SponsorSettings;

    @Input() set sponsorSettings(sponsorSetting: SponsorSettings) {
        this._sponsorSettings = sponsorSetting;
    };

    ngOnInit() {
        super.ngOnInit();
    }
}
