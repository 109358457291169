import { AfterContentInit, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultItemInputs } from '@engineering/genesis-frontend';
import { Company } from 'src/app/shared/company.class';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { SponsorCompaniesService } from 'src/app/shared/sponsor-companies.service';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
  selector: 'reactive-company',
  templateUrl: './reactive-company.component.html',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ReactiveCompanyComponent),
        multi: true,
    }
]
})
export class ReactiveCompanyComponent extends BaseFormField implements OnInit, ControlValueAccessor, AfterContentInit {

    companies: Company[] = [];
    grooveOptions: DropdownOptions[] = [];
    selectedCompany: Company = null;
    @Output() isFieldHidden = new EventEmitter<boolean>(true);

    @Input() field: FlexFormField;

    constructor(public sponsorCompaniesService: SponsorCompaniesService) {
        super();
    }

    ngOnInit() {
        this.isFieldHidden.emit(false);
        this.companies = this.field?.values as Company[];
        this.grooveOptions = this.companies?.map((company) => {
            return { text: company.name };
        });
    }

    ngAfterContentInit() {
        if (this.field?.initialValue) {
            this.selectedCompany = this.getCompanyById(this.field?.initialValue);
        }

        if (this.companies?.length === 1) {
            this.selectedCompany = this.companies[0];
            this.isFieldHidden.emit(true);
        }
    }

    saveSelectedCompany() {
        this.propagateChange(this.selectedCompany?.id);
        this.sponsorCompaniesService.updateOrgHierarchy(this.selectedCompany);
    }

    onChange(name: string) {
        this.selectedCompany = this.getCompanyByName(name);
        this.saveSelectedCompany();
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(id: string) {
        this.selectedCompany = this.getCompanyById(id);
        this.sponsorCompaniesService.updateOrgHierarchy(this.selectedCompany);
    }

    private getCompanyById(id: string): Company {
        return this.companies?.find((company) => {
            return company?.id === id;
        });
    }

    private getCompanyByName(name: string): Company {
        return this.companies?.find((company) => {
            return company?.name === name;
        });
    }
}
