export class FlexFormDropdownData {
    id: string;
    name: string;

    constructor(initialObject?: {
        id: string,
        name: string
    }) {
        this.id = initialObject?.id;
        this.name = initialObject?.name;
    }
}
