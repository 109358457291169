<div *ngIf="isHeaderVisible" class="header container-fluid">
    <img [src]="sponsorLogo" alt="Logo" class="logo" arid-hidden="true">

    <div class="right-content">
        <div *ngIf="showSignInLink" class="sr-only" translate="">enrollment.header.alreadyAMember</div>
        <div *ngIf="showSignInLink" class="already-member d-none d-lg-block" translate="" aria-hidden="true">
            enrollment.header.alreadyAMember
        </div>
        <a *ngIf="showSignInLink" class="sign-in-link btn-orange-outline d-none d-md-block" role="button" href="{{loginLink}}"
           translate="">
            enrollment.header.signIn
        </a>
        <select id="language-selector" [attr.aria-label]="'enrollment.header.setYourLanguage' | translate"
                class="language-select" [(ngModel)]="selectedLanguage" (ngModelChange)="setLanguage()">
            <option *ngFor="let language of languages" [ngValue]="language">
                {{ language.description }}
            </option>
        </select>
    </div>
</div>
<error-banner></error-banner>
