import { Injectable } from '@angular/core';
import 'clientjs';
import * as _ from 'lodash';

import { fromEvent } from 'rxjs';
import { AudioFingerprintService } from './audioFingerprint.service';

declare function ClientJS(): void;

@Injectable()
export class EnrollmentFingerprintService {
    clientJS;

    KEY_ITEM = 'X-Authenticator-Device'

    constructor(
        private audioFingerprintService: AudioFingerprintService
    ) {
        this.clientJS = new ClientJS();
    }

    async saveCustomFingerprint() {
        return this.getCustomFingerprint()
            .then((customFingerprint) => localStorage.setItem(this.KEY_ITEM, customFingerprint));
    }

    initStorageListener() {
        const storageChange = fromEvent<StorageEvent>(window, 'storage');
        storageChange.subscribe((event) => {
            const isFingerprintChanged = event.storageArea === localStorage &&
                event.key === this.KEY_ITEM &&
                (event.oldValue !== event.newValue);

            if (isFingerprintChanged) { this.saveCustomFingerprint(); }
        });
    }

    private getDeviceFingerprint(): number {
        return this.clientJS.getFingerprint();
    }

    private async getAudioFingerprint(): Promise<number> {
        const defaultAudioFingerprint = 0.0;
        try {
            return await this.audioFingerprintService.getAudioFingerprintId().catch(() => defaultAudioFingerprint);
        } catch (error) {
            return defaultAudioFingerprint;
        }
    }

    private async getCustomFingerprint(): Promise<string> {
        const valueAudio = await this.getAudioFingerprint();
        const valueDevice = this.getDeviceFingerprint();
        return valueAudio > 0 ? _.add(valueAudio, valueDevice).toString() : (valueDevice.toString() + valueAudio.toFixed(1));
    }
}
