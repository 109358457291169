import { Input, Directive, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StateService, Transition } from '@uirouter/angular';
import * as _ from 'lodash';

import { CurrentMemberService } from 'src/app/shared/current-member.service';
import { EnrollmentError, EnrollmentErrorType } from 'src/app/shared/enrollment-error-type.enum';
import { EnrollmentGroup } from 'src/app/shared/enrollment-group.class';
import { MemberEnrollmentData } from 'src/app/shared/member-enrollment-data.class';
import { SponsorEnrollmentGroupsService } from 'src/app/shared/sponsor-enrollment-groups.service';
import { SponsorSettings } from 'src/app/shared/sponsor-settings.class';
import { ValidationTransitionService } from 'src/app/shared/validation-transition.service';
import { AnalyticsTrackingCommandsService } from '../../analytics-tracking/analyticsTrackingCommands.service';
import { ValidationPageModel } from '../../analytics-tracking/domain/validation-page.model';
import { WaitlistService } from '../../waitlist/waitlist.service';
import { SponsorValidationService } from '../sponsor-validation.service';
import { CountryService } from '../../shared/country.service';

@Directive()
export class BaseValidationForm implements OnInit {
    enrollmentError = EnrollmentError;
    saveError: EnrollmentErrorType = null;
    member: MemberEnrollmentData = new MemberEnrollmentData();
    validationForm: UntypedFormGroup;
    lastNameText: string;
    _enrollmentGroup: EnrollmentGroup;
    loadingValidation = false;
    _sponsorSettings: SponsorSettings;

    @Input()
    get sponsorSettings(): SponsorSettings {
        return this._sponsorSettings;
    }

    set sponsorSettings(sponsorSettings: SponsorSettings) {
        this._sponsorSettings = sponsorSettings;
    }

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
        this._enrollmentGroup = group;
        this.lastNameText = group.getFieldName('LastName');
    }

    @Input() set memberData(memberData) {
        if (memberData) {
            this.member = memberData;

            this.validationForm = this.fb.group({
                firstName: [this.member.firstName, Validators.required],
                lastName: [this.member.lastName, Validators.required],
                employeeId: [this.member.employeeId, Validators.required],
                country: [this.member.country, Validators.required],
                stateOfResidence: [this.member.stateOfResidence]
            });
        }
    }

    constructor(
        protected sponsorValidationService: SponsorValidationService,
        protected currentMemberService: CurrentMemberService,
        protected sponsorEnrollmentGroupsService: SponsorEnrollmentGroupsService,
        protected translateService: TranslateService,
        protected validationTransitionService: ValidationTransitionService,
        protected state: StateService,
        protected transition: Transition,
        protected fb: UntypedFormBuilder,
        protected waitlistService: WaitlistService,
        protected analyticsTrackingCommandsService: AnalyticsTrackingCommandsService,
        protected countryService: CountryService
    ) { }

    ngOnInit() {
        this.member.language = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.member.language = event.lang;
            this.sponsorEnrollmentGroupsService.loadEnrollmentGroup(
                this.sponsorSettings.sponsorId,
                this._enrollmentGroup.id,
                event.lang
            ).subscribe((group) => {
                this._enrollmentGroup = group;
                this.lastNameText = group.getFieldName('LastName');
            });
        });
        const validationPageModel: ValidationPageModel = {
            sponsor_id: this.sponsorSettings.sponsorId,
            sponsor_name: this.sponsorSettings.sponsorName,
            validation_rule: _.lowerCase(this._enrollmentGroup.enrollmentValidationRule),
            eligibility_token: (this.transition.params().eligibilityToken) ? true : false
        };
        this.analyticsTrackingCommandsService.trackSignUpDemographicPageViewed(
            validationPageModel,
            this.sponsorSettings.excludeMixpanelTracking
        );
    }

    onSubmit() {
        if (!this.sponsorSettings) {
            return;
        }

        this.member.sponsorId = this.sponsorSettings.sponsorId;
        this.member.legacySponsorId = this.sponsorSettings.legacySponsorId;
        this.member.enrollmentGroupId = Number(this.transition.params().enrollmentGroupId);
        this.loadingValidation = true;

        this.validateAndTransition();
    }

    validateAndTransition() {
        this.sponsorValidationService
            .validateMemberResolved(this.member)
            .subscribe((result) => {
                this.member = result;
                this.currentMemberService.memberEnrollmentData = result;
                this.saveError = null;

                const transition = this.validationTransitionService.getValidationTransition(
                    this.transition.params(), this.currentMemberService.memberEnrollmentData.eligibleProducts);

                this.state.go(transition.target, transition.params);

                const validationPageModel: ValidationPageModel = {
                    sponsor_id: this.sponsorSettings.sponsorId,
                    sponsor_name: this.sponsorSettings.sponsorName,
                    validation_rule: _.lowerCase(this._enrollmentGroup.enrollmentValidationRule),
                    country: _.lowerCase(this.member.country),
                    external_member_id: this.member.externalId
                };
                this.analyticsTrackingCommandsService.trackSignUpDemographicInfoComplete(
                    validationPageModel,
                    this.sponsorSettings.excludeMixpanelTracking
                );
            },
        (error) => {
            this.saveError = error;
            this.loadingValidation = false;
        });
    }
}
