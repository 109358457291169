import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { SponsorCompaniesService } from '../../../shared/sponsor-companies.service';
import { MemberEnrollmentData } from '../../../shared/member-enrollment-data.class';
import { Company } from '../../../shared/company.class';
import { BusinessUnit } from '../../../shared/business-unit.class';
import { Office } from '../../../shared/office.class';
import { SponsorSettings } from '../../../shared/sponsor-settings.class';

import * as _ from 'lodash';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

@Component({
    selector: 'vpe-org-hierarchy',
    templateUrl: './org-hierarchy.component.html',
    styleUrls: ['./org-hierarchy.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrgHierarchyComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OrgHierarchyComponent),
            multi: true,
        }
    ]
})
export class OrgHierarchyComponent implements Validator, ControlValueAccessor {
    _member: MemberEnrollmentData;

    @Input() set member(value: MemberEnrollmentData) {
        if (value) {
            this._member = value;
        }
    }

    @Output() memberChanged = new EventEmitter<Boolean>();

    @Input() set sponsorSettings(settings: SponsorSettings) {
        if (settings) {
            this.displayCustomCompanyLabel = settings.displayCustomCompanyLabel;
            this.sponsorCompaniesService.getSponsorCompanies(settings.sponsorId).subscribe((companies) => {
                this.companies = companies;

                if (this.companies.length === 1 && !this._member.company) {
                    this._member.company = this.companies[0].name;
                }

                if (this._member.company) {
                    this.selectCompany();
                    this.memberChanged.emit(this.companies.length == 1 && this.businessUnits.length == 1 && this.locations.length == 1);
                }
            });
        }
    }

    companies: Array<Company>;
    selectedCompany: Company;
    businessUnits: Array<BusinessUnit>;
    locations: Array<Office>;

    companyTouched = false;
    businessUnitTouched = false;
    locationTouched = false;
    displayCustomCompanyLabel = false;

    constructor(private sponsorCompaniesService: SponsorCompaniesService) { }

    public writeValue(obj: any) { }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onCompanyChange() {
        this._member.officeLocation = null;
        this._member.businessUnit = null;
        this.selectCompany();
    }

    private selectCompany() {
        this.selectedCompany = _.find(this.companies, { 'name': this._member.company });
        if (!this.selectedCompany) {
            this.selectedCompany = new Company();
            this.selectedCompany.name = this._member.company;
            this.selectedCompany.businessUnits = [];

            if (this._member.businessUnit) {
                let businessUnit = new BusinessUnit();
                businessUnit.name = this._member.businessUnit;

                this.selectedCompany.businessUnits.push(businessUnit);
            }

            this.selectedCompany.offices = [];
            if (this._member.officeLocation) {
                let office = new Office();
                office.name = this._member.officeLocation;

                this.selectedCompany.offices.push(office);
            }

            this.companies.push(this.selectedCompany);
        }

        if (this.selectedCompany.id) {
            this._member.companyId = this.selectedCompany.id;
        }

        this.businessUnits = this.selectedCompany.businessUnits;
        if (!this._member.businessUnit) {
            this._member.businessUnit = this.businessUnits.length === 1 ? this.businessUnits[0].name : null;
        } else {
            let memberBU = this._member.businessUnit;
            let existingBusinessUnit = _.find(this.businessUnits, function(bu) {
                return memberBU.toLowerCase === bu.name.toLowerCase;
            });
            if (existingBusinessUnit) {
                this._member.businessUnit = existingBusinessUnit.name;
            } else {
                let newBusinessUnit = new BusinessUnit();
                newBusinessUnit.name = this._member.businessUnit;
                this.businessUnits.push(newBusinessUnit);
            }
        }

        this.locations = this.selectedCompany.offices;
        if (!this._member.officeLocation) {
            this._member.officeLocation = this.locations.length === 1 ? this.locations[0].name : null;
        } else {
            let memberOfficeLocation = this._member.officeLocation;
            let existingLocation = _.find(this.locations, function(location) {
                return memberOfficeLocation.toLowerCase === location.name.toLowerCase;
            });
            if (existingLocation) {
                this._member.officeLocation = existingLocation.name;
            } else {
                let newOffice = new Office();
                newOffice.name = this._member.officeLocation;
                this.locations.push(newOffice);
            }
        }
        this.propagateChange(true);
    }

    public hasValidOrgHierarchy() {
        if (this.companies && this.companies.length) {
            if (this.selectedCompany) {
                return this.locations && this.locations.length && this.businessUnits && this.businessUnits.length;
            }

            return true;
        }

        return false;
    }

    public onChange() {
        this.propagateChange(true);
    }

    public onCompanyBlur() {
        this.companyTouched = true;
        this.propagateTouch(true);
    }

    public onBusinessUnitBlur() {
        this.businessUnitTouched = true;
        this.propagateTouch(true);
    }

    public onLocationBlur() {
        this.locationTouched = true;
        this.propagateTouch(true);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this._member || !this._member.company) {
            return { 'required' : 'company' };
        }

        if (!this._member.businessUnit) {
            return { 'required' : 'businessUnit' };
        }

        if (!this._member.officeLocation) {
            return { 'required' : 'office' };
        }

        return null;
    }
}
