<form class="groove-dropdown-form" [formGroup]="form">
    <vpg-dropdown-next class="groove-dropdown-vpg-dropdown"
                       [formControlName]="fieldName"
                       [name]="fieldName"
                       [placeholder]="placeholder"
                       [ariaLabel]="label"
                       [label]="label"
                       [options]="data"
                       [optionLabel]="'text'"
                       (focusout)="onBlur()"
                       [required]="isRequired">
        <ng-template #errors>
            <vpg-control-error [id]="fieldName + '-required-error-message'"
                               role="alert"
                               *ngIf="form.controls[fieldName].errors?.required">
                {{ requiredErrorMessage }}
            </vpg-control-error>
        </ng-template>
    </vpg-dropdown-next>
</form>
