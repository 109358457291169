import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnrollmentGroup } from '../enrollment-group.class';
import { Observable } from 'rxjs';

@Injectable()
export class FlexFormEnrollmentGroupsService {
    constructor(
        private httpClient: HttpClient
    ) {}

    getEnrollmentGroupsBySponsorGuid(sponsorGuid: string): Observable<EnrollmentGroup[]> {
        return this.httpClient.get<EnrollmentGroup[]>(`/enrollment-api/sponsor/${sponsorGuid}/enrollment-groups`);
    }
}
