import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';

const PLATFORM = 'web';
const PROPERTY_BLACKLIST = [
    '$city',
    '$region',
    'mp_country_code',
    '$country_code'
];

@Injectable()
export class AnalyticsTrackingService {

    static init() {
        mixpanel.init(environment.mixpanel.token, {
            ip: false,
            property_blacklist: PROPERTY_BLACKLIST
        });
    }

    sendData(actionName: string, trackingData: any = {}, excludeMixpanelTracking: boolean): void {
        const data = {
            platform: PLATFORM,
            member_timestamp: moment().utc().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
        };

        if (excludeMixpanelTracking === false) {
            this.track(actionName, {...data, ...trackingData });
        }
    }

    private track(actionName: string, trackingData: any = {}): void {
        mixpanel.track(actionName, trackingData);
    }
}
