import { Component, Input, forwardRef } from '@angular/core';
import {
    FormsModule,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';


@Component({
    selector: 'vpe-username',
    templateUrl: './username.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UsernameComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UsernameComponent),
            multi: true,
        }
    ]
})

export class UsernameComponent implements ControlValueAccessor, Validator {
    username: string;
    touched = false;
    private usernameRegex = /^[a-zA-Z0-9]{5,30}$/;

    public writeValue(obj: any) {
        if (obj) {
            this.username = obj.toString();
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.propagateChange(this.username);
    }

    public onBlur() {
        this.touched = true;
        this.propagateTouch(true);
    }

    public isValidUsername() {
        return this.usernameRegex.test(this.username);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.isValidUsername()) {
            return { 'invalid' : 'username' };
        }
    }

}
