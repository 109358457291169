<div>
    <groove-input-field class="groove-wrapper"
                        [id]="field?.fieldName + '-input'"
                        [fieldName]="field?.fieldName"
                        [label]="field?.displayName"
                        [placeholder]="field?.placeholder"
                        [value]="emailAddress"
                        (valueChanged)="onEmailChange($event)"
                        (touched)="onEmailTouch()"
                        [isRequired]="isRequired">
    </groove-input-field>

    <img class="validation-spinner" *ngIf="isValidationPending" src="assets/images/spinnerLoading.gif"
        [id]="field?.fieldName + '-validation-spinner'">
</div>

<div>
    <span translate="" role="alert" class="error-text" [id]="field?.fieldName + '-format-error'"
        *ngIf="emailAddress && fieldErrors[VALIDATORS.EMAIL_ADDRESS]">
        enrollment.forms.errors.thisEmailIsNotValid
    </span>
</div>

<div class="confirmation-container">
    <div>
        <groove-input-field class="groove-wrapper"
                            [id]="field?.fieldName + '-confirmation-input'"
                            [fieldName]="field?.fieldName + 'Confirmation'"
                            [label]="'enrollment.flex.emailAddressConfirmation' | translate"
                            [placeholder]="'enrollment.flex.reenterYourEmailAddress' | translate"
                            [value]="emailConfirmation"
                            (valueChanged)="onConfirmationChange($event)"
                            (touched)="onConfirmationTouch()"
                            [isRequired]="isRequired">
        </groove-input-field>
    </div>

    <div>
        <span translate="" role="alert" class="error-text" [id]="field?.fieldName + '-confirmation-error'"
            *ngIf="emailConfirmation && fieldErrors[VALIDATORS.CONFIRMATION] && emailConfirmationTouched">
            enrollment.flex.errors.emailAddressDoNotMatch
        </span>
    </div>
</div>
