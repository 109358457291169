<form class="container-fluid"
      (ngSubmit)="onSubmit()"
      [formGroup]="validationForm"
      novalidate
      *ngIf="displayOrgHierarchyDropdowns">
    <vpe-org-hierarchy [member]="member"
                       [sponsorSettings]="sponsorSettings"
                       (memberChanged)="onMemberChange($event)">
    </vpe-org-hierarchy>
    <button class="btn btn-orange"
            type="submit"
            translate=""
            [disabled]="isSubmitDisabled()"
            aria-describedby="nef-save-error">
        enrollment.forms.submit
    </button>
</form>
