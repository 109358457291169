<div>
    <groove-input-field class="groove-wrapper"
                        [id]="field.fieldName + '-input'"
                        [fieldName]="field.fieldName"
                        [label]="field.displayName"
                        [isDisabled]="field.editable === false"
                        (valueChanged)="onChange($event)"
                        (touched)="onTouch()"
                        [placeholder]="field.placeholder"
                        [isRequired]="isRequired"
                        [customValidatorsWithMessages]="customValidatorsWithMessages">
    </groove-input-field>
</div>
