import { Component, OnInit } from '@angular/core';

import { Transition } from "@uirouter/angular";
import { TranslateService } from '@ngx-translate/core';

import { EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService } from '../shared/enrollment-api/services/sponsors/enrollment-groups/eligibility/tokens/enrollment-api-sponsors-enrollment-groups-eligibility-tokens.service';

@Component({
    selector: 'registration-closed-again',
    templateUrl: './registration-closed-again.component.html',
    styleUrls: ['./registration-closed-again.component.scss']
})
export class RegistrationClosedAgainComponent implements OnInit {
    constructor(
        private enrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService: EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService,
        private transition: Transition,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        let eligibilityToken = this.transition.params().eligibilityToken;

        if (eligibilityToken) {
            this.enrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService
                .getMemberEligibilityFromToken(
                    this.transition.params().sponsorId,
                    this.transition.params().enrollmentGroupId,
                    eligibilityToken
                ).subscribe((result) => {
                    if (result.language) {
                        this.translateService.use(result.language);
                    }
                });
        }
    }
}
