export type ValidationRuleType =
    'EmployeeIdAndLastName' |
    'EmployeeIdAndLastInitial' |
    'EmployeeIdAndLastNameAndDateOfBirth' |
    'EnrollmentCode' |
    'EnrollmentCodeAndLastName' |
    'EnrollmentCodeAndLastNameAndDateOfBirth' |
    'LastNameAndDateOfBirthAndGender' |
    'NoEligibilityFile' |
    'NoEligibilityFileWithOnlyBirthYear' |
    'EmployeeId';

export let NonFileValidationRules: Array<string> = ['NoEligibilityFile', 'NoEligibilityFileWithOnlyBirthYear'];

export let ValidationRule: any = {
    EmployeeIdAndLastName: 'EmployeeIdAndLastName',
    EmployeeIdAndLastInitial: 'EmployeeIdAndLastInitial',
    EmployeeIdAndLastNameAndDateOfBirth: 'EmployeeIdAndLastNameAndDateOfBirth',
    EnrollmentCode: 'EnrollmentCode',
    EnrollmentCodeAndLastName: 'EnrollmentCodeAndLastName',
    EnrollmentCodeAndLastNameAndDateOfBirth: 'EnrollmentCodeAndLastNameAndDateOfBirth',
    LastNameAndDateOfBirthAndGender: 'LastNameAndDateOfBirthAndGender',
    NoEligibilityFile: 'NoEligibilityFile',
    NoEligibilityFileWithOnlyBirthYear: 'NoEligibilityFileWithOnlyBirthYear',
    EmployeeId: 'EmployeeId'
}
