import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Directive()
@Injectable()
export class MainCelebrationTriggerService {
    @Output() eventTrigger: EventEmitter<any> = new EventEmitter();

    trigger() {
        this.eventTrigger.emit(null);
    }
}
