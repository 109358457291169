<div class="form-group form-group-name">
    <label for="genderIdentities"
           *ngIf="!sponsorAllowsNonBinaryGender">
        {{ customLabel ? customLabel : ('enrollment.forms.sex' | translate) }}
    </label>
    <label for="genderIdentities"
           *ngIf="sponsorAllowsNonBinaryGender">
        {{ customLabel ? customLabel : ('enrollment.forms.gender' | translate) }}
    </label>
    <div>
        <select class="form-input"
                id="genderIdentities"
                (change)="onChange()"
                (blur)="onBlur()"
                [(ngModel)]="genderIdentity"
                [ngClass]="{ 'validation-error': !genderIdentity }"
                required
                aria-describedby="gender-blank-error">
            <option [ngValue]="null"
                    disabled
                    translate="">
                enrollment.forms.iAm
            </option>
            <option *ngFor="let gi of genderIdentities"
                    [ngValue]="gi">
                {{ (gi.description) }}
            </option>
        </select>
    </div>
    <div *ngIf="!genderIdentity && touched"
         id="gender-blank-error">
        <small class="sr-only" translate="">
            enrollment.screenReader.error
        </small>
        <small class="error-text"
            [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: customLabel ? customLabel : (sponsorAllowsNonBinaryGender ? 'enrollment.forms.gender' : 'enrollment.forms.sex' | translate) }">
        </small>
    </div>
</div>
