<div [ngClass]="{ 'no-side-padding': field.columns > 1 }">
    <groove-input-field class="groove-wrapper"
                        [id]="field.fieldName + '-input'"
                        [fieldName]="field.fieldName"
                        [label]="field.displayName"
                        [isDisabled]="field.editable === false"
                        (valueChanged)="onChange($event)"
                        (touched)="onTouch()"
                        [placeholder]="field.placeholder"
                        [value]="value"
                        [maxLength]="field.maxLength"
                        [type]="fieldTypeToUse"
                        [hasIcon]="hasIcon"
                        [isRequired]="isRequired">
    </groove-input-field>
</div>
