import { Injectable } from '@angular/core';
import * as _ from 'lodash';

const enum InnerErrorName {
    Timeout = 'timeout',
    Suspended = 'suspended',
}

const enum SpecialFingerprint {
    /** Making a fingerprint is skipped because the browser is known to always suspend audio context */
    KnownToSuspend = -1,
    /** The browser doesn't support audio context */
    NotSupported = -2,
    /** The browser does support audio context */
    Supports = -3,
}

@Injectable()
export class AudioFingerprintService {

    DEFAULT_RETURN_VALUE = 0.0;

    AUDIO_CONTEXT = {
        NUMBER_OF_CHANNELS: 2,
        SAMPLE_RATE: 48000,
        BASE_LENGTH: 44100,
        BASE_LENGTH_MULTIPLIER: 40,
        FREQUENCY: 1000,
        START_STATE: 0
    };

    AUDIO_HASH = {
        CHANNEL: 0,
        HASH_FROM_INDEX: 4500,
        HASH_TO_INDEX: 5000,
    };

    BUFFER = {
        BUFFER_CHANNEL: 1,
        SAMPLE_RATE_LENGTH: 3
    };

    AUDIO_RENDERING = {
        RESUME_TRIES_MAX_COUNT: 3,
        RESUME_RETRY_DELAY: 500,
        RUNNING_TIMEOUT: 2000
    };

    BROWSER = {
        WEBKIT: 4,
        DESKTOP_SAFARI: 3,
        WEBKIT686_OR_NEWER: 3
    };

    constructor() {}

    async getAudioFingerprintId(): Promise<number> {
        return this.getSupport() === SpecialFingerprint.NotSupported ? this.DEFAULT_RETURN_VALUE : this.generateAudioFingerprint();
    }

    async generateAudioFingerprint(): Promise<number> {
        const audioCtxOff = new OfflineAudioContext(
            this.AUDIO_CONTEXT.NUMBER_OF_CHANNELS,
            this.AUDIO_CONTEXT.BASE_LENGTH * this.AUDIO_CONTEXT.BASE_LENGTH_MULTIPLIER,
            this.AUDIO_CONTEXT.SAMPLE_RATE);

        if (this.doesCurrentBrowserSuspendAudioContext()) {
            return SpecialFingerprint.KnownToSuspend;
        }
        const {audioID, oscillator} = this.audioContextOffSetup(audioCtxOff);
        let buffer = this.createBuffer(audioCtxOff);

        try {
            buffer = await this.renderAudio(audioCtxOff);
        } catch (error) {
            return this.DEFAULT_RETURN_VALUE;
        } finally {
            oscillator.disconnect();
            audioID.disconnect();
        }

        return this.calculateHash(
            buffer.getChannelData(this.AUDIO_HASH.CHANNEL)
                .subarray(this.AUDIO_HASH.HASH_FROM_INDEX, this.AUDIO_HASH.HASH_TO_INDEX));
    }

    createBuffer(audioCtxOff: OfflineAudioContext): AudioBuffer {
        return audioCtxOff.createBuffer(
            this.BUFFER.BUFFER_CHANNEL,
            audioCtxOff.sampleRate * this.BUFFER.SAMPLE_RATE_LENGTH,
            audioCtxOff.sampleRate);
    }

    audioContextOffSetup(audioCtxOff: OfflineAudioContext) {
        const audioID = audioCtxOff.createDynamicsCompressor();
        const oscillator = audioCtxOff.createOscillator();
        oscillator.type = 'triangle';
        oscillator.frequency.value = this.AUDIO_CONTEXT.FREQUENCY;

        oscillator.connect(audioID);
        audioID.connect(audioCtxOff.destination);
        oscillator.start(this.AUDIO_CONTEXT.START_STATE);
        return {audioID, oscillator};
    }

    getSupport() {
        const audioContext = (window['AudioContext'] || window['webkitAudioContext']);
        if (!audioContext) { return SpecialFingerprint.NotSupported; }
        return SpecialFingerprint.Supports;
    }

    calculateHash(sample: Float32Array): number {
        return _.sumBy(sample, (sampleKey) =>  Math.abs(Number(sampleKey)) );
    }

    renderAudio(context: OfflineAudioContext): Promise<AudioBuffer> {
        return new Promise<AudioBuffer>((resolve, reject) => {
            context.oncomplete = (event) => resolve(event.renderedBuffer);

            let resumeTriesLeft =  this.AUDIO_RENDERING.RESUME_TRIES_MAX_COUNT;
            const tryResume = () => {
                context.startRendering();
                switch (context.state) {
                    case 'running':
                        setTimeout(() => reject(new Error(InnerErrorName.Timeout)), this.AUDIO_RENDERING.RUNNING_TIMEOUT);
                        break;
                    case 'suspended':
                        if (!document.hidden) {
                            resumeTriesLeft--;
                        }
                        if (!resumeTriesLeft) {
                            reject(new Error(InnerErrorName.Suspended));
                            break;
                        }
                        setTimeout(tryResume, this.AUDIO_RENDERING.RESUME_RETRY_DELAY);
                        break;
                }
            };
            tryResume();
        });
    }

    doesCurrentBrowserSuspendAudioContext(): boolean {
        return this.isWebKit() && !this.isDesktopSafari() && !this.isWebKit606OrNewer();
    }

    countTruthy(values: boolean[]): number {
        return values.reduce<number>((sum, value) => sum + (value ? 1 : 0), 0);
    }

    isWebKit(): boolean {
        const w = window;
        const n = navigator;
        return (this.countTruthy([
            'ApplePayError' in w,
            'CSSPrimitiveValue' in w,
            'Counter' in w,
            n.vendor.indexOf('Apple') === 0,
            'getStorageUpdates' in n,
            'WebKitMediaKeys' in w,
        ]) >= this.BROWSER.WEBKIT );
    }

    isDesktopSafari(): boolean {
        const w = window;
        return (
            this.countTruthy([
                'safari' in w,
                !('DeviceMotionEvent' in w),
                !('ongestureend' in w),
                !('standalone' in navigator),
            ]) >= this.BROWSER.DESKTOP_SAFARI
        );
    }

    isWebKit606OrNewer(): boolean {
        const w = window;
        return (
            this.countTruthy([
                'DOMRectList' in w,
                'RCTPeerConnectionIceEvent' in w,
                'SVGGeomentryElement' in w,
                'ontransitioncancel' in w,
            ]) >= this.BROWSER.WEBKIT686_OR_NEWER
        );
    }
}
