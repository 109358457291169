import { Component, forwardRef } from '@angular/core';
import { TimezonesService } from '../../../shared/timezones.service';
import { Timezone } from '../../../shared/timezone.class';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

@Component({
    selector: 'vpe-timezone',
    templateUrl: './timezone.component.html',
    styleUrls: ['./timezone.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimezoneComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimezoneComponent),
            multi: true,
        }
    ]
})
export class TimezoneComponent implements ControlValueAccessor, Validator {
    timezones: Array<Timezone>;
    timezone: Timezone;

    constructor(private timezonesService: TimezonesService) { }

    public writeValue(obj: any) {
        this.timezonesService.getTimezones().subscribe((timezones) => {
            if (!this.timezones) {
                this.timezones = timezones;
            }

            let timezone: Timezone;

            if (obj) {
                timezone = _.find(this.timezones, obj);
            }
            if (!timezone) {
                timezone = _.find(this.timezones, {name: moment.tz.guess()});
            }
            if (!timezone) {
                timezone = _.find(this.timezones, {name: 'America/New_York'});
            }

            if (timezone !== this.timezone) {
                if (timezone || !this.timezone) {
                    this.timezone = timezone;
                }
                this.onChange();
            }
        });
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.propagateChange(this.timezone);
    }

    public onBlur() {
        this.propagateTouch(true);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.timezone) {
            return { 'required' : 'timezone' };
        }

        return null;
    }
}
