import { Component, Input, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
    Validator
} from '@angular/forms';

import { Transition } from "@uirouter/angular";

import { EnrollmentGroup } from '../../../shared/enrollment-group.class';

@Component({
    selector: 'vpe-enrollment-code',
    templateUrl: './enrollment-code.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnrollmentCodeComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EnrollmentCodeComponent),
            multi: true,
        }
    ]
})
export class EnrollmentCodeComponent implements ControlValueAccessor, Validator {
    enrollmentCode: string;
    touched = false;
    customLabel: string;

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
        this.customLabel = group.getFieldName('EnrollmentCode');
    }

    constructor( ) { }

    public writeValue(obj: any) {
        if (obj) {
            this.enrollmentCode = obj.toString();
        }
    }

    private propagateChange = (_: any) => { };
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };
    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }

    public onChange() {
        this.propagateChange(this.enrollmentCode);
    }

    public onBlur() {
        this.touched = true;
        this.propagateTouch(true);
    }

    public validate(c: UntypedFormControl): {[key: string]: any} {
        if (!this.enrollmentCode) {
            return { 'required' : 'enrollmentCode' };
        }

        return null;
    }
}
