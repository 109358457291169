import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    ValidatorFn,
    AbstractControl
} from '@angular/forms';
import { Constants } from 'src/app/shared/constants.class';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { TranslateService } from '@ngx-translate/core';
import { InputType } from '@engineering/genesis-frontend/domain/types';

@Component({
    selector: 'reactive-date-of-birth',
    templateUrl: './reactive-date-of-birth.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveDateOfBirthComponent),
            multi: true,
        }
    ]
})
export class ReactiveDateOfBirthComponent extends BaseFormField implements ControlValueAccessor, OnInit {
    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;

    dateOfBirth: string = null;
    inputType: InputType = 'date';
    customValidatorsWithMessages: { validatorName: string, validator: ValidatorFn }[] = [];

    isRtlForm = false;

    constructor(
        public translateService: TranslateService
    ) {
        super();
    }

    get errors(): ValidationErrors {
        return this.form?.controls[this.field?.fieldName]?.errors;
    }

    get VALIDATORS() {
        return Constants.VALIDATORS;
    }

    ngOnInit() {
        if (this.field?.initialValue) {
            this.dateOfBirth = this.field.initialValue;
        }

        if (this.translateService.currentLang == 'ar-001') {
            this.isRtlForm = true;
        }

        this.customValidatorsWithMessages.push({
            validatorName: 'minDateOfBirth',
            validator: this.minDateOfBirth()
        }, {
            validatorName: 'maxDateOfBirth',
            validator: this.maxDateOfBirth()
        }, {
            validatorName: 'dateFormat',
            validator: this.dateFormat()
        });
    }

    writeValue(newDateOfBirth: string) {
        if (newDateOfBirth) {
            this.dateOfBirth = newDateOfBirth;
        }
    }

    onChange($event: any) {
        this.dateOfBirth = $event;
        this.propagateChange(this.dateOfBirth);
    }

    onTouch() {
        this.propagateTouch();
    }

    private dateFormat(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && !Constants.REGEX.DATE.test(control.value as string)) {
                return {
                    dateFormat: 'Invalid',
                    message: this.translateService.instant('enrollment.flex.errors.invalidDateFormat')
                };
            }

            return null
        };
    }

    private minDateOfBirth(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                let dob = new Date(control.value as string);

                if (dob.getUTCFullYear() < Constants.MINIMUM_BIRTH_YEAR) {
                    return {
                        minDateOfBirth: 'Invalid',
                        message: this.translateService.instant('enrollment.flex.errors.invalidBirthDate_maxAge')
                    };
                }

                return null;
            }

            return null;
        };
    }

    private maxDateOfBirth(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                let dob = new Date(control.value as string);
                let utcDob = new Date(
                    dob.getUTCFullYear(),
                    dob.getUTCMonth(),
                    dob.getUTCDate()
                );

                let now = new Date();
                let utcMaxDob = new Date(
                    now.getUTCFullYear() - Constants.MINIMUM_AGE,
                    now.getUTCMonth(),
                    now.getUTCDate()
                );

                if (utcDob > utcMaxDob) {
                    return {
                        maxDateOfBirth: 'Invalid',
                        message: this.translateService.instant('enrollment.forms.errors.youMustBeAtLeast')
                    };
                }

                return null;
            }

            return null
        };
    }
}
