import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseValidationForm } from '../../shared/base-validation-form.class';

@Component({
    selector: 'enrollment-code-validation-form',
    templateUrl: './enrollment-code-validation-form.component.html',
    styleUrls: ['../../validation.component.scss']
})
export class EnrollmentCodeValidationFormComponent extends BaseValidationForm implements OnInit {

    @Input() set memberData(memberData) {
        if (memberData) {
            this.member = memberData;

            this.validationForm = this.fb.group({
                firstName: [this.member.firstName, Validators.required],
                lastName: [this.member.lastName, Validators.required],
                enrollmentCode: [this.member.enrollmentCode, Validators.required],
                country: [this.member.country, Validators.required],
                stateOfResidence: [this.member.stateOfResidence]
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onSubmit() {
        super.onSubmit();
    }
}
