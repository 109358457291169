export class WaitlistEligible {
    public sponsorId: number;
    public emailAddress: string;
    public firstName: string;
    public lastName: string;
    public companyId: number;
    public language: string;

    constructor() {
        this.sponsorId = null;
        this.emailAddress = null;
        this.firstName = null;
        this.lastName = null;
        this.companyId = null;
        this.language = null;
    }
}
