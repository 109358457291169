import { AfterContentInit, Component, Input } from '@angular/core';

import { StateService, Transition } from "@uirouter/angular";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { ValidationRule } from './validation-rule-type.enum';
import { CurrentMemberService } from '../shared/current-member.service';
import { MemberEnrollmentData } from '../shared/member-enrollment-data.class';
import { EnrollmentGroup } from '../shared/enrollment-group.class';
import { EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService } from '../shared/enrollment-api/services/sponsors/enrollment-groups/eligibility/tokens/enrollment-api-sponsors-enrollment-groups-eligibility-tokens.service';
import { SponsorSettings } from '../shared/sponsor-settings.class';
import { EnrollmentGroupsSettingsService } from '../shared/enrollment-api/services/sponsors/enrollment-groups/enrollment-groups-settings.service';

import * as _ from 'lodash';

@Component({
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements AfterContentInit {
    ruleType: string = null;
    memberData: MemberEnrollmentData = null;
    _enrollmentGroup: EnrollmentGroup;
    enrollmentGroupSettingsText: String = null;
    isScreenCopySet: Boolean = false;

    @Input() sponsorSettings: SponsorSettings;

    @Input() set enrollmentGroup(group: EnrollmentGroup) {
        this._enrollmentGroup = group;

        if (group && group.enrollmentValidationRule) {
            this.ruleType = group.enrollmentValidationRule;
        }
        else {
            this.ruleType = 'default';
        }
    }

    @Input() set enrollmentGroupSettings(settings: Object) {
        this.setEnrollmentGroupSettingsText(settings);
    }

    constructor(
        private enrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService: EnrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService,
        private transition: Transition,
        private currentMemberService: CurrentMemberService,
        private state: StateService,
        private translateService: TranslateService,
        enrollmentGroupsSettingsService: EnrollmentGroupsSettingsService
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            enrollmentGroupsSettingsService.getEnrollmentGroupSettings(
                transition.params().sponsorId,
                transition.params().enrollmentGroupId,
                event.lang
            ).subscribe((settings) => {
                this.setEnrollmentGroupSettingsText(settings);
            });
        });
    }

    ngAfterContentInit() {
        let sponsorId = this.transition.params().sponsorId;
        let enrollmentGroupId = this.transition.params().enrollmentGroupId;
        let eligibilityToken = this.transition.params().eligibilityToken;

        this.setMemberData(new MemberEnrollmentData());

        if (eligibilityToken) {
            this.enrollmentApiSponsorsEnrollmentGroupsEligibilityTokensService
                .getMemberEligibilityFromToken(sponsorId, enrollmentGroupId, eligibilityToken).subscribe(
                (result) => {
                    this.setMemberDataFromEligibilityToken(result);
            });
        }
    }

    private setEnrollmentGroupSettingsText(settings) {
        if (!_.isNil(settings['EnrollmentForm1'])) {
            this.enrollmentGroupSettingsText = settings['EnrollmentForm1'];
            this.isScreenCopySet = true;
        }
    }

    private setMemberData(data) {
        this.memberData = data;
        this.setAgreementsAndLanguage(data);
    }

    private setMemberDataFromEligibilityToken(data) {
        if (this.ruleType === ValidationRule.EmployeeId) {
            this.setMemberData(data);
        } else {
            this.memberData.setFromEligibilityToken(data);
            this.setAgreementsAndLanguage(data);
        }
    }

    private setAgreementsAndLanguage(data) {
        this.memberData.agreements = this.currentMemberService.memberEnrollmentData.agreements;
        if (data.language) {
            this.translateService.use(data.language);
        }
    }
}
