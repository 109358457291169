import { Component, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { CountryService } from '../../../shared/country.service';
import { Country } from '../../../shared/country.class';
import { TranslateService } from '@ngx-translate/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS
} from '@angular/forms';
import * as _ from 'lodash';
import { Utils } from '../../Utils.class';

@Component({
    selector: 'vpe-phonenumber',
    templateUrl: './phonenumber.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneNumberComponent),
            multi: true,
        }
    ]
})

export class PhoneNumberComponent implements ControlValueAccessor {

    parsedPhoneNumber: PhoneNumber;
    phoneUtil = PhoneNumberUtil.getInstance();
    phoneNumber: string;
    countryDialCode: string = '1';
    maxInputLength: number = 14;
    isValidPhoneNumber: boolean = true;
    touched = false;
    countryIso2Code: string = 'US';
    intlTelObject: any;
    currentLanguage: string;
    inputId: string;
    translationPhoneNumber: string;

    @Input() intlPhoneNumber: string;
    @Input() set phoneType(phoneType: string) {
        this.inputId = phoneType;
        this.translationPhoneNumber = 'enrollment.forms.homePhoneNumber';
        if (phoneType === 'phoneNumber') {
            this.translationPhoneNumber = 'enrollment.forms.cellPhoneNumber';
        }
    }
    @Output() intlPhoneNumberChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(private countryService: CountryService, private translateService: TranslateService) {
        countryService.getSelectedCountry().subscribe((country) => {
            if (!this.phoneNumber && _.get(country, 'countryCode', null)) {
                if (Utils.isNullOrUndefined(this.intlTelObject)) {
                    this.setCountryCode(country);
                } else {
                    this.intlTelObject.setCountry(country.countryCode);
                }
            }
        });

        translateService.onLangChange.subscribe(() => {
            this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
            this.populateCountryList(countryService);
        });
    }

    public setCountryCode(country: Country) {
        this.phoneNumber = '';
        this.parsedPhoneNumber = null;
        this.countryDialCode = country.phoneCountryCode;
        this.isValidPhoneNumber = true;
        if (country.countryCode === 'US') {
            this.maxInputLength = 14;
            this.countryIso2Code = 'US';
        } else {
            this.maxInputLength = 20;
            this.countryIso2Code = country.countryCode;
        }
    }

    public populateCountryList(countryService: CountryService){
        countryService.getCountries(this.currentLanguage).subscribe((countries) => {
            var countriesObject= _.keyBy(countries, 'countryCode');
            this.intlTelObject.intlTelInput.getCountryData().forEach(country => {
                var iso2Code=country.iso2.toUpperCase();
                if(!Utils.isNullOrUndefined(countriesObject[iso2Code])){
                    country.name=countriesObject[iso2Code].name;
                }
            });
        });
    }

    public writeValue(obj: any) {
        if (obj) {
            this.phoneNumber = obj.toString();
        }
    }

    private propagateChange = (_: any) => { };

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    private propagateTouch = (_: any) => { };

    public registerOnTouched(fn: any) {
        this.propagateTouch = fn;
    }
    public onChange() {
        this.propagateChange(this.phoneNumber)
    }

    public numberOnly(event: any): boolean {
        var charCode = (event.which) ? event.which : event.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57));
      }

    public onBlur() {
        this.touched = true;
        if (!Utils.isNullOrUndefined(this.phoneNumber)) {
            if (this.phoneNumber.length === 0) {
                this.isValidPhoneNumber = true;
                this.phoneNumber = null;
                this.parsedPhoneNumber = null;
                this.intlPhoneNumberChange.emit(null);
            } else {
              if (this.countryDialCode.startsWith('+')) {
                this.intlPhoneNumber = `${this.countryDialCode} ${this.phoneNumber}`;
              } else {
                this.intlPhoneNumber = `+${this.countryDialCode} ${this.phoneNumber}`;
              }
                this.intlPhoneNumberChange.emit(this.intlPhoneNumber);
            }
        }
        this.propagateChange(this.phoneNumber);
    }

    public telInputObject(obj) {
        this.intlTelObject = obj;
    }

    public onCountryChange(event){
        this.phoneNumber = '';
        this.parsedPhoneNumber = null;
        this.countryDialCode = event.dialCode;
        this.isValidPhoneNumber = true;
        if( event.iso2 === 'us') {
            this.maxInputLength = 14;
            this.countryIso2Code = 'US';
        } else {
            this.maxInputLength = 20;
            this.countryIso2Code = event.iso2.toUpperCase();
        }
    }

     public validateNumber() {
        this.isValidPhoneNumber = true;
        if (!Utils.isNullOrUndefined(this.phoneNumber)) {
            try {
                this.parsedPhoneNumber = this.phoneUtil.parse(this.phoneNumber, this.countryIso2Code);
            } catch (e) {
                if (!this.phoneNumber) {
                    this.isValidPhoneNumber = true;
                } else {
                    this.isValidPhoneNumber = false;
                }
                return this.isValidPhoneNumber;
            }
            if (!this.phoneUtil.isValidNumber(this.parsedPhoneNumber)) {
                this.isValidPhoneNumber = false;
            } else {
                this.phoneNumber = this.phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.NATIONAL);
            }
        }
        return this.isValidPhoneNumber;
    }

    public validate(): {[key: string]: any} {
        if (!this.validateNumber()) {
            return { 'invalid' : 'phoneNumber' };
        }
    }
}
