import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EnrollmentErrorService } from '../shared/enrollment-error.service';

import { MemberEnrollmentData } from '../shared/member-enrollment-data.class';

@Injectable()
export class SponsorValidationService {
    constructor(private http: HttpClient, private enrollmentErrorService: EnrollmentErrorService) { }

    validateMember(member: MemberEnrollmentData): Observable<MemberEnrollmentData> {
        let MEMBER_VALIDATION_URL: string = `/enrollment-api/sponsors/${ member.sponsorId }/enroll/validate`;

        return this.http.post<MemberEnrollmentData>(MEMBER_VALIDATION_URL, member);
    }

    validateMemberResolved(member: MemberEnrollmentData): Observable<MemberEnrollmentData> {
        return this.validateMember(member).pipe(
            catchError((error) => {
                throw this.enrollmentErrorService.handleError(error);
            }));
    }

    validateEmailDomain( sponsorId: number, member: MemberEnrollmentData,) {
        let EMAIL_DOMAIN_VALIDATION_URL: string = `/enrollment-api/sponsors/${ sponsorId }/enroll/validate/email`;

        return this.http.post(EMAIL_DOMAIN_VALIDATION_URL, member);
    }

    validateEmailAddress(enrollmentGroupExternalId: string, emailAddress: string): Promise<Boolean> {
      const emailValidationUrl = `/enrollment-api/enrollment-group/${enrollmentGroupExternalId}/email/validate`;

      return this.http.post(emailValidationUrl, { emailAddress }).toPromise().then(() => {
        return true;
      }).catch(() => {
        return false;
      });
    }
}
