import { Component, Input } from '@angular/core';
import { Constants } from '../../shared/constants.class';

@Component({
    selector: 'flex-form-header',
    templateUrl: './flex-form-header.component.html',
    styleUrls: ['./flex-form-header.component.scss']
})
export class FlexFormHeaderComponent {
    @Input() pageType = '';
    @Input() title = '';
    @Input() description = '';

    get PAGE_TYPE() {
        return Constants.PAGE_TYPE;
    }
}
