import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AsyncSubject, Observable } from 'rxjs';

import { EnrollmentGroup } from './enrollment-group.class';

@Injectable()
export class SponsorEnrollmentGroupsService {
    private currentGroupSubject: AsyncSubject<EnrollmentGroup> = new AsyncSubject<EnrollmentGroup>();
    private currentSponsorId: number;
    private currentLanguage: string;
    private currentEnrollmentGroupId: number;

    constructor(
        private http: HttpClient
    ) { }


    loadEnrollmentGroup(sponsorId: number, enrollmentGroupId: number, language: string): Observable<EnrollmentGroup> {
        if (this.currentSponsorId === sponsorId && this.currentEnrollmentGroupId == enrollmentGroupId && this.currentLanguage === language) {
            return this.currentGroupSubject;
        }

        this.currentLanguage = language;
        this.currentSponsorId = sponsorId;
        this.currentEnrollmentGroupId = enrollmentGroupId;
        this.currentGroupSubject = new AsyncSubject();

        let SPONSOR_ENROLLMENT_GROUPS_URL: string = `/enrollment-api/sponsors/${ sponsorId }/enrollment-groups/${ enrollmentGroupId }/?language=${ language }`;
        this.http.get<EnrollmentGroup>(SPONSOR_ENROLLMENT_GROUPS_URL).subscribe((enrollmentGroup) => {
            this.currentGroupSubject.next(Object.assign(new EnrollmentGroup(), enrollmentGroup));
            this.currentGroupSubject.complete();
        });


        return this.currentGroupSubject;
    }
}
