import { Injectable } from "@angular/core";
import { CurrentMemberService } from "./current-member.service";
import { RawParams } from '@uirouter/core';

import * as _ from 'lodash';
import { EligibleProduct } from "./eligible-product";
import { ProductCode } from "./product.enum";
import { AgreementRedirect } from "./agreement-redirect.enum";
import { ProductHelper } from './product-helper.class';
import { EnrollmentGroup } from "./enrollment-group.class";
import { EnrollmentGroupUtil } from "./enrollment-group-util";

export class ValidationTransition {
    public target: string;
    public params: RawParams;
}

@Injectable()
export class ValidationTransitionService {
    constructor(private memberService: CurrentMemberService) {
    }

    getValidationTransition(currentParams: { [p: string]: any }, products: Array<EligibleProduct>, selected?: ProductCode): ValidationTransition {
        if (!_.isNil(products)) {
            products = products.filter(product => _.includes(ProductHelper.eligibleProducts, product.productCode));
        } else {
            products = [new EligibleProduct('VP', false)];
        }
        const transition = new ValidationTransition();

        if (!selected && products.length > 1) {
            // Scenario 1: No product selected, multiple products eligible -- transition to product selection screen
            transition.target = 'productSelect';
            transition.params = _.extend({ products: products }, currentParams)
        } else if (!selected && products.length <= 1) {
            // Scenario 2: No product selected, only one product eligible -- transition to agreements page with redirect to signup
            transition.target = 'agreements';
            transition.params = _.extend({ product: products[0].productCode || 'VP', redirect: AgreementRedirect.SIGNUP }, currentParams);
        } else if (selected && products.find(prod => !prod.accepted && prod.productCode !== selected)) {
            // Scenario 3: Product selected, other eligible products exist -- transition to agreements page with redirect to product select
            transition.target = 'agreements';
            transition.params = _.extend({ product: selected, redirect: AgreementRedirect.PRODUCTS }, currentParams);
        } else {
            // Scenario 4: Product selected, no other eligible products exist -- transition to agreements page with redirect to signup
            transition.target = 'agreements';
            transition.params = _.extend({ product: selected, redirect: AgreementRedirect.SIGNUP }, currentParams);
        }

        return transition;
    }

    getSignupTransition(currentParams: { [p: string]: any }, enrollmentGroup: EnrollmentGroup, redirect: AgreementRedirect): ValidationTransition {
        const transition = new ValidationTransition();
        transition.params = currentParams;

        if (EnrollmentGroupUtil.isOpenEnrollment(enrollmentGroup) || enrollmentGroup.requireEmailConfirmation) {
            if (ProductHelper.hasPendingProducts(this.memberService.memberEnrollmentData)) {
                transition.target = 'productSelect';
            } else {
                transition.target = 'verify';
            }
        } else {
            if (redirect == AgreementRedirect.PRODUCTS) {
                transition.target = 'productSelect';
            } else {
                transition.target = 'signup';
            }
        }

        return transition;
    }
}
