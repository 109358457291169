import { Component, Input, forwardRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LabelType } from '@engineering/genesis-frontend';

const minAge = 16;
const noConsentFormAge = 18;

@Component({
    selector: 'vpe-age-consent-warning',
    templateUrl: './age-consent-warning.component.html'
})
export class AgeConsentWarningComponent {
    consentFormURL: string = 'https://form.jotform.com/221115473325043';
    showWarning: boolean = false;
    deadline: string = moment().add(60, 'days').format('ll');

    @Input() set birthdate(_birthDate: any) {
        let enteredBirthDate: moment.Moment = moment(_birthDate);
        this.showWarning = moment().subtract(minAge, 'years').isAfter(enteredBirthDate)
                        && moment().subtract(noConsentFormAge, 'years').isBefore(enteredBirthDate);
    }
}
