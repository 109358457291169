<form [formGroup]="form">
    <vpg-input-field [formControlName]="fieldName"
                     [name]="fieldName"
                     [required]="isRequired"
                     [placeholder]="placeholder"
                     [label]="label"
                     [type]="fieldType"
                     (focusout)="onBlur()">
        <ng-template #suffix>
            <vpg-icon-next (vpg-interactive-element)="togglePasswordVisible()"
                           [icon]="passwordVisible ? 'eye' : 'eye-slash'"
                           [screenReaderText]="(passwordVisible ? 'enrollment.forms.arialLabelPasswordVisible' : 'enrollment.forms.arialLabelPasswordHidden') | translate">
            </vpg-icon-next>
        </ng-template>
        <ng-template #errors>
            <vpg-control-error role="alert" *ngIf="form.controls[fieldName].errors?.required">
                {{ requiredErrorMessage }}
            </vpg-control-error>
        </ng-template>
    </vpg-input-field>
</form>
