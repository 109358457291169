<div class="form-group form-group-name">
    <label for="username"
           translate="">
        enrollment.forms.username
    </label>
    <div>
        <input class="form-input"
               id="username"
               name="username"
               [(ngModel)]="username"
               placeholder="{{'enrollment.forms.createYourUsername' | translate }}"
               triggers="focus" popover="{{ 'enrollment.forms.usernamesMustHave' | translate }}"
               [ngClass]="{'validation-error': !isValidUsername() }"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               required>
    </div>
    <div *ngIf="touched">
        <small class="error-text"
               translate=""
               *ngIf="touched && !isValidUsername()"
               role="alert"
               aria-live="assertive">
            enrollment.forms.errors.thisUsernameDoesntMeetAllConditions
        </small>
    </div>
</div>
