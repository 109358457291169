import { Injectable } from '@angular/core';

@Injectable()
export class PasswordService {
    private password = ''

    constructor() {}

    setPassword(password: string) {
        this.password = password
    }

    getPassword() {
        return this.password
    }
}
