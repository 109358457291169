import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie';


@Injectable()
export class KeycloakLoginService {
    private AUTH_PROVIDER_COOKIE = 'authentication_provider';

    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) {
    }

    public logInWithProvider(username: string, password: string, identityProviderType: string) {
        let KEYCLOAK_URL: string = this.getKeyCloakProviderWithIdentityProviderType(identityProviderType)
        let formData = this.getFormData(username, password, identityProviderType)

        return this.http.post(KEYCLOAK_URL, formData, {
            headers: new HttpHeaders({
                'Content-Type':  'application/x-www-form-urlencoded'
            }),
            withCredentials: true
        });
    }

    getFormData(username: string, password: string, identityProviderType: string) {
        let clientId = this.getIamClientId(identityProviderType)
        let encodedRedirectUri = this.getEncodedRedirectUri(identityProviderType)

        return 'grant_type=password&client_id=' + clientId + '&redirect=false&username=' + encodeURIComponent(username)
            + '&password=' + encodeURIComponent(password) + '&redirect_uri=' + encodedRedirectUri;
    }

    setLoginProviderTypeToKeycloak(identityProviderType: string) {
        const domain = this.getProviderCookieDomainName(identityProviderType);
        this.cookieService.put(this.AUTH_PROVIDER_COOKIE, 'keycloak', { domain });
    }

    getIamClientId(identityProviderType: string) {
        let clientId: string
        if(identityProviderType === 'VirginPulse') {
            clientId = 'genesis-ui';
        } else {
            clientId = 'platform-ui';
        }

        return clientId
    }

    getEncodedRedirectUri(identityProviderType: string) {
        let redirectUri: string
        if(identityProviderType === 'VirginPulse') {
            redirectUri = environment.genesis.url;
        } else {
            redirectUri = environment.personifyHealth.url;
        }

        return encodeURIComponent(redirectUri)
    }

    getKeyCloakProviderWithIdentityProviderType(identityProviderType: string) {
        let keycloakUrl: string
        if(identityProviderType === 'VirginPulse') {
            keycloakUrl = environment.genesis.iamUrl + '/direct-web-session';
        } else {
            keycloakUrl = environment.personifyHealth.iamUrl + '/direct-web-session';
        }

        return keycloakUrl
    }

    /**
     * Get the Keycloak sign-in URL, for existing members, based on the identity provider type.
     * @param identityProviderType one of the IdentitiyProviderType enum entries on API side.
     */
    getKeycloakSignInUrl(identityProviderType?: string): string {
        if(!identityProviderType) {
            return environment.signIn.vpLink;
        } else if (identityProviderType === 'PersonifyHealth') {
            return environment.signIn.phLink;
        }

        return environment.signIn.vpLink;
    }

    getProviderCookieDomainName(identityProviderType: string) {
        let domain: string
        if(identityProviderType === 'VirginPulse') {
            domain = environment.genesis.url;
        } else {
            domain = environment.personifyHealth.url
        }
        const domainStringArray = _.split(domain, '.');

        if (domainStringArray.length > 1) {
            domain = '.' + domainStringArray[domainStringArray.length - 2] + '.' + domainStringArray[domainStringArray.length - 1];
        }

        return domain;
    }
}
