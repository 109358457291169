<form-wrapper>
    <div class="agreements-header">
        <div *ngIf="product !== 'PA'">
            <img class="agreement-image" src="assets/images/protecting-privacy-image.png" alt="">
            <div class="add-padding"></div>
        </div>
        <h2 translate="">enrollment.forms.agreements.protectingYourData</h2>
        <p class="intro-text" translate="">enrollment.forms.agreements.agreementsIntro</p>
    </div>
    <div class="agreement-container" *ngFor="let agreement of agreements">
        <h3 class="agreement-title" [innerHtml]="agreement.title"></h3>
        <div class="agreement-summary" *ngIf="agreement.summary" [innerHtml]="agreement.summary"></div>
        <div class="toggle-content-link"
             *ngIf="agreement.summary"
             (click)="toggleContent(agreement, $event)"
             tabindex="0"
             role="button">
            <span class="show-link" *ngIf="!agreement.showContent">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
                <span [innerHtml]="'enrollment.forms.agreements.showFullAgreement' | translate: { agreementTitle: agreement.title } "></span>
            </span>
            <span class="hide-link" *ngIf="agreement.showContent">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
                <span [innerHtml]="'enrollment.forms.agreements.hideFullAgreement' | translate: { agreementTitle: agreement.title } "></span>
            </span>
        </div>
        <div class="agreement-content" *ngIf="!agreement.summary || agreement.showContent">
            <div [ngClass]="{ 'agreement-content-scrollbox': agreement.type !== 'DataConsent' }"
                 [innerHtml]="agreement.content">
            </div>
        </div>
    </div>
    <div class="agreements-checkboxes">
        <div class="checkbox-row" *ngFor="let agreement of agreements">
            <input class="sr-only agreement-checkbox"
                   type="checkbox"
                   [attr.name]="'agreement-' + agreement.id"
                   [(ngModel)]="agreement.accepted"
                   [attr.id]="'agreement-' + agreement.id">
            <label class="agreement-label" [attr.for]="'agreement-' + agreement.id">
                <div class="checkbox-container"
                     [attr.aria-checked]=" agreement.accepted ? 'true' : 'false' "
                     [ngClass]="{ 'checked': agreement.accepted }">
                    <i class="checkmark" *ngIf="agreement.accepted" aria-hidden="true">✓</i>
                </div>
                <span class="accept-text" [innerHtml]="'enrollment.forms.agreements.iHaveReadAndAgree' | translate: { title: agreement.title } "></span>
            </label>
        </div>
    </div>
    <button class="btn btn-orange"
            (click)="continue()"
            translate=""
            [disabled]="isContinueDisabled()">
        enrollment.forms.continue
    </button>
    <div>
        <small class="error-text" *ngIf="displayAgreementsToaster" translate="">enrollment.forms.oopsSomethingWentWrong</small>
    </div>
</form-wrapper>
