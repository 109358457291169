import { Component, Input, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenderIdentityService } from '../../../shared/gender-identity.service';
import { GenderIdentity } from '../../../shared/gender-identity.class';
import { SponsorSettings } from '../../../shared/sponsor-settings.class';
import { Gender } from '../../../shared/gender.class';

import * as _ from 'lodash';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormControl,
  Validator
} from '@angular/forms';
import { EnrollmentGroup } from '../../../shared/enrollment-group.class';

@Component({
  selector: 'vpe-gender-identity',
  templateUrl: './gender-identity.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenderIdentityComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GenderIdentityComponent),
      multi: true,
    }
  ]
})
export class GenderIdentityComponent implements ControlValueAccessor, Validator {
  genderIdentities: Array<GenderIdentity>;
  genderIdentity: GenderIdentity = null;
  gender: Gender = null;
  customLabel: string;
  currentLanguage: string;
  sponsorAllowsNonBinaryGender: boolean;
  touched = false;

  @Input() sponsorSettings: SponsorSettings;

  @Input() set enrollmentGroup(group: EnrollmentGroup) {
    this.customLabel = group.getFieldName('Gender');
  }

  constructor(private genderIdentitiesService: GenderIdentityService, private translateService: TranslateService) {
    translateService.onLangChange.subscribe(() => {
      this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
      this.genderIdentitiesService.getGenderIdentities(this.currentLanguage, this.sponsorSettings.sponsorId).subscribe((genderIdentities) => {
        this.genderIdentities = genderIdentities;
        if (this.genderIdentity) {
          this.genderIdentity = _.find(this.genderIdentities, {name: this.genderIdentity.name});
        }
      });
    });
  }

  public writeValue(obj: string) {
    this.currentLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
    this.sponsorAllowsNonBinaryGender = this.sponsorSettings.allowNonBinaryGender;

    this.genderIdentitiesService.getGenderIdentities(this.currentLanguage, this.sponsorSettings.sponsorId).subscribe((genderIdentities) => {
      if (!this.genderIdentities) {
        this.genderIdentities = genderIdentities;
      }

      let genderIdentity: GenderIdentity;

      if (obj) {
        genderIdentity = _.find(this.genderIdentities, { 'name': obj });
      }
      if (!genderIdentity) {
        genderIdentity = null;
      }

      if (genderIdentity !== this.genderIdentity) {
        if (genderIdentity || !this.genderIdentity) {
          this.genderIdentity = genderIdentity;
        }
        this.onChange();
      }
    });
  }

  private propagateChange = (_: any) => { };
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  private propagateTouch = (_: any) => { };
  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  public onChange() {
    this.propagateChange(this.genderIdentity);
  }

  public onBlur() {
    this.touched = true;
    this.propagateTouch(true);
  }

  public validate(c: UntypedFormControl): {[key: string]: any} {
    if (!this.genderIdentity) {
      return { 'required' : 'genderIdentity' };
    }

    return null;
  }
}
