<div class="form-group">
    <label for="stateProvince">
        {{ customLabel ? customLabel : ('enrollment.forms.stateProvince' | translate) }}
    </label>
    <div>
        <input class="form-input"
               id="stateProvince"
               [(ngModel)]="stateProvince"
               [ngClass]="{ 'validation-error': !stateProvince }"
               [typeahead]="stateProvinceList"
               (typeaheadOnSelect)="onChange()"
               (change)="onChange()"
               (keyup)="onChange()"
               (blur)="onBlur()"
               required>
    </div>
    <div>
        <small class="error-text"
               *ngIf="touched && !stateProvince"
               [innerHtml]="'enrollment.forms.errors.fieldIsRequired' | translate: { fieldName: customLabel ? customLabel : ('enrollment.forms.stateProvince' | translate) }">
        </small>
    </div>
</div>
